/* eslint-disable no-console */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { UPDATE_TURNSTILE_RECAPTCHA_TOKEN, UPDATE_TURNSTILE_RECAPTCHA_TOKEN_VALUE } from './TurnstileRecaptcha.action';

export const initialState = {
    turnstileRecaptchaToken: '',
    turnstileRecaptchaTokenValue: ''
};

/** @namespace Pwa/Store/TurnstileRecaptcha/Reducer/TurnstileRecaptchaReducer */
export const TurnstileRecaptchaReducer = (state = initialState, action) => {
    const {
        type,
        turnstileRecaptchaToken,
        turnstileRecaptchaTokenValue
    } = action;

    switch (type) {
    case UPDATE_TURNSTILE_RECAPTCHA_TOKEN:

        return {
            ...state,
            turnstileRecaptchaToken
        };
    case UPDATE_TURNSTILE_RECAPTCHA_TOKEN_VALUE:

        return {
            ...state,
            turnstileRecaptchaTokenValue
        };

    default:
        return state;
    }
};

export default TurnstileRecaptchaReducer;
