/* eslint-disable arrow-body-style */
/* eslint-disable prefer-const */
/* eslint-disable prefer-destructuring */
/* eslint-disable max-len */
/* eslint-disable no-empty */
/* eslint-disable no-console */
/* eslint-disable no-magic-numbers */
/* eslint-disable max-lines */
/* eslint-disable no-unused-vars */
/* eslint-disable no-useless-escape */
/* eslint-disable @scandipwa/scandipwa-guidelines/use-namespace */
/* eslint-disable fp/no-let */
/* eslint-disable fp/no-let */

import axios from 'axios';
import { DateTime } from 'luxon';

import {
    isAusStore, isEurStore, isIntStore, isRsaStore, isUsaStore
} from './Store';

/**
 * Is Black Friday Active?
 * @namespace Util/FrontRunner/Store/Promotions/getBlackFridayPromo
*/
export const getBlackFridayPromo = () => {
    // Check if B2B customer (Use local storage check)
    const isB2B = JSON.parse(sessionStorage.getItem('is_b2b')) ?? false;

    // Date format
    const dateFormat = 'yyyy-MM-dd HH:mm:ss';

    // Black Friday end date
    let startDate = null;
    const endDate = '2022-11-29 01:00:00';

    // Is Black Friday active?
    let active = false;

    // RSA
    if (isRsaStore()) {
        const timeNow = DateTime.now().setZone('Africa/Johannesburg').toFormat(dateFormat);
        // B2B
        if (isB2B) {
            startDate = '2022-11-13 23:55:00';
            if (timeNow >= startDate && timeNow <= endDate) {
                active = true;
            }
        // B2C
        } else {
            startDate = '2022-11-13 23:55:00';
            if (timeNow >= startDate && timeNow <= endDate) {
                active = true;
            }
        }
    }

    // USA
    if (isUsaStore()) {
        const timeNow = DateTime.now().setZone('America/New_York').toFormat(dateFormat);
        // B2B
        if (isB2B) {
            startDate = '2022-10-31 00:00:00';
            if (timeNow >= startDate && timeNow <= endDate) {
                active = true;
            }
        // B2C
        } else {
            startDate = '2022-11-07 00:00:00';
            if (timeNow >= startDate && timeNow <= endDate) {
                active = true;
            }
        }
    }

    // AUS
    if (isAusStore()) {
        const timeNow = DateTime.now().setZone('Australia/Sydney').toFormat(dateFormat);
        // B2B
        if (isB2B) {
            startDate = '2022-10-31 00:00:00';
            if (timeNow >= startDate && timeNow <= endDate) {
                active = true;
            }
        // B2C
        } else {
            startDate = '2022-11-07 00:00:00';
            if (timeNow >= startDate && timeNow <= endDate) {
                active = true;
            }
        }
    }

    // EUR
    if (isEurStore()) {
        const timeNow = DateTime.now().setZone('Europe/Berlin').toFormat(dateFormat);
        // B2B
        if (isB2B) {
            startDate = '2022-11-13 22:55:00';
            if (timeNow >= startDate && timeNow <= endDate) {
                active = true;
            }
        // B2C
        } else {
            startDate = '2022-11-13 22:55:00';
            if (timeNow >= startDate && timeNow <= endDate) {
                active = true;
            }
        }
    }

    // FOR TESTING
    // Check if not production environment
    // if (process.env.NODE_ENV !== 'production') {
    //     active = true;
    //     startDate = '2022-11-01 00:00:00';
    // }

    return {
        active,
        startDate,
        endDate
    };
};

/**
 * Is Xmas Promotion Active?
 * @namespace Util/FrontRunner/Store/Promotions/getXmasPromo
*/
export const getXmasPromo = () => {
    const isB2B = JSON.parse(sessionStorage.getItem('is_b2b')) ?? false;

    // Date format
    const dateFormat = 'yyyy-MM-dd HH:mm:ss';

    const startDate = '2022-12-05 07:00:00';
    const endDate = '2022-12-17 06:59:59';

    let active = false;

    // RSA
    if (isRsaStore()) {
        const timeNow = DateTime.now().setZone('Africa/Johannesburg').toFormat(dateFormat);
        if (timeNow >= startDate && timeNow <= endDate) {
            active = true;
        }
    }

    // USA
    if (isUsaStore()) {
        const timeNow = DateTime.now().setZone('America/New_York').toFormat(dateFormat);
        if (timeNow >= startDate && timeNow <= endDate) {
            active = true;
        }
    }

    // AUS
    if (isAusStore()) {
        const timeNow = DateTime.now().setZone('Australia/Sydney').toFormat(dateFormat);
        if (timeNow >= startDate && timeNow <= endDate) {
            active = true;
        }
    }

    // EUR
    if (isEurStore()) {
        const timeNow = DateTime.now().setZone('Europe/Berlin').toFormat(dateFormat);
        if (timeNow >= startDate && timeNow <= endDate) {
            active = true;
        }
    }

    // FOR TESTING
    // Check if not production environment
    if (process.env.NODE_ENV !== 'production') {
        // active = true;
        // startDate = '2022-11-01 00:00:00';
    }

    return {
        active,
        startDate,
        endDate
    };
};

/**
 * B2B Products with additional discounts that are applied at cart level
 * @namespace Util/FrontRunner/Store/Promotions/b2bAdditionalDiscounts
*/
export const b2bAdditionalDiscounts = () => {
    // Check if B2B customer (Use local storage check)
    const isB2B = JSON.parse(sessionStorage.getItem('is_b2b')) ?? false;
    if (!isB2B) {
        return {};
    }

    // 5% Discounts
    let group5 = [];
    // 10% Discounts
    let group10 = [];

    // RSA
    if (isRsaStore()) {
        group5 = [];
        group10 = ['LALD007', 'VACC021', 'SSST003', 'TRAC002', 'RRAC168', 'RRAC171', 'BPLD008', 'WPTH001', 'BBRA005'];
    }
    // AUS
    if (isAusStore()) {
        group5 = [];
        group10 = ['RRAC205', 'VACC021', 'STRA037', 'LALD007', 'SSST003', 'SSLD002', 'WTAN059', 'TRAC002', 'RRAC168', 'RRAC171', 'FSLI020', 'FSLI021', 'FSLI022', 'FSLI023', 'SGLD007', 'LIGH183', 'BBTH002', 'WPTH001', 'FSLI001', 'FSLI002', 'FSLI005', 'FSLI006', 'SAFE10'];
    }
    // USA
    if (isUsaStore()) {
        group5 = [];
        group10 = ['TENT031', 'RRAC205', 'VACC021', 'STRA037', 'LALD007', 'WTAN059', 'RRAC168', 'TRAC002', 'RRAC168', 'RRAC214', 'RRAC171', 'FSLI020', 'FSLI021', 'FSLI022', 'FSLI023', 'FSLI001', 'AWNI038', 'AWNI039', 'AWNI043', 'AWNI044', 'TENT049', 'TENT054', 'TENT037', 'LATF001'];
    }
    // EUR
    if (isEurStore()) {
        group5 = [];
        group10 = ['STRA037', 'VACC021', 'LALD007', 'SSLD002', 'SSST003', 'WTAN059', 'RRAC205', 'TRAC002', 'RRAC168', 'RRAC214', 'RRAC171', 'FSLI020', 'FSLI021', 'FSLI022', 'FSLI023', 'BBTH002', 'RRAC105', 'WPTH001', 'FSLI001', 'SAFE10', 'AWNI038', 'AWNI039', 'AWNI043', 'AWNI044', 'TENT049', 'TENT037'];
    }

    return {
        group5,
        group10
    };
};

/**
 * Get Gift Promotions
 * @namespace Util/FrontRunner/Store/Promotions/getGiftPromoRules
*/
export const getGiftPromoRules = async (payload = {}) => {
    // No rules are currently active so return null
    // return null;

    const storeId = window.storeConfig.storeId;
    if (storeId === undefined) {
        return null;
    }

    const { items, cartSubtotal = 0, is_b2b } = payload;

    // Get and validate gifts over api
    const data = {
        store_id: storeId,
        cartSubtotal,
        items,
        is_b2b
    };

    // Fsync Url
    let fsyncUrl = 'https://mcfsync-staging.frontrunneroutfitters.com/';
    if (process.env.NODE_ENV === 'production' && window.location.origin.includes('www.frontrunneroutfitters.com')) {
        fsyncUrl = 'https://fsync.frontrunneroutfitters.com/';
    }

    // Send request
    try {
        const response = await axios.post(`${fsyncUrl}api/v2/magento/gift-promos/gifts`, data);

        return response.data.gifts;
    } catch (error) {
        console.log('Gift Promo Error', error);
    }

    return null;
};

/**
 * Get Gift Promotions Widget - Used on Product Page
 * @namespace Util/FrontRunner/Store/Promotions/getGiftPromoWidgets
*/
export const getGiftPromoWidgets = async (payload = {}) => {
    // Get and validate gifts over api
    const storeId = window.storeConfig.storeId;
    if (storeId === undefined) {
        return null;
    }

    const { sku, is_b2b = false } = payload;

    // Fsync Url
    let fsyncUrl = 'https://mcfsync-staging.frontrunneroutfitters.com/';
    if (process.env.NODE_ENV === 'production' && window.location.origin.includes('www.frontrunneroutfitters.com')) {
        fsyncUrl = 'https://fsync.frontrunneroutfitters.com/';
    }

    // Send request
    try {
        const response = await axios.get(`${fsyncUrl}api/v2/magento/gift-promos/widget?sku=${sku}&store_id=${storeId}&is_b2b=${is_b2b}`);

        return response.data.widgets;
    } catch (error) {
        console.error('Gift Promo Widget Error', error);
    }

    return null;
};

/**
 * Get Gift Promotions Blocks - Used on Landing Page
 * @namespace Util/FrontRunner/Store/Promotions/getGiftPromoBlocks
*/
export const getGiftPromoBlocks = async () => {
    // Check if B2B customer (Use local storage check)
    const isB2B = JSON.parse(sessionStorage.getItem('is_b2b')) ?? false;
    if (isB2B) {
        return false;
    }

    // Get and validate gifts over api
    const storeId = window.storeConfig.storeId;
    if (storeId === undefined) {
        return null;
    }

    // Fsync Url
    let fsyncUrl = 'https://mcfsync-staging.frontrunneroutfitters.com/';
    if (process.env.NODE_ENV === 'production' && window.location.origin.includes('www.frontrunneroutfitters.com')) {
        fsyncUrl = 'https://fsync.frontrunneroutfitters.com/';
    }

    // Send request
    try {
        const response = await axios.get(`${fsyncUrl}api/v2/magento/gift-promos/blocks?store_id=${storeId}`);
        return response.data.blocks;
    } catch (error) {
        console.error('Gift Promo Blocks Error', error);
    }

    return null;
};

/**
 * Get Subscriber Drive Promotion - Use for popup
 * @namespace Util/FrontRunner/Store/Promotions/getSubscriberDrivePromo
*/
export const getSubscriberDrivePromo = () => {
    const startDate = '2024-06-01 00:00:00';
    const endDate = '2024-06-16 23:59:59';

    // Date format
    const dateFormat = 'yyyy-MM-dd HH:mm:ss';

    let active = false;
    let popupHeaderText = 'Subscribe and Win';
    let popupBodyText = 'Start the spring adventures';
    let popupBodyText2 = 'SUBSCRIBE NOW FOR YOUR SHOT AT WINNING A $750 VOUCHER AND MAKE THIS SEASON ONE TO REMEMBER!<br />DON\'T MISS OUT AND SUBSCRIBE TODAY! ';
    let popupImage = 'https://www.frontrunneroutfitters.com/media/promotions/subscriber_drive_usa.jpg';
    let widgetBodyHeader = 'Get 10% Off <br /><span>your next order</span>';
    let widgetBodyText = 'Enjoy some extra cheer when creating an account. This limited Festive deal is valid on all Front Runner Dometic products until 31 December.';
    let widgetImage = 'https://www.frontrunneroutfitters.com/media/promotions/Signup-Page-370px-610px-US.jpg';
    let categoryHeaderText = '<strong>10% Off All Front Runner Dometic Products!</strong> Offer Valid Until 31 December. <span>Discount applied at checkout.</span>';

    // RSA
    if (isRsaStore()) {
        /* const timeNow = DateTime.now().setZone('Africa/Johannesburg').toFormat(dateFormat);
        if (timeNow >= startDate && timeNow <= endDate) {
            active = true;
        }

        popupHeaderText = 'SUBSCRIBE AND WIN';
        popupBodyText = 'LAST DAYS OF SUMMER';
        popupBodyText2 = 'SUBSCRIBE NOW FOR YOUR SHOT AT WINNING A R5000 VOUCHER AND MAKE THIS SEASON ONE TO REMEMBER!<br />DON\'T MISS OUT AND SUBSCRIBE TODAY!';
        popupImage = 'https://www.frontrunneroutfitters.com/media/promotions/subscriber_drive_rsa.jpg';
        // widgetImage = 'https://www.frontrunneroutfitters.com/media/promotions/Signup-Page-370px-610px-ZA.jpg'; */

        active = false;
    }

    // USA
    if (isUsaStore()) {
        /* const timeNow = DateTime.now().setZone('America/New_York').toFormat(dateFormat);
        if (timeNow >= startDate && timeNow <= endDate) {
            active = true;
        }

        if (window.storeConfig.code === 'es_us') {
            popupHeaderText = 'SUSCRÍBETE Y GANA';
            popupBodyText = 'COMIENZAN LAS AVENTURAS DE PRIMAVERA';
            popupBodyText2 = 'SUSCRÍBETE AHORA PARA GANAR UN VALE CON VALOR DE $750 Y ¡HAZ DE ESTA, UNA TEMPORADA INOLVIDABLE!<br />NO TE LO PIERDAS ¡INSCRÍBETE HOY MISMO!';
        } */

        active = false;
    }

    // AUS
    if (isAusStore()) {
        const timeNow = DateTime.now().setZone('Australia/Sydney').toFormat(dateFormat);
        if (timeNow >= startDate && timeNow <= endDate) {
            active = true;
        }

        popupHeaderText = 'EOFY SALE';
        popupBodyText = 'SIGN UP FOR EARLY ACCESS';
        popupBodyText2 = 'SUBSCRIBE NOW AND BE THE FIRST TO HEAR ABOUT OUR EOFY SALE!';
        popupImage = 'https://www.frontrunneroutfitters.com/media/promotions/en_au_eofy_subscriber_drive_2024_430_660.jpg';
        widgetImage = 'https://www.frontrunneroutfitters.com/media/promotions/Signup-Page-370px-610px-AU.jpg';
    }

    // EUR
    if (isEurStore()) {
        /* const timeNow = DateTime.now().setZone('Europe/Berlin').toFormat(dateFormat);
        if (timeNow >= startDate && timeNow <= endDate) {
            active = true;
        }

        popupImage = 'https://www.frontrunneroutfitters.com/media/promotions/Website-Popup-430px-660px-EU.jpg';
        widgetImage = 'https://www.frontrunneroutfitters.com/media/promotions/Signup-Page-370px-610px-EU.jpg';

        // Text translations
        if (window.storeConfig.code === 'de_de') {
            popupHeaderText = 'Erhalte 10% Rabatt <br /><span>auf Deine nächste Bestellung</span>';
            popupBodyText = 'Eine Extraportion Freude, wenn Du Dich anmeldest oder ein Konto erstellst. Gültig für alle Front Runner Dometic Produkte und nur bis zum 31. Dezember.';
            widgetBodyHeader = 'Erhalte 10% Rabatt <br /><span>auf Deine nächste Bestellung</span>';
            widgetBodyText = 'Eine Extraportion Freude, wenn Du ein Konto erstellst. Gültig für alle Front Runner Dometic Produkte und nur bis zum 31. Dezember.';
            categoryHeaderText = '<strong>10% Rabatt auf alle Front Runner Dometic Produkte!</strong> Das Angebot ist nur bis zum 31. Dezember gültig. <span>Der Rabatt wird bei der Bezahlung angewendet.</span>';
        }
        if (window.storeConfig.code === 'fr_be') {
            popupHeaderText = 'Recevez 10% de remise <br /><span>sur votre prochaine commande</span>';
            popupBodyText = 'Profitez-en encore plus en vous connectant ou en créant votre compte. Ces offres de fêtes sont valables sur tous les produits Front Runner Dometic jusqu\'au 31 décembre. ';
            widgetBodyHeader = 'Recevez 10% de remise <br /><span>sur votre prochaine commande</span>';
            widgetBodyText = 'Profitez-en encore plus en créant votre compte. Ces offres de fêtes sont valables sur tous les produits Front Runner Dometic jusqu\'au 31 décembre.';
            categoryHeaderText = '<strong>10% de remise sur tous les produits Front Runner Dometic !</strong> Offre valable jusqu\'au 31 décembre. <span>Remise appliquée à la validation du panier.</span>';
        }
        if (window.storeConfig.code === 'es_be') {
            popupHeaderText = 'Obtenga un 10% <br /><span>en su próxima compra</span>';
            popupBodyText = 'Disfruta de una alegría extra al iniciar sesión o crear una cuenta. Esta oferta festiva limitada es válida para todos los productos Front Runner Dometic hasta el 31 de Diciembre.';
            widgetBodyHeader = 'Obtenga un 10% <br /><span>en su próxima compra</span>';
            widgetBodyText = 'Disfruta de una alegría extra al crear una cuenta. Esta oferta festiva limitada es válida para todos los productos Front Runner Dometic hasta el 31 de Diciembre.';
            categoryHeaderText = '<strong>¡10% de descuento en todos los productos Front Runner Dometic!</strong> Oferta válida hasta el 31 de Diciembre. <span>Descuento aplicado al momento de la compra.</span>';
        }
        if (window.storeConfig.code === 'nl_be') {
            popupHeaderText = 'Ontvang 10% korting <br /><span>op je volgende bestelling</span>';
            popupBodyText = 'Geniet van wat extra vrolijkheid bij het inloggen of het aanmaken van een account. Deze beperkte Feestdeal is t/m 31 december geldig op alle Front Runner Dometic producten. ';
            widgetBodyHeader = 'Ontvang 10% korting <br /><span>op je volgende bestelling</span>';
            widgetBodyText = 'Geniet van wat extra vrolijkheid als je een account aanmaakt. Deze beperkte Feestdeal is t/m 31 december geldig op alle Front Runner Dometic producten.';
            categoryHeaderText = '<strong>10% korting op alle Front Runner Dometic producten!</strong> Aanbod geldig t/m 31 december. <span>Korting toegepast bij de kassa.</span>';
        } */

        active = false;
    }

    return {
        active,
        popupHeaderText,
        popupBodyText,
        popupBodyText2,
        popupImage,
        widgetBodyHeader,
        widgetBodyText,
        widgetImage,
        categoryHeaderText
    };
};

/**
 * Is Generic Sale Promotions? (E.g. Fathersday, 4 July)
 * @namespace Util/FrontRunner/Store/Promotions/getSalesPromotion
*/
export const getSalesPromotion = () => {
    // Check if B2B customer (Use local storage check)
    const isB2B = JSON.parse(sessionStorage.getItem('is_b2b')) ?? false;

    // Date format
    const dateFormat = 'yyyy-MM-dd HH:mm:ss';

    // Sale end date
    let startDate = null;
    let endDate = '2025-01-01 04:00:00';

    // Is sale active?
    let active = false;

    // Can use coupons during promotion?
    let useCoupons = false;

    // Show countdown
    let showProductCountdown = true;

    // USA
    if (isUsaStore()) {
        const timeNow = DateTime.now().setZone('America/New_York').toFormat(dateFormat);
        endDate = '2025-01-01 04:00:00';

        // B2B
        if (isB2B) {
            startDate = '2024-12-09 00:00:01';
            if (timeNow >= startDate && timeNow <= endDate) {
                active = true;
            }
        // B2C
        } else {
            startDate = '2024-12-09 00:00:01';
            if (timeNow >= startDate && timeNow <= endDate) {
                active = true;
            }
        }
    }

    // AUS
    if (isAusStore()) {
        const timeNow = DateTime.now().setZone('Australia/Sydney').toFormat(dateFormat);
        endDate = '2025-04-14 04:00:00';

        showProductCountdown = false;

        // B2B
        if (isB2B) {
            // startDate = '2024-12-09 00:00:01';
            // if (timeNow >= startDate && timeNow <= endDate) {
            //     active = true;
            // }
        // B2C
        } else {
            startDate = '2025-04-01 00:00:01';
            if (timeNow >= startDate && timeNow <= endDate) {
                active = true;
            }
        }
    }

    // RSA
    if (isRsaStore()) {
        const timeNow = DateTime.now().setZone('Africa/Johannesburg').toFormat(dateFormat);
        endDate = '2025-04-14 02:00:00';

        // B2B
        if (isB2B) {
            // startDate = '2024-12-09 00:00:01';
            // if (timeNow >= startDate && timeNow <= endDate) {
            //     active = true;
            // }
        // B2C
        } else {
            startDate = '2025-04-01 00:00:01';
            if (timeNow >= startDate && timeNow <= endDate) {
                active = true;
            }
        }
    }

    // EUR
    if (isEurStore(false)) {
        // Allow coupons
        useCoupons = false;

        const timeNow = DateTime.now().setZone('Europe/Berlin').toFormat(dateFormat);
        endDate = '2025-01-01 04:00:00';

        // if (timeNow >= startDate && timeNow <= endDate) {
        //     active = true;
        // }

        // B2B
        if (isB2B) {
            startDate = '2024-12-09 00:00:01';
            if (timeNow >= startDate && timeNow <= endDate) {
                active = true;
            }
        // B2C
        } else {
            startDate = '2024-12-09 00:00:01';
            if (timeNow >= startDate && timeNow <= endDate) {
                active = true;
                useCoupons = false;
            }
        }
    }

    // FOR TESTING
    // Check if not production environment
    // if (process.env.NODE_ENV !== 'production') {
    //     active = true;
    //     startDate = '2022-11-01 00:00:00';
    // }

    return {
        active,
        startDate,
        endDate,
        useCoupons,
        showProductCountdown
    };
};
