/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-restricted-properties */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable @scandipwa/scandipwa-guidelines/create-config-files */
/* eslint-disable react/no-unused-state */
/* eslint-disable react/jsx-curly-spacing */
/* eslint-disable react/no-danger */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable valid-typeof */
/* eslint-disable no-empty */
/* eslint-disable radix */
/* eslint-disable no-magic-numbers */
/* eslint-disable react/style-prop-object */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react/sort-comp */
/* eslint-disable react/prop-types */
/* eslint-disable @scandipwa/scandipwa-guidelines/derived-class-names */
/* eslint-disable @scandipwa/scandipwa-guidelines/use-namespace */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/* eslint-disable no-restricted-globals */
/* eslint-disable eqeqeq */
/* eslint-disable consistent-return */
/* eslint-disable @scandipwa/scandipwa-guidelines/no-jsx-variables */
/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable no-undef */
/* eslint-disable no-console */
/* eslint-disable react/no-string-refs */
/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable fp/no-let */
/* eslint-disable prefer-const */
/* eslint-disable max-len */
/* eslint-disable max-lines */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-conditional */
/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/boolean-prop-naming */
/* eslint-disable react/require-default-props */
/* eslint-disable no-unused-vars */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

/* eslint-disable no-restricted-syntax */
/* eslint-disable react/no-array-index-key */
// Disabled due placeholder needs
import {
    faInfoCircle
} from '@fortawesome/pro-light-svg-icons';
import {
    faCircleStar,
    faExclamationTriangle,
    faShippingFast,
    faTrophy
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Loader from '@scandipwa/scandipwa/src/component/Loader';
import { Elements, PaymentElement, PaymentMethodMessagingElement } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import Payjustnowlogo from 'images/payjustnow/payjustnow_logo_dark_theme.png';
import PayjustnowlogoLight from 'images/payjustnow/payjustnow_logo_light_theme.png';
import BrowserIcon from 'images/payjustnow/PJNStep1.png';
import PaymentmethodIcon from 'images/payjustnow/PJNStep2.png';
import CreateAcIcon from 'images/payjustnow/PJNStep3.png';
import CompleteIcon from 'images/payjustnow/PJNStep4.png';
import TickIcon from 'images/payjustnow/PJNTick.png';
import Payflexlogo from 'images/payment_icons/payflex.png';
import {
    compact, each, filter, get, isEmpty, map
} from 'lodash';
import postscribe from 'postscribe';
import PropTypes from 'prop-types';
import { createRef, PureComponent } from 'react';
import {
    Accordion,
    AccordionItem,
    AccordionItemButton,
    AccordionItemHeading,
    AccordionItemPanel,
    AccordionItemState
} from 'react-accessible-accordion';
import LazyLoad from 'react-lazy-load';
import {
    EmailIcon,
    FacebookIcon,
    FacebookShareButton,
    LinkedinIcon,
    LinkedinShareButton,
    PinterestIcon,
    PinterestShareButton,
    TelegramIcon,
    TelegramShareButton,
    TwitterIcon,
    TwitterShareButton,
    WhatsappIcon,
    WhatsappShareButton
} from 'react-share';
import Pinterest from 'react-sharingbuttons/dist/buttons/Pinterest';

import AddToCart from 'Component/AddToCart';
import SalesCountdown from 'Component/Countdown/SalesCountdown';
import EmailFriend from 'Component/EmailFriend';
import Field from 'Component/Field';
import Form from 'Component/Form';
import GroupedProductList from 'Component/GroupedProductList';
import Html from 'Component/Html';
import Image from 'Component/Image';
import Link from 'Component/Link';
// import RelatedProducts from 'Component/RelatedProducts';
import ProductAccordions from 'Component/ProductAccordions';
// import ProductBundleItems from 'Component/ProductBundleItems';
// import { OUT_OF_STOCK } from 'Component/ProductCard/ProductCard.config';
import ProductConfigurableAttributes from 'Component/ProductConfigurableAttributes';
import ProductCustomizableOptions from 'Component/ProductCustomizableOptions';
import ProductLinks from 'Component/ProductLinks';
import ProductPrice from 'Component/ProductPrice';
import ProductQA from 'Component/ProductQA';
import ProductReviewRating from 'Component/ProductReviewRating';
import ProductReviews from 'Component/ProductReviews';
import ProductWishlistButton from 'Component/ProductWishlistButton';
import TextPlaceholder from 'Component/TextPlaceholder';
import TierPrices from 'Component/TierPrices';
import { RELATED, UPSELL } from 'Store/LinkedProducts/LinkedProducts.reducer';
import { ProductType } from 'Type/ProductList';
import { isSignedIn } from 'Util/Auth';
import { breadpayKey } from 'Util/BreadpayKeys';
import { isCrawler, isSSR } from 'Util/Browser';
import { hasFreeShipping, isRackKit } from 'Util/FrontRunner/Product';
import {
    isAusStore, isEurStore, isIntStore, isRsaStore, isUsaStore
} from 'Util/FrontRunner/Store';
import { getGiftPromoWidgets } from 'Util/FrontRunner/Store/Promotions';
import { isB2BUser, scrollToTop } from 'Util/Helper';
import {
    formatCurrency,
    formatPrice,
    roundPrice
} from 'Util/Price';
import {
    BUNDLE, CONFIGURABLE, GIFT_CARD, GROUPED
} from 'Util/Product';

import { isGbrStore } from '../../util/FrontRunner/Store';
import { addToRecentlyViewedProducts } from '../../util/Product/Product';

import 'react-sharingbuttons/dist/main.css';
import './ProductActions.style';
// eslint-disable-next-line @scandipwa/scandipwa-guidelines/create-config-files
export const OUT_OF_STOCK = 'OUT_OF_STOCK';
export const PRODUCT_Q_SCRIPT_ID = 'productquestions_sc';

/**
 * Product actions
 * @class ProductActions
 * @namespace Pwa/Component/ProductActions/Component/ProductActionsComponent */
export class ProductActions extends PureComponent {
    static propTypes = {
        product: ProductType.isRequired,
        productOrVariant: PropTypes.object.isRequired,
        minQuantity: PropTypes.number.isRequired,
        maxQuantity: PropTypes.number.isRequired,
        configurableVariantIndex: PropTypes.number,
        showOnlyIfLoaded: PropTypes.func.isRequired,
        quantity: PropTypes.number.isRequired,
        customGiftPrice: PropTypes.any,
        areDetailsLoaded: PropTypes.bool.isRequired,
        getLink: PropTypes.func.isRequired,
        setQuantity: PropTypes.func.isRequired,
        setCustomPrice: PropTypes.func,
        updateConfigurableVariant: PropTypes.func.isRequired,
        parameters: PropTypes.objectOf(PropTypes.string).isRequired,
        getIsConfigurableAttributeAvailable: PropTypes.func.isRequired,
        groupedProductQuantity: PropTypes.objectOf(PropTypes.number).isRequired,
        clearGroupedProductQuantity: PropTypes.func.isRequired,
        setGroupedProductQuantity: PropTypes.func.isRequired,
        showErrorNotification: PropTypes.func.isRequired,
        updateGiftCardFormData: PropTypes.func,
        updateGiftCardPrice: PropTypes.func,
        updateGiftCardDesign: PropTypes.func,
        giftCardData: PropTypes.object,
        frontend_store_url: PropTypes.any
    };

    static defaultProps = {
        configurableVariantIndex: 0
    };

    configurableOptionsRef = createRef();

    groupedProductsRef = createRef();

    _toCents(aValue) {
        return Math.round((Math.abs(aValue) / 100) * 10000);
    }

    componentDidUpdate(prevProps) {
        const { product = {}, getBreadPayAsLowAsAmount, areDetailsLoaded } = this.props;
        const { sku, id } = product;
        const { getAsLowAsFlag } = this.state;

        if (areDetailsLoaded && !getAsLowAsFlag && sku !== 'VOUC002') {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({ getAsLowAsFlag: true }, () => {
                getBreadPayAsLowAsAmount();
            });
        }

        if (prevProps.selectedBundlePrice !== this.props.selectedBundlePrice && sku !== 'VOUC002') {
            getBreadPayAsLowAsAmount();
        }

        if (sku && sku !== 'VOUC002') {
            const {
                areDetailsLoaded,
                product: {
                    price_range: {
                        minimum_price: { default_final_price }
                    }
                },
                selectedBundlePrice
            } = this.props;

            this.renderZipLine();

            let finalPrice = this._toCents(default_final_price?.value);
            let finalPriceCurrency = default_final_price?.currency;

            if (selectedBundlePrice !== 0) {
                finalPrice = this._toCents(selectedBundlePrice);
            }

            const placement = {
                financingType: 'installment',
                locationType: 'product',
                domID: 'bread-prequal-btn',
                allowCheckout: false,
                order: {
                    items: [],
                    subTotal: {
                        value: finalPrice,
                        currency: finalPriceCurrency
                    },
                    totalTax: {
                        value: 0,
                        currency: finalPriceCurrency
                    },
                    totalShipping: {
                        value: 0,
                        currency: finalPriceCurrency
                    },
                    totalDiscounts: {
                        value: 0,
                        currency: finalPriceCurrency
                    },
                    totalPrice: {
                        value: finalPrice,
                        currency: finalPriceCurrency
                    }
                }
            };

            const placements = [
                placement
            ];

            const BreadFinancialObj = window.BreadPayments;
            if (BreadFinancialObj) {
                BreadFinancialObj.setup({
                    integrationKey: breadpayKey()
                });

                BreadFinancialObj.setInitMode('manual'); // puts Bread into manual mode, is 'auto' by default

                // callback runs after customer application result
                BreadFinancialObj.on('INSTALLMENT:APPLICATION_DECISIONED', (installmentResult) => {
                    console.log(installmentResult);
                });
                // callback runs after customer completes checkout
                BreadFinancialObj.on('INSTALLMENT:APPLICATION_CHECKOUT', (installmentResult) => {
                    console.log(installmentResult);
                });

                BreadFinancialObj.registerPlacements(placements);

                BreadFinancialObj.init(); // call the 'init' function when you want Bread to render on the page
            }
        }

        const url = window.location.href.includes('#reviews');
        if (url) {
            this.scrollToBottom();
        }
        this._loadStripeBuylater();
    }

    componentDidMount() {
        const {
            product = {},
            product: {
                configurable_product_options_selection = {},
                variants
            },
            setRefs,
            configurableVariantIndex,
            getBreadPayAsLowAsAmount
        } = this.props;

        scrollToTop();
        // const { sku } = product;

        setRefs({
            configurableOptionsRef: this.configurableOptionsRef,
            groupedProductsRef: this.groupedProductsRef
        });

        const { variant } = configurable_product_options_selection;
        let productOrVariant = variants && variants[configurableVariantIndex] !== undefined
            ? variants[configurableVariantIndex]
            : product;

        productOrVariant = variant ?? productOrVariant ?? product;
        const { sku } = productOrVariant;

        if (sku && sku !== 'VOUC002') {
            this.setState({ getAsLowAsFlag: true }, () => {
                getBreadPayAsLowAsAmount();
            });
        }

        if (sku) {
            const skuArray = [sku];
            skuArray.push(localStorage.getItem('skusList'));
            localStorage.setItem('skusList', skuArray);
            const skusList = localStorage.getItem('skusList', '');
            addToRecentlyViewedProducts(sku);
        }
        let retryCount = 0;

        function initYotpoWidgets() {
            if (window.yotpoWidgetsContainer && typeof window.yotpoWidgetsContainer.initWidgets === 'function') {
                setTimeout(() => {
                    window.yotpoWidgetsContainer.initWidgets();
                }, 1000);
            } else if (retryCount < 2) { // Stop after 2 attempts
                console.warn('yotpoWidgetsContainer or initWidgets is not available, retrying...');
                retryCount++;
                setTimeout(initYotpoWidgets, 1000); // Retry after 1000ms
            } else {
                console.warn('yotpoWidgetsContainer init failed after 2 attempts');
            }
        }

        // Start the initial attempt
        initYotpoWidgets();

        this._loadStripeBuylater();
    }

    async _loadStripeBuylater() {
        const {
            areDetailsLoaded,
            product: {
                price_range: {
                    minimum_price: { default_final_price = {} } = {}
                } = {}
            },
            stripe_test_key,
            stripe_live_Key
        } = this.props;

        const { storeId, currencyCode } = window?.storeConfig;

        let paymentMethods = [];
        let currency = '';
        let country = '';

        switch (storeId) {
        case 5:
        case 16:
            paymentMethods = ['klarna', 'afterpay_clearpay', 'affirm'];
            currency = 'USD';
            country = 'US';
            break;
        case 8:
        case 9:
        case 12:
        case 14:
        case 15:
            paymentMethods = ['klarna'];
            currency = 'EUR';
            country = 'DE';
            break;
        case 17:
            paymentMethods = ['klarna'];
            currency = 'GBP';
            country = 'GB';
            break;
        case 4:
            paymentMethods = [];
            currency = 'ZAR';
            country = 'ZA';
            break;
        case 7:
            paymentMethods = ['klarna', 'afterpay_clearpay'];
            currency = 'AUD';
            country = 'AU';
            break;
        default:
            paymentMethods = ['klarna'];
            currency = 'USD';
            country = 'US';
            break;
        }

        const finalPrice = default_final_price?.value;

        const stripe = await loadStripe(stripe_live_Key);

        if (stripe && finalPrice) {
            const elements = stripe.elements();

            const options = {
                amount: this._toCents(finalPrice), // $99.00 USD
                currency,
                paymentMethodTypes: paymentMethods,
                countryCode: country // The country the end-buyer is in
            };

            // Check if the DOM element exists
            const paymentElement = document.querySelector('#payment-method-messaging-element');
            if (paymentElement) {
                // Create and mount the PaymentMessageElement
                const PaymentMessageElement = elements.create('paymentMethodMessaging', options);
                PaymentMessageElement.mount('#payment-method-messaging-element');
            } else {
                console.error('error The #payment-method-messaging-element does not exist in the DOM.');
            }
        } else {
            console.error('error Stripe has not loaded or finalPrice is not available.');
        }
    }

    onConfigurableProductError = this.onProductError.bind(this, this.configurableOptionsRef);

    onGroupedProductError = this.onProductError.bind(this, this.groupedProductsRef);

    state = {
        showValidationError: 'noError',
        addProgress: false,
        setGiftWidgets: false,
        giftWidgets: [],
        getAsLowAsFlag: false
    };

    scrollToBottom = () => {
        this.scrollRef?.scrollIntoView({ behavior: 'smooth' });
    };

    renderZipLine = () => {
        const divZip = document.getElementById('zipline');
        const { zipline = false } = this.state;
        if (divZip != undefined && divZip != null && window.storeConfig.code === 'en_au' && !zipline) {
            postscribe('#zipline', '<script type="text/javascript" src="https://static.zipmoney.com.au/lib/js/zm-widget-js/dist/zip-widget.min.js"></script>', {
                done: () => {
                }
            });
            this.setState({ zipline: true });
        }
    };

    onProductError(ref) {
        if (!ref) {
            return;
        }
        const { current } = ref;

        current.scrollIntoView({
            behavior: 'smooth',
            block: 'center'
        });

        current.classList.remove('animate');
        // eslint-disable-next-line no-unused-expressions
        current.offsetWidth; // trigger a DOM reflow
        current.classList.add('animate');
    }

    onProductValidationError = (type) => {
        switch (type) {
        case CONFIGURABLE:
            this.onConfigurableProductError();
            break;
        case GROUPED:
            this.onGroupedProductError();
            break;
        default:
            this.triggerOnAddToCart();
            break;
        }
    };

    renderSKU() {
        const {
            product,
            product: { configurable_product_options_selection = {}, variants },
            configurableVariantIndex,
            showOnlyIfLoaded
        } = this.props;

        const { variant } = configurable_product_options_selection;
        let productOrVariant = variants && variants[configurableVariantIndex] !== undefined
            ? variants[configurableVariantIndex]
            : product;

        productOrVariant = variant ?? productOrVariant ?? product;
        const { sku } = productOrVariant;

        return (
            <div
              block="ProductActions"
              elem="Attribute"
              mods={ { type: 'product_sku' } }
              aria-label="Product sku"
            >
                { sku && (
                    <span block="ProductActions" elem="ProductSKU" itemProp="sku" content={ sku }>
                        <meta itemProp="sku" content={ sku } />
                        { `SKU: ${sku}` }
                    </span>
                ) }
            </div>
        );
    }

    renderSkuAndStock() {
        const {
            product,
            product: { variants },
            configurableVariantIndex,
            showOnlyIfLoaded
        } = this.props;
        const productOrVariant = variants && variants[configurableVariantIndex] !== undefined
            ? variants[configurableVariantIndex]
            : product;

        const { sku, stock_status } = productOrVariant;

        return (
            <section
              block="ProductActions"
              elem="Section"
              mods={ { type: 'sku' } }
              aria-label="Product SKU and availability"
            >
                { showOnlyIfLoaded(
                    sku,
                    (
                        <>
                            <span block="ProductActions" elem="Sku" itemProp="sku" content={ sku }>
                                { `${__('SKU')}: ${sku}` }
                            </span>
                            <span block="ProductActions" elem="Stock">
                                { (stock_status === 'OUT_OF_STOCK') ? __('Out of stock') : __('In stock') }
                            </span>
                        </>
                    ),
                    <TextPlaceholder />
                ) }
            </section>
        );
    }

    renderConfigurableAttributes() {
        const {
            getLink,
            updateConfigurableVariant,
            parameters,
            areDetailsLoaded,
            product: { configurable_options, type_id, variants },
            getIsConfigurableAttributeAvailable,
            isLoading,
            resetDropDown,
            setAddToCartEnable,
            enableAddToCart
        } = this.props;

        if (type_id !== 'configurable') {
            return null;
        }

        return (
            <div
              ref={ this.configurableOptionsRef }
              block="ProductActions"
              elem="AttributesWrapper"
            >
                {isLoading && <Loader isLoading /> }
                { configurable_options
                && (
                    <ProductConfigurableAttributes
                      resetDropDown={resetDropDown}
                      setAddToCartEnable={ setAddToCartEnable }
                                        // eslint-disable-next-line no-magic-numbers
                      numberOfPlaceholders={ [2, 4] }
                      mix={ { block: 'ProductActions', elem: 'Attributes' } }
                      isReady={ areDetailsLoaded }
                      getLink={ getLink }
                      parameters={ parameters }
                      updateConfigurableVariant={ updateConfigurableVariant }
                      configurable_options={ configurable_options }
                      getIsConfigurableAttributeAvailable={ getIsConfigurableAttributeAvailable }
                      isContentExpanded
                      variantsConfig={variants}
                    />
                )}
            </div>
        );
    }

    renderCustomizableOptions() {
        const {
            dataSource: { options },
            getSelectedCustomizableOptions,
            productOptionsData,
            device
        } = this.props;

        if (!device.isMobile) {
            return null;
        }

        return (
            <ProductCustomizableOptions
              options={ options || [] }
              getSelectedCustomizableOptions={ getSelectedCustomizableOptions }
              productOptionsData={ productOptionsData }
            />
        );
    }

    renderBundleItems() {
        const {
            product: { items, type_id, price_range },
            maxQuantity,
            getSelectedCustomizableOptions,
            productOptionsData,
            setBundlePrice
        } = this.props;

        if (type_id !== BUNDLE) {
            return null;
        }

        return (
            <section
              block="ProductActions"
              elem="Section"
              mods={ { type: 'bundle_items' } }
            >
                <ProductBundleItems
                  items={ items }
                  getSelectedCustomizableOptions={ getSelectedCustomizableOptions }
                  maxQuantity={ maxQuantity }
                  productOptionsData={ productOptionsData }
                  setBundlePrice={ setBundlePrice }
                  price_range={ price_range }
                  type_id={ type_id }
                />
            </section>
        );
    }

    renderShortDescription() {
        const {
            product: {
                short_description,
                id,
                meta_description = null
            }
        } = this.props;
        const { html } = short_description || {};
        const seoDescription = meta_description || html;
        const htmlWithItemProp = `<div itemProp="description" class="ProductActions-MetaDescription">${seoDescription}</div>`;
        const htmlShortDescription = html;

        if (!html && id) {
            return null;
        }

        return (
            <section
              block="ProductActions"
              elem="Section"
              mods={ { type: 'short' } }
              aria-label="Product short description"
            >
                <div block="ProductActions" elem="ShortDescription">
                    { html ? <Html content={ htmlShortDescription } /> : <p><TextPlaceholder length="long" /></p> }
                    { seoDescription && <Html content={ htmlWithItemProp } /> }
                </div>
            </section>
        );
    }

    renderGiftDetails() {
        const {
            product: {
                short_description,
                id,
                type_id,
                am_allow_open_amount,
                am_email_template,
                am_giftcard_code_image,
                am_giftcard_fee_enable,
                am_giftcard_fee_type,
                am_giftcard_fee_value,
                am_giftcard_lifetime,
                am_giftcard_prices,
                am_giftcard_type,
                am_open_amount_max,
                am_open_amount_min
            }
        } = this.props;

        if ((isEmpty(am_giftcard_type) || am_giftcard_type != 'VIRTUAL') && type_id != 'amgiftcard') {
            return null;
        }

        return (
            <div className="GiftCardDetails">
                { /* <div className="GiftAmountContainer"> */ }
                { this.renderAmountSelection(am_giftcard_prices) }
                { this.renderAmountInputField(am_giftcard_prices, am_allow_open_amount, am_open_amount_max, am_open_amount_min) }
                { /* </div> */ }
                { this.renderDesignSection(am_giftcard_code_image) }
                { this.renderRequiredFields() }
            </div>
        );
    }

    onSubmitAttempt = () => {
    };

    handlePriceClick = (giftPrice) => {
        const { updateGiftCardPrice, giftCardData = {} } = this.props;
        if (updateGiftCardPrice) {
            updateGiftCardPrice(giftPrice);
        }
    };

    handleImageClick = (giftImage) => {
        const { updateGiftCardDesign, giftCardData = {} } = this.props;
        if (updateGiftCardDesign) {
            updateGiftCardDesign(giftImage);
        }
    };

    updateCustomGiftPrice = (value) => {
        const { showErrorNotification, setCustomPrice } = this.props;

        if (isNaN(value)) {
            showErrorNotification(__('Amount can only be number'));
            return;
        }
        if (setCustomPrice) {
            setCustomPrice(value);
        }
    };

    setCustomAmount = () => {
        let {
            customGiftPrice = '0', showErrorNotification, product: {
                short_description,
                am_open_amount_max,
                am_open_amount_min
            }
        } = this.props;

        if (isNaN(customGiftPrice) || isEmpty(customGiftPrice)) {
            showErrorNotification(__('Amount can only be number'));
            return;
        }
        customGiftPrice = roundPrice(customGiftPrice);
        if (isEmpty(customGiftPrice) || customGiftPrice > am_open_amount_max || customGiftPrice < am_open_amount_min || customGiftPrice < 1) {
            showErrorNotification(__('Amount should be in valid range!'));
            return;
        }
        const { updateGiftCardPrice } = this.props;
        if (updateGiftCardPrice) {
            updateGiftCardPrice(customGiftPrice);
        }
    };

    handleYourNameChange = (value) => {
        const { updateGiftCardFormData, giftCardData = {} } = this.props;
        const {
            your_name = '', recipient_name = '', recipient_email = '', gift_message = ''
        } = giftCardData;

        if (updateGiftCardFormData) {
            updateGiftCardFormData(value, recipient_name, recipient_email, gift_message);
        }
    };

    handleRecipientNameChange = (value) => {
        const { updateGiftCardFormData, giftCardData = {} } = this.props;
        const {
            your_name = '', recipient_name = '', recipient_email = '', gift_message = ''
        } = giftCardData;

        if (updateGiftCardFormData) {
            updateGiftCardFormData(your_name, value, recipient_email, gift_message);
        }
    };

    handleRecipientEmailChange = (value) => {
        const { updateGiftCardFormData, giftCardData = {} } = this.props;
        const {
            your_name = '', recipient_name = '', recipient_email = '', gift_message = ''
        } = giftCardData;

        if (updateGiftCardFormData) {
            updateGiftCardFormData(your_name, recipient_name, value, gift_message);
        }
    };

    handleGiftMessageChange = (value) => {
        const { updateGiftCardFormData, giftCardData = {} } = this.props;
        const {
            your_name = '', recipient_name = '', recipient_email = '', gift_message = ''
        } = giftCardData;

        if (updateGiftCardFormData) {
            updateGiftCardFormData(your_name, recipient_name, recipient_email, value);
        }
    };

    renderAmountSelection(am_giftcard_prices) {
        if (isEmpty(am_giftcard_prices) || am_giftcard_prices.length < 1) {
            return null;
        }

        const { updateGiftCardPrice, giftCardData = {} } = this.props;
        const { giftPrice = '' } = giftCardData;
        const value = am_giftcard_prices[0];

        if (isEmpty(giftPrice)) {
            updateGiftCardPrice(value);
        }
        let selectedPrice = '';
        if (!isEmpty(giftPrice)) {
            const { price_id } = giftPrice;
            if (price_id) {
                selectedPrice = price_id;
            }
        }

        const priceItems = am_giftcard_prices.map((giftPrice) => {
            // here have to handle selected and unselected price field
            const { price_id, value: { value, currency } } = giftPrice;
            //  const formated Currency = roundPrice(value);
            const cr = formatCurrency(currency);
            const ariaLabel = `${cr} ${value}`;
            let stylePriceItem = 'GiftPriceItem';
            stylePriceItem = (selectedPrice == price_id) ? 'GiftPriceItemSelected' : 'GiftPriceItem';

            return (
                <div className={ stylePriceItem } key={ price_id } onClick={ () => this.handlePriceClick(giftPrice) }>
                    { ariaLabel }
                </div>
            );
        });

        return (
            <div className="GiftPriceUI">
                <h1 className="GiftPrices">
                    { __('Step 1: Select Amount') }
                </h1>
                <div className="GiftPriceItems">
                    { priceItems }

                </div>
            </div>
        );
    }

    renderAmountInputField(am_giftcard_prices, am_allow_open_amount, am_open_amount_max, am_open_amount_min) {
        const { customGiftPrice = '' } = this.props;

        if (!(am_allow_open_amount)) {
            return null;
        }

        let cr = '';

        if (am_giftcard_prices.length > 0) {
            const { value: { value, currency } } = am_giftcard_prices[0];
            cr = formatCurrency(currency);
        }

        const placeholderText = `${cr} ${am_open_amount_min} - ${cr} ${am_open_amount_max}`;

        return (
            <div className="GiftAmountInputFiled">

                <h1 className="GiftAmountInput">
                    { __('OR Enter An Amount') }
                </h1>
                <div className="giftInputApply">
                    <Field
                      id="gift_amount"
                      name="gift_amount"
                      placeholder={ placeholderText }
                      min={ am_open_amount_min }
                      max={ am_open_amount_max }
                      type="text"
                      value={ customGiftPrice }
                      mix={ { block: 'ProductActions', elem: 'giftAmount' } }
                      onChange={ (value) => this.updateCustomGiftPrice(value) }
                    />
                    <div className="SetAmountButton Button" onClick={ () => this.setCustomAmount() }>{ __('SET') }</div>

                </div>

            </div>
        );
    }

    renderDesignSection(am_giftcard_code_image) {
        if (isEmpty(am_giftcard_code_image) || am_giftcard_code_image.length < 1) {
            return null;
        }

        const { updateGiftCardDesign, giftCardData = {} } = this.props;
        const { giftImage = '' } = giftCardData;
        const value = am_giftcard_code_image[0];

        if (isEmpty(giftImage)) {
            updateGiftCardDesign(value);
        }

        let selectedImage = '';
        if (!isEmpty(giftImage)) {
            const { id } = giftImage;
            selectedImage = id;
        }

        const imageItems = am_giftcard_code_image.map((giftImage) => {
            // here have to handle selected and unselected price field

            const { id } = giftImage;
            let styleImageItem = 'GiftImageItem';
            styleImageItem = (selectedImage == id) ? 'GiftImageItemSelected' : 'GiftImageItem';

            return (
                <div className={ styleImageItem } key={ giftImage.id } onClick={ () => this.handleImageClick(giftImage) }>
                    <img src={ giftImage.src } loading="lazy" />
                </div>
            );
        });

        return (
            <>
                <h1 className="GiftImageItems">
                    { __('Step 2: Choose a Design') }
                </h1>
                <div className="GiftImageItems-wrap">
                    <div className="GiftImageItems">
                        { imageItems }
                    </div>
                </div>
            </>
        );
    }

    renderRequiredFields() {
        const { giftCardData = {} } = this.props;
        const {
            your_name = '', recipient_name = '', recipient_email = '', gift_message = ''
        } = giftCardData;
        const { showValidationError = 'noError' } = this.state;
        const csscls = `GiftCardForm-input ${showValidationError}`;

        return (
            <div>

                <h1 className="EnterGiftDetails">
                    { __('Step 3: Enter Details') }
                </h1>

                <Form
                    // ref="giftInputForm"
                  id="giftInputForm"
                  ref={ (ref) => {
                      this.form = ref;
                  } }
                  key="GiftCardForm-input"
                  mix={ { block: `GiftCardForm ${showValidationError}` } }
                  onSubmit={ this.onSubmitAttempt }
                >
                    <Field
                      type="text"
                      label={ __('Your Name') }
                      id="your_name"
                      name="your_name"
                      validation={ ['notEmpty'] }
                      value={ your_name }
                      onChange={ (value) => this.handleYourNameChange(value) }
                    />
                    <Field
                      type="text"
                      label={ __('Recipient Name') }
                      id="recipient_name"
                      name="recipient_name"
                      validation={ ['notEmpty'] }
                      value={ recipient_name }
                      onChange={ (value) => this.handleRecipientNameChange(value) }
                    />
                    <Field
                      type="text"
                      label={ __('Recipient Email') }
                      id="recipient_email"
                      name="email"
                      validation={ ['notEmpty', 'email'] }
                      value={ recipient_email }
                      onChange={ (value) => this.handleRecipientEmailChange(value) }
                    />
                    <Field
                      type="textarea"
                      label={ __('Message( Optional )') }
                      id="gift_message"
                      name="gift_message"
                      value={ gift_message }
                      onChange={ (value) => this.handleGiftMessageChange(value) }
                    />
                </Form>
            </div>
        );
    }

    renderNameAndBrand() {
        const {
            product:
            {
                name,
                attributes = {}
            },
            showOnlyIfLoaded
        } = this.props;

        if (!attributes) {
            return null;
        }

        const { brand = null } = attributes;
        if (!brand) {
            return null;
        }

        // Brand
        let brandValue = '';
        if (!isEmpty(brand)) {
            const brandCode = parseInt(brand?.attribute_value);
            brandValue = brand?.attribute_options[brandCode]?.label ?? '';
        }

        return (
            <section
              block="ProductActions"
              elem="Section"
              mods={ { type: 'brand' } }
            >
                { showOnlyIfLoaded(
                    brand,
                    (
                        <div itemProp="brand" itemType="http://schema.org/Brand" itemScope>
                            <meta itemProp="brand" content={ brandValue } />
                        </div>
                    )
                ) }
                <p block="ProductActions" elem="Title" itemProp="brand">
                    <TextPlaceholder content={ brandValue } length="medium" />
                </p>
            </section>
        );
    }

    renderQuantityInput() {
        const {
            quantity,
            maxQuantity,
            minQuantity,
            setQuantity,
            product: { type_id }
        } = this.props;

        // if (type_id === GROUPED) {
        //     return null;
        // }
        if (type_id === 'amgiftcard') {
            return (
                <Field
                  id="item_qty"
                  name="item_qty"
                  type="number"
                  min={ minQuantity }
                  max={ 1 }
                  value={ quantity }
                  mix={ { block: 'ProductActions', elem: 'Qty' } }
                  onChange={ setQuantity }
                />
            );
        }

        return (
            <>
                { /* <label>{__('Qty')}</label> */ }
                <Field
                  id="item_qty"
                  name="item_qty"
                  type="number"
                  min={ minQuantity }
                  max={ maxQuantity }
                  value={ quantity }
                  mix={ { block: 'ProductActions', elem: 'Qty' } }
                  onChange={ setQuantity }
                />
            </>
        );
    }

    triggerOnAddToCart = () => {
        this.setState({ showValidationError: '' });

        if (this.refs.giftInputForm) {
            this.refs.giftInputForm.submit();
        }
        if (this.refs.form) {
            this.refs.form.submit();
        }
    };

    renderAddToCart() {
        const {
            configurableVariantIndex,
            product = {},

            quantity,
            groupedProductQuantity,
            giftCardData,
            onProductValidationError,
            productOptionsData,
            optionsSelected,
            clearDropDownList,
            enableAddToCart,
            product: {
                variants,
                type_id,
                configurable_product_options_selection = {},
                items
            } = {}
        } = this.props;
        const { variant } = configurable_product_options_selection;
        const productOrVariant = variant ?? product;

        const handleProductValidation = (type_id && type_id === GIFT_CARD) ? this.onProductValidationError : onProductValidationError;

        const { stock_status } = productOrVariant;
        let bundle_stock_status = '';
        if (!isEmpty(items && items[0].options) && items.length > 0 && items[0].options.length > 0) {
            bundle_stock_status = items[0].options[0].product.stock_status;
        }

        return (
            <AddToCart
              product={ product }
              enableAddToCart={ enableAddToCart }
              clearDropDownList={clearDropDownList}
              optionsSelected={ optionsSelected }
              configurableVariantIndex={ configurableVariantIndex }
              mix={ { block: 'ProductActions', elem: 'AddToCart' } }
              quantity={ quantity }
              groupedProductQuantity={ groupedProductQuantity }
              giftCardData={ giftCardData }
              onProductValidationError={ handleProductValidation }
              productOptionsData={ productOptionsData }
              disabled={ stock_status === OUT_OF_STOCK || bundle_stock_status === OUT_OF_STOCK }
              notifyAdded={ false }
            />
        );
    }

    renderBrand() {
        const {
            product:
            {
                attributes = {}
            },
            showOnlyIfLoaded
        } = this.props;

        if (!attributes) {
            return null;
        }

        const { brand = null } = attributes;
        if (!brand) {
            return null;
        }

        // Brand
        let brandValue = '';
        if (!isEmpty(brand)) {
            const brandCode = parseInt(brand?.attribute_value);
            brandValue = brand?.attribute_options[brandCode]?.label ?? '';
        }

        return (
            <div
              block="ProductActions"
              elem="Attribute"
              mods={ { type: 'product_brand' } }
              aria-label="Brand"
            >
                { brand && (
                    <span block="ProductActions" elem="Brand" itemProp="brand" itemType="http://schema.org/Brand" itemScope>
                        <meta itemProp="name" content={ brandValue } />
                        { brandValue }
                    </span>
                ) }
            </div>
        );
    }

    renderName = () => {
        const { product: { name, attributes = {} }, productName } = this.props;
        const nameHeader1 = attributes?.name_header_1?.attribute_value ?? null;
        const nameHeader2 = attributes?.name_header_2?.attribute_value ?? null;
        const proName = productName || name;

        // If name_header_1 and name_header_2 are not empty, then we need to render them inside h1, else default to name
        if (!isEmpty(nameHeader1) && !isEmpty(nameHeader2)) {
            return (
                <>
                <meta itemProp="name" content={ name } />
                <h1 className="ProductName">
                    <span className="ProductName-Line1">{ nameHeader1 }</span>
                    <span className="ProductName-Line2">{ nameHeader2 }</span>
                </h1>
                </>
            );
        }

        return (
            <>
            <meta itemProp="name" content={ name } />
            <h1 className="ProductName">
                { proName }
            </h1>
            </>
        );
    };

    renderPrice() {
        const {
            product: {
                price, variants, tax_description, shipping_block, type_id
            }, configurableVariantIndex
        } = this.props;

        if (type_id === GROUPED) {
            return null;
        }

        // Product in props is updated before ConfigurableVariantIndex in props, when page is opened by clicking CartItem
        // As a result, we have new product, but old configurableVariantIndex, which may be out of range for variants
        let productOrVariantPrice = variants && variants[configurableVariantIndex] !== undefined
            ? variants[configurableVariantIndex].price
            : price;

        if (type_id === GIFT_CARD) {
            const { giftCardData = {} } = this.props;
            const { giftPrice } = giftCardData;
            if (!isEmpty(giftPrice)) {
                const { minimalPrice: { amount } } = productOrVariantPrice;
                let { minimalPrice } = productOrVariantPrice;
                if (giftPrice.price_id) {
                    minimalPrice = { amount: giftPrice.value };
                } else {
                    const amtData = { value: giftPrice, currency: amount.currency };
                    minimalPrice = { amount: amtData };
                }
                productOrVariantPrice = { ...productOrVariantPrice, minimalPrice };
            }
        }

        return (
            <ProductPrice
              price={ productOrVariantPrice }
              mix={ { block: 'ProductActions', elem: 'Price' } }
              tax_description={ tax_description }
              shipping_block={ shipping_block }
              variants={variants}
              configurableVariantIndex={configurableVariantIndex}
            />
        );
    }

    renderTierPrices() {
        const { productOrVariant } = this.props;

        return (
            <div block="ProductActions" elem="TierPrices">
                {/* <TierPrices product={ productOrVariant } /> */}
            </div>
        );
    }

    moveToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    renderGoToWishlist() {
        if (!isSignedIn()) {
            return null;
        }

        return (
            <Link
              to="/my-account/my-wishlist"
              mix={ { block: 'Button', mods: { isHollow: true } } }
              onClick={ () => this.moveToTop() }
            >
                { __('Go to Wishlist') }
            </Link>
        );
    }

    renderProductWishlistButton() {
        const {
            product,
            quantity,
            configurableVariantIndex,
            productOptionsData
        } = this.props;

        return (
            <ProductWishlistButton
              product={ product }
              productOptionsData={ productOptionsData }
              quantity={ quantity }
              configurableVariantIndex={ configurableVariantIndex }
              onProductValidationError={ this.onProductValidationError }
            />
        );
    }

    renderzipWidget() {
        let {
            productPrice = {},
            offerCount,
            product: {
                price, shipping_block, tax_description, type_id
            }
        } = this.props;

        if (type_id == undefined) {
            return null;
        }
        let emiAmount = 0;

        if (type_id === GIFT_CARD) {
            const { giftCardData = {} } = this.props;
            let { giftPrice } = giftCardData;
            let { minimum_price } = productPrice;

            if (!isEmpty(giftPrice)) {
                let { final_price } = minimum_price;
                let priceData = {};
                if (giftPrice.price_id) {
                    priceData = { ...giftPrice.value };
                } else {
                    priceData = { value: giftPrice, currency: final_price.currency };
                }
                productPrice = { ...productPrice, minimum_price: { ...productPrice.minimum_price, final_price: priceData } };
            }

            // const { currency } = productPrice.minimum_price.final_price;
            const content_price = productPrice.minimum_price.final_price
                ? productPrice.minimum_price.final_price.value : productPrice.minimum_price.regular_price.value;

            const { currency } = productPrice.minimum_price.final_price;
            // eslint-disable-next-line no-magic-numbers
            emiAmount = roundPrice(content_price);
        } else {
            const {
                product: {
                    price, variants
                }, configurableVariantIndex
            } = this.props;
            let productOrVariantPrice = variants && variants[configurableVariantIndex] !== undefined
                ? variants[configurableVariantIndex].price
                : price;

            const content_price = productPrice.minimum_price.final_price
                ? productPrice.minimum_price.final_price.value : productPrice.minimum_price.regular_price.value;

            const { currency } = productPrice.minimum_price.final_price;

            // eslint-disable-next-line no-magic-numbers
            emiAmount = roundPrice(content_price);
        }
        emiAmount = `${parseInt(emiAmount)}`;
        this.setState({ zipemi: emiAmount });

        // Remove <title> from #zipline
        const title = document.querySelector('#zipline title');
        if (title) {
            title.remove();
        }

        return (
            <div block="zipWidget" elem="Buttons">
                { (window.storeConfig.code === 'en_au') && (
                    <div className="zipWidget" id="zipline">
                        <div
                          id="zip-product-widget"
                          data-zm-widget="popup"
                          data-zm-region="au"
                          data-env="production"
                          data-zm-merchant="97dc351a-32ef-44e5-90f0-63664177ee83"
                          data-zm-price={ emiAmount }
                          data-zm-asset="productwidget"
                          data-zm-popup-asset="termsdialog"
                        />
                    </div>
                ) }
            </div>
        );
    }

    showPayJustNowPopup = () => {
        const { isEnabledPayJustNowPopup = false } = this.state;
        this.setState({ isEnabledPayJustNowPopup: !isEnabledPayJustNowPopup });
    };

    renderPayjustNowWidget = () => {
        let {
            productPrice = {},
            offerCount,
            product: {
                price, shipping_block, tax_description, type_id
            }
        } = this.props;

        if (type_id == undefined) {
            return null;
        }
        let pjnAmount = 0;
        let pafAmount = 0;

        if (type_id === GIFT_CARD) {
            const { giftCardData = {} } = this.props;
            let { giftPrice } = giftCardData;
            let { minimum_price } = productPrice;

            if (!isEmpty(giftPrice)) {
                let { final_price } = minimum_price;
                let priceData = {};
                if (giftPrice.price_id) {
                    priceData = { ...giftPrice.value };
                } else {
                    priceData = { value: giftPrice, currency: final_price.currency };
                }
                productPrice = { ...productPrice, minimum_price: { ...productPrice.minimum_price, final_price: priceData } };
            }

            // const { currency } = productPrice.minimum_price.final_price;
            const content_price = productPrice.minimum_price.final_price
                ? productPrice.minimum_price.final_price.value : productPrice.minimum_price.regular_price.value;

            const { currency } = productPrice.minimum_price.final_price;

            // Pay Just Now amount
            pjnAmount = `${content_price / 3}`;
            pjnAmount = formatPrice(roundPrice(pjnAmount), currency);
            // Payflex Amount
            pafAmount = `${content_price / 4}`;
            pafAmount = formatPrice(roundPrice(pafAmount), currency);
        } else {
            const {
                product: {
                    price, variants
                }, configurableVariantIndex
            } = this.props;
            let productOrVariantPrice = variants && variants[configurableVariantIndex] !== undefined
                ? variants[configurableVariantIndex].price
                : price;

            const content_price = productPrice.minimum_price.final_price
                ? productPrice.minimum_price.final_price.value : productPrice.minimum_price.regular_price.value;

            const { currency } = productPrice.minimum_price.final_price;

            // Pay Just Now amount
            pjnAmount = `${content_price / 3}`;
            pjnAmount = formatPrice(roundPrice(pjnAmount), currency);
            // Payflex Amount
            pafAmount = `${content_price / 4}`;
            pafAmount = formatPrice(roundPrice(pafAmount), currency);

            this.setState({ pjnAmount: `${content_price / 3}`, pafAmount: `${content_price / 4}` });
        }

        return (
            <>
                { ' ' }
                { (isRsaStore()) && (
                    <>
                    <div className="payflex">
                        <img alt="Get your goods upfront with Payflex and pay for them over 4 fortnightly interest free instalments." src={ Payflexlogo } />
                        <div className="payflex-right">
                            <strong>Get your goods upfront</strong>
                            { ' ' }
                            with Payflex and pay for them over
                            { ' ' }
                            <strong>4 fortnightly interest free</strong>
                            { ' ' }
                            instalments of
                            { ' ' }
                            {pafAmount}
                            . Standard shipping terms apply.
                            <br />
                        </div>
                    </div>
                    <div className="payjustnow">
                        <img alt="Get your goods upfront with PayJustNow and pay for them over 3 interest free instalments." src={ PayjustnowlogoLight } />
                        <div className="payjust-right">
                            <strong>{ __('Get your goods upfront') }</strong>
                            { __(' with ') }
                            <button onClick={ this.showPayJustNowPopup } className="payjustnowinfomodal">
                                PayJustNow
                            </button>
                            { ' and pay for them over ' }
                            <strong>{ '3 interest free ' }</strong>
                            { ` instalments of ${pjnAmount}. Standard shipping terms apply. ` }
                            <button onClick={ this.showPayJustNowPopup } id="payjustnowinfo" className="payjustnowinfomodal">
                                <FontAwesomeIcon icon={ faInfoCircle } />
                            </button>
                            { ' ' }
                            <br />
                        </div>
                    </div>
                    </>
                ) }
            </>
        );
    };

    renderPayJustNowPopup = () => {
        const { isEnabledPayJustNowPopup = false } = this.state;

        if (!isEnabledPayJustNowPopup || !isRsaStore()) {
            return null;
        }

        return (
            // eslint-disable-next-line react/style-prop-object
            <div id="payjustnow" className="modal">
                <div className="modal-content">
                    <span onClick={ this.showPayJustNowPopup } className="close">×</span>
                    <div className="pjn-popup-header">
                        <div className="pjn-logo">
                            <a href="https://payjustnow.com/register/overview" target="_blank" rel="nofollow">
                                <img alt="Get your goods upfront with PayJustNow and pay for them over 3 interest free instalments." src={ Payjustnowlogo } />
                            </a>
                        </div>
                        <div className="pjn-header-right">
                            <h2 className="pjn-heading-large">
                                { __('PayJustNow is a simple, easy-to-use payment system.') }
                            </h2>
                            <p className="pjn-paragraph">
                                <strong>{ __('Here’s how it works:') }</strong>
                            </p>
                            <p className="pjn-paragraph">
                                { __('PayJustNow allows you to pay for your purchase over 3 equal, zero interest instalments. You’ll pay one instalment at the time of purchase, the next at the beginning of the following month and the last one a month thereafter.') }
                                <br />
                                <span className="pjn-emphasis">{ __('#zerointerest') }</span>
                            </p>
                        </div>
                    </div>
                    <div className="pjn-popup-body">
                        <div className="pjn-popup-body-grid">
                            <div>
                                <img alt="BrowserIcon" src={ BrowserIcon } />
                                <p className="pjn-emphasis">
                                    { __('Step 1:') }
                                </p>
                                <p>
                                    { __('Browse your favourite online stores and proceed to check-out.') }
                                </p>
                            </div>
                            <div>
                                <img alt="PaymentmethodIcon" src={ PaymentmethodIcon } />
                                <p className="pjn-emphasis">
                                    { __('Step 2:') }
                                </p>
                                <p>
                                    { __('Choose PayJustNow as your payment method.') }
                                </p>
                            </div>
                            <div>
                                <img alt="CreateAcIcon" src={ CreateAcIcon } />
                                <p className="pjn-emphasis">
                                    { __('Step 3:') }
                                </p>
                                <p>
                                    { __('Create your account as easily as if your eyes were shut (though we’d recommend you keep them open).') }
                                </p>
                            </div>
                            <div>
                                <img alt="CompleteIcon" src={ CompleteIcon } />
                                <p className="pjn-emphasis">
                                    { __('Step 4:') }
                                </p>
                                <p>
                                    { __('Complete your purchase and whoop for joy!') }
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="pjn-popup-footer">
                        <div className="pjn-footer-box">
                            <h4 className="pjn-heading-small">{ __("Here's what you'll need:") }</h4>
                            <div className="bottom-text">
                                <div>
                                    <img alt="Tickicon" src={ TickIcon } />
                                    <p>
                                        { __('To be over 18 years old') }
                                    </p>
                                </div>
                                <div>
                                    <img alt="Tickicon" src={ TickIcon } />
                                    <p>
                                        { __('A valid RSA ID document') }
                                    </p>
                                </div>
                                <div>
                                    <img alt="Tickicon" src={ TickIcon } />
                                    <p>
                                        { __('A debit or credit card') }
                                    </p>
                                </div>
                                <div>
                                    <img alt="Tickicon" src={ TickIcon } />
                                    <p>
                                        { __('An email address') }
                                    </p>
                                </div>
                            </div>
                        </div>
                        <hr className="pjn-hr" />
                        <div className="button-learn-more">
                            <a href="https://payjustnow.com" target="_blank" rel="nofollow">{ __('Learn more') }</a>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    renderPayJustNowPaylatterButton = () => {
        if (!isRsaStore()) {
            return null;
        }

        const { product: { type_id = 'simple' } = {}, quantity } = this.props;

        const {
            configurableVariantIndex,
            product = {},
            product: {
                variants,
                items
            } = {}
        } = this.props;

        const productOrVariant = variants && variants[configurableVariantIndex] !== undefined
            ? variants[configurableVariantIndex]
            : product;

        const { stock_status = 'IN_STOCK', id } = productOrVariant;

        let bundle_stock_status = '';
        if (!isEmpty(items && items[0].options) && items.length > 0 && items[0].options.length > 0) {
            bundle_stock_status = items[0].options[0].product.stock_status;
        }

        let showNotifyMe = (stock_status === 'OUT_OF_STOCK' || bundle_stock_status === 'OUT_OF_STOCK');

        if (type_id === 'configurable' && configurableVariantIndex < 0) {
            showNotifyMe = false;
        }
        if (type_id === GROUPED) {
            showNotifyMe = false;
        }

        if (showNotifyMe) {
            return null;
        }

        return (
            <div id="bread-btn-cntnr">
                <button
                  onClick={ this.showPayJustNowPopup }
                  id="payjustnowinfo"
                  className="payjustnowinfomodal btn btn-default"
                >
                    { __('Pay Later') }
                </button>
            </div>
        );
    };

    renderSelectedBundlePrice = () => {
        const {
            selectedBundlePrice,
            productPrice = {},
            product: {
                type_id,
                price_range
            }
        } = this.props;

        const finalPrice = price_range?.maximum_price?.default_final_price?.value;
        const finalFprice = formatPrice(roundPrice(finalPrice));

        if (!type_id || type_id != BUNDLE) {
            return null;
        }

        const { currency } = productPrice.minimum_price.final_price;
        // eslint-disable-next-line no-magic-numbers
        let subTotalAmount = formatPrice(roundPrice(selectedBundlePrice), currency);
        if (subTotalAmount.includes('NaN')) {
            subTotalAmount = finalFprice;
        }

        return (
            <div className="SelectBundlePrice">
                { /* { __(`Sub Total ${subTotalAmount}`) } */ }
                { subTotalAmount }
            </div>
        );
    };

    renderAdditionalButtons() {
        return (
            <div block="ProductActions" elem="AdditionalButtons">
                { this.renderProductWishlistButton() }
                { this.renderGoToWishlist() }
            </div>
        );
    }

    renderReviews() {
        const { product: { review_summary: { rating_summary, review_count } = {} } } = this.props;
        if (!rating_summary) {
            return null;
        }
        const ONE_FIFTH_OF_A_HUNDRED = 20;
        const rating = parseFloat(rating_summary / ONE_FIFTH_OF_A_HUNDRED).toFixed(2);

        return (
            <div block="ProductActions" elem="Reviews">
                <ProductReviewRating summary={ rating_summary || 0 } />
                <p block="ProductActions" elem="ReviewLabel">
                    { rating }
                    <span>{ __('%s reviews', review_count) }</span>
                </p>
            </div>
        );
    }

    renderPdfPreview = (pdf, key) => {
        const {
            pdf_link,
            pdf_link_label,
            thumbnail,
            title,
            sku,
            filename,
            filesize,
            resource_type,
            language
        } = pdf;

        const linkTitle = `${title} (${language})`;

        return (
            <div className="product_downloads" key={ key }>
                <div className="pd_title">
                    <Link
                      to={ pdf_link }
                      target="_blank"
                      title={ linkTitle }
                    >
                        { linkTitle }
                    </Link>

                </div>
                <div className="pd_wrap">
                    <div className="pd_img">
                        <Link
                          to={ pdf_link }
                          target="_blank"
                          title={ linkTitle }
                        >
                            <Image src={ thumbnail } />
                        </Link>
                    </div>
                    <div className="pd_pdf">
                        <Link
                          to={ pdf_link }
                          target="_blank"
                          mix={ { block: 'Button', mods: { isHollow: true } } }
                          title={ linkTitle }
                        >
                            { pdf_link_label }
                        </Link>
                        <div className="pd_attributes">
                            <ul>
                                <li>
                                    <strong>{ __('Language') }</strong>
                                    :
                                    { ' ' }
                                    { language }
                                </li>
                                <li>
                                    <strong>{ __('Filename') }</strong>
                                    :
                                    { ' ' }
                                    <Link
                                      to={ pdf_link }
                                      target="_blank"
                                      title={ linkTitle }
                                    >
                                        { filename }
                                    </Link>
                                </li>
                                <li>
                                    <strong>{ __('Filesize') }</strong>
                                    :
                                    { ' ' }
                                    { filesize }
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    processInstallData = (data) => {
        const { pdfs, pdf_instructions } = data;

        return (
            <div className="install">
                { map(pdfs, this.renderPdfPreview) }
                <i>{ `*${pdf_instructions}` }</i>
            </div>
        );
    };

    addHttps = (link) => {
        const pattern = /^((http|https|ftp):\/\/)/;
        return pattern.test(link) ? link : `https://${link}`;
    };

    processVideoData = (video, key) => {
        const { video_link, is_youtube = false } = video;
        if (is_youtube === false) {
            return null;
        }
        let link = this.addHttps(video_link);
        link = link.split(/[?#]/)[0];
        link = `${link}?autoplay=0&modestbranding=1&rel=0`;

        return (
            <div key={ key } className="vid-container">
                <div className="iframe_wrap">
                    <iframe
                      width="100%"
                      height="100%"
                      frameBorder="0"
                      allowFullScreen="allowfullscreen"
                      src={ link }
                    />
                </div>
            </div>
        );
    };

    processQaData = (data) => {
        const { product } = this.props;
        this.renderQaScript(data);
        const qaData = <ProductQA data={ data } product={ product } />;

        return qaData;
    };

    renderQaScript(data) {
        const { product_qa = [] } = data;

        if (isEmpty(product_qa) || product_qa.length === 0) {
            return null;
        }

        // eslint-disable-next-line array-callback-return
        const mainEntity = product_qa.map((qa) => {
            const { question = '', answer = '' } = qa;
            let strippedQuestion = '';
            let strippedAnswer = '';
            if (question != null) {
                strippedQuestion = question.replace(/(<([^>]+)>)/gi, '');
                strippedAnswer = answer.replace(/(<([^>]+)>)/gi, '');
            }

            return ({
                '@type': 'Question',
                name: strippedQuestion,
                acceptedAnswer: {
                    '@type': 'Answer',
                    text: strippedAnswer
                }
            });
        });

        if (mainEntity.length === 0) {
            return null;
        }

        const scriptObj = {
            '@context': 'https://schema.org',
            '@type': 'FAQPage',
            mainEntity
        };
        const structuredData = JSON.stringify(scriptObj);

        const script = document.getElementById(PRODUCT_Q_SCRIPT_ID);
        if (script) {
            script.parentNode.removeChild(script);
        }
        const questionScript = document.createElement('script');
        questionScript.setAttribute('id', PRODUCT_Q_SCRIPT_ID);
        questionScript.setAttribute('type', 'application/ld+json');
        questionScript.async = true;
        questionScript.textContent = structuredData;
        document.head.appendChild(questionScript);
    }

    processTabsData = (tabsData) => {
        const { product = {} } = this.props;
        const tabsLabel = []; const
            tabsContent = [];

        each(tabsData, ({ type, data }) => {
            const { label } = data;
            const { content } = data;
            switch (type) {
            case 'info':
                if (content) {
                    let stripped = content.replace(/target="_blank"/g, '');
                    const { categories = [] } = product;
                    if (categories.length > 0 && categories[0].url_path === 'roof-racks') {
                        stripped = `<div>${stripped}<div className='lear_more'><a href='/slimline2' target='_blank' className='btn btn-primary'>${__('Learn more')}</a></div></div>`;
                    }
                    tabsLabel.push(label);
                    tabsContent.push(<Html content={ stripped } />);
                }

                break;
            case 'specs':
                const strippeds = content.replace(/target="_blank"/g, '');
                tabsLabel.push(label);
                tabsContent.push(<Html content={ strippeds } />);
                break;
            case 'install':
                const installData = this.processInstallData(data);
                tabsLabel.push(label);
                tabsContent.push(installData);
                break;
            case 'videos':
                const { video = [] } = data;
                const videoData = compact(map(video, this.processVideoData));
                tabsLabel.push(label);
                tabsContent.push(videoData);
                break;
            case 'qa':
                tabsLabel.push(label);
                const qaData = this.processQaData(data);
                tabsContent.push(qaData);
                break;
            case 'reviews':
                tabsLabel.push(label);
                const reviewData = <ProductReviews product={ product } />;
                tabsContent.push(reviewData);
                break;
            default:
                tabsLabel.push(label);
            }
        });

        return {
            label: tabsLabel,
            content: tabsContent
        };
    };

    renderTabs = () => {
        const {
            product: { reviews = {} }, product: { additionalInformation = {} }, areReviewsEnabled, videoTab, selectedInxData
        } = this.props;

        if (isEmpty(additionalInformation)) {
            return;
        }

        let tabsData = [
            { type: 'info', data: get(additionalInformation, 'info[0]', {}) },
            { type: 'specs', data: get(additionalInformation, 'specs[0]', {}) },
            { type: 'install', data: get(additionalInformation, 'install[0]', {}) },
            { type: 'videos', data: get(additionalInformation, 'videos[0]', {}) },
            { type: 'reviews', data: { label: __('Reviews'), reviews } }
            // { type: 'qa', data: get(additionalInformation, 'questions_answers[0]', {}) }
        ];

        if (!areReviewsEnabled) {
            tabsData.splice(4, 1);
        }

        // tabsData = filter(tabsData, td => !isEmpty(td.data))
        // tabsData = filter(tabsData, (td) => {
        //     const {
        //         content, reviews, label, video, pdfs
        //     } = td.data;
        //     let okay = false;
        //     if (!isEmpty(content) || !isEmpty(reviews) || label == 'Q&A' || label == 'Reviews') {
        //         okay = true;
        //     }
        //     if (label == 'Videos' && video.length > 0) {
        //         okay = true;
        //     }
        //     if (label == 'Install' && pdfs && pdfs.length > 0) {
        //         okay = true;
        //     }

        //     return okay;
        // });

        tabsData = this.processTabsData(tabsData);
        return (
            <ProductAccordions
              wrapperClass="ProductPageTabs"
              data={ tabsData }
              videoTab={ videoTab }
              selectedInxData={ selectedInxData }
              additionalInformation={ additionalInformation }
            />
        );
    };

    renderFaqSections = () => {
        const {
            product: { additionalInformation = {} }, selectedInxData
        } = this.props;

        if (isEmpty(additionalInformation)) {
            return;
        }

        let tabsData = [
            { type: 'qa', data: get(additionalInformation, 'questions_answers[0]', {}) }
        ];

        tabsData = this.processTabsData(tabsData);
        const { label, content } = tabsData;
        return (
            <div className="faqSectionWrap">
                <h2>
                    { __('Frequently Asked Questions') }
                </h2>
                { map(content, (data, key) => (
                    (data && (
                    <div key={ key } className="faqSection">
                        { data }
                    </div>
                    ))
                )) }
            </div>
        );
    };

    renderContactButtons = () => {
        // Contact numbers
        let contactNumbers = [];
        if (isEurStore()) {
            contactNumbers = [
                { display: '(DE) +49 (0) 511 47 40 46-400', href: '+4951147404640' }, // Germany
                { display: '(AT) +43 (0) 800 65 66 51', href: '+43800656651' }, // Austria
                { display: '(CH) +41 (0) 800 00 03 25', href: '+41800000325' } // Switzerland
            ];
        }
        if (isUsaStore()) {
            contactNumbers = [
                { display: '1 (818) 253-1322', href: '+18182531322' } // USA
            ];
        }
        if (isRsaStore() || isIntStore()) {
            contactNumbers = [
                { display: '+27 11 466 0155', href: '+27114660155' } // RSA
            ];
        }
        if (isAusStore()) {
            contactNumbers = [
                { display: '1300 84 90 09', href: '1300849009' } // Australia
            ];
        }
        if (isGbrStore()) {
            contactNumbers = [
                { display: '+44 (0) 800 0318087', href: '+448000318087' } // UK
            ];
        }

        return (
            <>
            { contactNumbers.map((number, index) => (
                <a key={index} href={`tel:${number.href}`}>
                    { __('Call') }
                    { ' ' }
                    { number.display }
                </a>
            )) }
            </>
        );
    };

    renderGotMoreQstSections = () => {
        const helpCenterText = __('visit_help_center');

        let helpCenterLink = 'https://support.frontrunneroutfitters.com/hc/en-za';
        const storeCode = window.storeConfig.code;
        if (storeCode === 'de_de') {
            helpCenterLink = 'https://support.frontrunneroutfitters.com/hc/de';
        }

        return (
            <div className="gotMoreQstSections">
                <h2>
                    { __('Got More Questions?') }
                </h2>
                <p>
                    { helpCenterText.split('<link>').map((part, index) => (
                        index === 0 ? part : (
                            <>
                                <Link key={index} to={helpCenterLink} target="_blank">
                                    Help Center
                                </Link>
                                { part.split('</link>')[1] }
                            </>
                        )
                    )) }
                </p>
                <div className="btnWrap">
                    {typeof window.zE !== 'undefined' && (
                        <button
                          onClick={() => window.zE('messenger', 'open')}
                          className="LiveChatButton"
                        >
                            {__('Live chat')}
                        </button>
                    )}
                    {this.renderContactButtons()}
                </div>
            </div>
        );
    };

    renderCategoryIdsString = () => {
        const { product: { categories = [] } } = this.props;
        const categoryIds = map(categories, 'id');
        // format string like this {{category_id:1}},{{category_id:2}}
        const categoryIdsString = categoryIds.join(',').replace(/(\d+)/g, '{{category_id:$1}}');

        return (
            <div id="categoryIds_hidden">
                { categoryIdsString }
            </div>
        );
    };

    renderShareOptions = () => {
        const { product: { url: product_url }, frontend_store_url } = this.props;
        if (!product_url) {
            return;
        }
        // const { frontend_store_url } = window.storeConfig;
        const product_urls = product_url.replace(baseName, '');
        const url = `${frontend_store_url}${product_urls}`;
        const size = 40;
        const round = false;
        // eslint-disable-next-line @scandipwa/scandipwa-guidelines/no-jsx-variables
        const emailIcon = <EmailIcon size={ size } round={ round } />;
        return (
            <div className="EmailToFriend">
                <span>
                    { __('Share to') }
                    { (':') }
                </span>
                <div className="EmailToFriend-links">
                    <FacebookShareButton url={ url }>
                        <FacebookIcon size={ size } round={ round } />
                        <span>{ __('Facebook') }</span>
                    </FacebookShareButton>
                    <TwitterShareButton url={ url }>
                        <TwitterIcon size={ size } round={ round } />
                        <span>{ __('Twitter') }</span>
                    </TwitterShareButton>
                    <Pinterest url={ url }>
                        <span>{ __('Pinterest') }</span>
                    </Pinterest>
                    <WhatsappShareButton url={ url }>
                        <WhatsappIcon size={ size } round={ round } />
                        <span>{ __('Whatsapp') }</span>
                    </WhatsappShareButton>
                    <TelegramShareButton url={ url }>
                        <TelegramIcon size={ size } round={ round } />
                        <span>{ __('Telegram') }</span>
                    </TelegramShareButton>
                    <LinkedinShareButton url={ url }>
                        <LinkedinIcon size={ size } round={ round } />
                        <span>{ __('Linkedin') }</span>
                    </LinkedinShareButton>
                    <EmailFriend icon={ emailIcon } { ...this.props } />
                </div>
            </div>
        );
    };

    renderWarrantyRow = () => {
        const { product: { additionalInformation = {} } } = this.props;
        if (isEmpty(additionalInformation)) {
            return;
        }

        const warrantyBlock = get(additionalInformation, 'warranty_block', []);
        return (
            <>
                <div className="service-warranty-cont">
                    {warrantyBlock && this.renderWarrantyBlock(warrantyBlock)}
                </div>
                <div id="vidTab" />
            </>
        );
    };

    renderWarrantyBlock = (warrantyBlock) => map(warrantyBlock, ({ css_class, text_h3, text_h4 }, key) => (
        <div key={key} className="service-warranty-wrap">
            <div className={ css_class } key={ key }>
                <h4 className="h4">{ text_h4 }</h4>
                <h3 className="h3">{ text_h3 }</h3>
            </div>
            <div className="divider" />
        </div>
    ));

    renderGroupedItems() {
        const {
            product,
            product: { type_id },
            groupedProductQuantity,
            setGroupedProductQuantity,
            clearGroupedProductQuantity
        } = this.props;

        if (type_id !== GROUPED) {
            return null;
        }

        return (
            <div
              block="ProductActions"
              elem="GroupedItems"
              ref={ this.groupedProductsRef }
            >
                <GroupedProductList
                  product={ product }
                  clearGroupedProductQuantity={ clearGroupedProductQuantity }
                  groupedProductQuantity={ groupedProductQuantity }
                  setGroupedProductQuantity={ setGroupedProductQuantity }
                />
            </div>
        );
    }

    renderPriceWithSchema() {
        let {
            productPrice,
            offerCount,
            product: {
                shipping_block, tax_description, type_id, price_range
            }
        } = this.props;

        let productOrVariantPrice = '';

        if (type_id === GIFT_CARD) {
            const { giftCardData = {} } = this.props;
            let { giftPrice } = giftCardData;
            let { minimum_price } = productPrice;

            if (!isEmpty(giftPrice)) {
                let { final_price } = minimum_price;
                let priceData = {};
                if (giftPrice.price_id) {
                    priceData = { ...giftPrice.value };
                } else {
                    priceData = { value: giftPrice, currency: final_price.currency };
                }
                productPrice = { ...productPrice, minimum_price: { ...productPrice.minimum_price, final_price: priceData } };
            }
        }

        const {
            product,
            product: { variants },
            configurableVariantIndex
        } = this.props;

        const productOrVariant = variants && variants[configurableVariantIndex] !== undefined
            ? variants[configurableVariantIndex]
            : product;

        const { stock_status } = productOrVariant;

        if (stock_status === 'OUT_OF_STOCK') {
            shipping_block = {};
        }

        return (
            <div
              block="ProductActions"
              elem="PriceWrapper"
            >
                { this.renderConfigurablePriceBadge() }
                { this.renderSchema() }
                <ProductPrice
                  isSchemaRequired
                  variantsCount={ offerCount }
                  price={ productPrice }
                  mix={ { block: 'ProductActions', elem: 'Price' } }
                  tax_description={ tax_description }
                  shipping_block={ shipping_block }
                  price_range={ price_range }
                  type_id={ type_id }
                  variants={variants}
                  configurableVariantIndex={configurableVariantIndex}
                />
            </div>
        );
    }

    renderSchema() {
        const {
            productName,
            stockMeta,
            metaLink,
            product: {
                url
            }
        } = this.props;

        return (
            <>
                { this.renderOfferCount() }
                <meta itemProp="availability" content={ stockMeta } />
                <a
                  block="ProductActions"
                  elem="Schema-Url"
                  itemProp="url"
                  href={ `${document.location.origin}${url}` }
                >
                    { productName }
                </a>
            </>
        );
    }

    renderOfferCount() {
        const { offerCount = 1 } = this.props;

        if (offerCount > 1) {
            return (
                <meta
                  itemProp="offerCount"
                  content={ offerCount }
                />
            );
        }

        return null;
    }

    renderPriceWithGlobalSchema() {
        const {
            offerType,
            product: {
                type_id
            }
        } = this.props;

        if (type_id === GROUPED) {
            return null;
        }

        return (
            <div
              block="ProductActions"
              elem="Schema"
              itemType={ offerType }
              itemProp="offers"
              itemScope
            >
                { this.renderPriceWithSchema() }
            </div>
        );
    }

    renderConfigurablePriceBadge() {
        const {
            configurableVariantIndex,
            product: { type_id }
        } = this.props;

        if (
            type_id !== CONFIGURABLE
            || configurableVariantIndex > -1
        ) {
            return null;
        }

        return (
            <p
              mix={ {
                  block: 'ProductActions',
                  elem: 'ConfigurablePriceBadge'
              } }
            >
                { __('Price From:') }
            </p>
        );
    }

    renderAdditionalSections() {
        const {
            areDetailsLoaded
        } = this.props;

        // Only show items when fully loaded
        // TODO: Show skeleton loader here
        if (!areDetailsLoaded) {
            return null;
        }

        return (
            <div className="productLinks_wrp">
                <ProductLinks
                  linkType={ RELATED }
                  title={ __('Related Products') }
                  areDetailsLoaded={ areDetailsLoaded }
                  className="abc"
                />
            </div>
        );
    }

    renderProductaAlsoLikeSections() {
        const {
            areDetailsLoaded
        } = this.props;

        if (!areDetailsLoaded) {
            return null;
        }

        return (
            <div className="product_also_like">
                <LazyLoad>
                    <ProductLinks
                      linkType={ UPSELL }
                      title={ __('You might also like') }
                      areDetailsLoaded={ areDetailsLoaded }
                    />
                </LazyLoad>
            </div>
        );
    }

    renderYotpoUGC() {
        if (!isUsaStore()) {
            return null;
        }

        const {
            product: {
                sku
            },
            productPrice
        } = this.props;

        let galleryId = null;
        if (sku === 'SBOX031' || sku === 'SBOX058' || sku === 'SBOX059') {
            galleryId = '65575e6ffa5d2e00282c8d2d';
        }
        if (sku === 'CHAI007') {
            galleryId = '65575e0123631d46719b6c24';
        }
        if (sku === 'STRA034') {
            galleryId = '6557659f1356ba002180078c';
        }
        if (sku === 'RRAC159') {
            galleryId = '655765e7ca516f269b05d1b7';
        }
        if (sku === 'KRTF054T') {
            galleryId = '6557660e63c7262b547ac46c';
        }
        if (sku === 'KSTT003T') {
            galleryId = '65576673ca516f23b2061687';
        }
        if (sku === 'KRLD028T') {
            galleryId = '6557668fb7de0c001a6630dd';
        }
        if (sku === 'KRLD037T') {
            galleryId = '655766c055fee92bf2dccc4d';
        }
        if (sku === 'KRSO004T') {
            galleryId = '655766e563c7262bf27ad669';
        }

        if (isEmpty(galleryId)) {
            return null;
        }

        return (
            <div className="yotpo yotpo-pictures-widget" data-gallery-id={ galleryId } />
        );
    }

    renderYoptoReviews() {
        const {
            product: {
                sku,
                name,
                url,
                small_image,
                attributes = {}
            },
            productPrice
        } = this.props;

        if (!attributes) {
            return null;
        }

        let price = productPrice?.maximum_price?.final_price?.value;
        let currency = productPrice?.maximum_price?.final_price?.currency;
        if (!price || price === undefined) {
            price = productPrice?.minimum_price?.final_price?.value;
            currency = productPrice?.minimum_price?.final_price?.currency;
        }

        // Yotpo Reviews Count
        let yotpoProductReviewsCount = 0;
        const { yotpo_product_reviews_count = 0 } = attributes;
        if (!yotpo_product_reviews_count) {
            return null;
        }

        if (!isEmpty(yotpo_product_reviews_count)) {
            yotpoProductReviewsCount = parseInt(yotpo_product_reviews_count?.attribute_value ?? 0);
        }
        if (parseInt(yotpoProductReviewsCount) === 0) {
            return null;
        }

        return (
            <div
              className="yotpo yotpo-main-widget"
              data-product-id={sku}
              data-price={price}
              data-currency={currency}
              data-name={name}
              data-url={url}
              data-image-url={small_image?.url ?? ''}
            />
        );
    }

    reviewHighlightsWidget() {
        const {
            product: {
                sku,
                attributes = {}
            }
        } = this.props;

        if (!attributes) {
            return null;
        }

        // Yotpo Reviews Count
        let yotpoProductReviewsCount = 0;
        const { yotpo_product_reviews_count = 0 } = attributes;
        if (!yotpo_product_reviews_count) {
            return null;
        }

        if (!isEmpty(yotpo_product_reviews_count)) {
            yotpoProductReviewsCount = parseInt(yotpo_product_reviews_count?.attribute_value ?? 0);
        }
        if (yotpoProductReviewsCount === 0) {
            return null;
        }

        return (
            <div className="yotpo yotpo-shoppers-say" data-product-id={sku} />
        );
    }

    renderYoptoRatingWidget() {
        const {
            product: {
                sku,
                attributes = {}
            }
        } = this.props;

        if (!attributes) {
            return null;
        }

        // Yotpo Reviews Count
        let yotpoProductReviewsCount = 0;
        const { yotpo_product_reviews_count = 0 } = attributes;
        if (!yotpo_product_reviews_count) {
            return null;
        }

        if (!isEmpty(yotpo_product_reviews_count)) {
            yotpoProductReviewsCount = parseInt(yotpo_product_reviews_count?.attribute_value ?? 0);
        }
        if (yotpoProductReviewsCount === 0) {
            return null;
        }

        const storeClass = `yotpo_${window.storeConfig.code}`;

        return (
            <div
              className={`yotpo bottomLine ${storeClass}`}
              data-yotpo-product-id={sku}
            />

        );
    }

    renderAddToCartWrapper = () => {
        const {
            product,
            product: { variants, items, configurable_product_options_selection = {} },
            configurableVariantIndex
        } = this.props;
        const { variant } = configurable_product_options_selection;

        // const {
        //     product: { items, type_id, price_range },
        //     maxQuantity,
        //     getSelectedCustomizableOptions,
        //     productOptionsData,
        //     setBundlePrice
        // } = this.props;
        let bundle_stock_status = '';
        if (!isEmpty(items && items[0].options) && items.length > 0 && items[0].options.length > 0) {
            bundle_stock_status = items[0].options[0].product.stock_status;
        }
        // const productOrVariant = variants && variants[configurableVariantIndex] !== undefined
        //     ? variants[configurableVariantIndex]
        //     : product;
        const productOrVariant = variant ?? product;

        const { stock_status } = productOrVariant;

        const classStyle = (stock_status === 'OUT_OF_STOCK' || bundle_stock_status === 'OUT_OF_STOCK') ? 'cart-wishlist-wrap no_stock' : 'cart-wishlist-wrap';

        const isOutOfStock = (stock_status === 'OUT_OF_STOCK' || bundle_stock_status === 'OUT_OF_STOCK');

        return (
            <>
                <div
                  block="ProductActions"
                  elem="AddToCartWrapper"
                  mods={ { isPrerendered: isSSR() || isCrawler() } }
                >
                    <div className={ classStyle }>
                        { (!isOutOfStock) && (
                            <div className="actions">
                                { this.renderQuantityInput() }
                                <div className="actions-addToCart">
                                    { this.renderAddToCart() }
                                    <div className="actions-additional">
                                        { this.renderAdditionalButtons() }
                                    </div>
                                </div>
                                { /* { this.renderPayJustNowPaylatterButton() } */ }
                            </div>
                        ) }

                        { (isOutOfStock) && (
                            <>
                                <div className="actions no_stock">
                                    <div className="out_of_stock">
                                        { __('Out of stock') }
                                    </div>
                                    <div className="actions-additional">
                                        { this.renderAdditionalButtons() }
                                    </div>
                                </div>
                                <div className="ProductNotifyEmail">
                                    <div block="ProductNotifyEmail" elem="Label">
                                        { __('Email me when this is available!') }
                                    </div>
                                    <div block="ProductNotifyEmail" elem="Capture">
                                        { this.renderQuantityInput() }
                                        { this.renderAddToCart() }
                                    </div>
                                    <div block="ProductNotifyEmail" elem="Message">
                                        { __('Missed it again? Resubmit a request to be notified.') }
                                    </div>
                                </div>
                            </>
                        ) }
                    </div>
                    { this.renderAdditionalButtons() }
                </div>
                {/* { (!isOutOfStock) && (
                    <>
                        { this.renderzipWidget() }
                        { this.renderPayjustNowWidget() }
                    </>
                ) } */}
            </>
        );
    };

    renderBuyNowPayLaterOptions() {
        // Check if B2B customer (Use local storage check)
        const isB2B = JSON.parse(sessionStorage.getItem('is_b2b')) ?? false;

        if (isB2B) {
            return null;
        }

        const { product = {} } = this.props;
        const {
            sku, price_range: {
                minimum_price: { default_final_price = {} } = {}
            } = {}
        } = product;
        const finalPrice = default_final_price?.value;
        if (sku === 'VOUC002') {
            return null;
        }

        if (window.BreadPayments !== undefined) {
            if (isUsaStore()) {
                // Bread Payments
                if (finalPrice < 50 || finalPrice > 10000) {
                    return null;
                }

                return (
                    <div id="buynowpaylateroptions">
                        { this.renderBreadPayFinance() }
                    </div>
                );
            }
        }

        if (isAusStore()) {
            // Zip Money
            return (
                <div id="buynowpaylateroptions">
                    { this.renderzipWidget() }
                </div>
            );
        }

        if (isRsaStore()) {
            // Pay Just Now
            return (
                <div id="buynowpaylateroptions">
                    { this.renderPayjustNowWidget() }
                </div>
            );
        }

        return null;
    }

    renderPayLaterStripeOptions() {
        const { stripe_test_key, stripe_live_Key } = this.props;
        // const stripe = stripe(stripe_test_key);
        const stripePromise = loadStripe(stripe_live_Key);

        return (
            <div id="payment-method-messaging-element" />
        );
    }

    getItemState({ expanded }) {
        return (
          <div className="toggle-nav-sec">
            { expanded ? <svg viewBox="0 0 24 24" focusable="false" className="chakra-icon css-uv4vk6"><g fill="currentColor"><rect height="4" width="20" x="2" y="10" /></g></svg> : <svg viewBox="0 0 24 24" focusable="false" className="chakra-icon css-uv4vk6"><path fill="currentColor" d="M0,12a1.5,1.5,0,0,0,1.5,1.5h8.75a.25.25,0,0,1,.25.25V22.5a1.5,1.5,0,0,0,3,0V13.75a.25.25,0,0,1,.25-.25H22.5a1.5,1.5,0,0,0,0-3H13.75a.25.25,0,0,1-.25-.25V1.5a1.5,1.5,0,0,0-3,0v8.75a.25.25,0,0,1-.25.25H1.5A1.5,1.5,0,0,0,0,12Z" /></svg> }
          </div>
        );
    }

    buyNowPayLayerText() {
        const {
            breadgetAsLowObj: {
                breadpayGetaslowas = {}
            },
            product: {
                price_range: {
                    minimum_price: { default_final_price = {} } = {}
                } = {}
            },
            showOnlyIfLoaded
        } = this.props;

        const finalPrice = default_final_price?.value; // Get the final product price

        // Define the interest rate
        const annualRate = 9.58; // Annual interest rate
        // Determine the number of months based on price ranges
        let months = 0;
        if (finalPrice < 25) {
            months = 1;
        } else if (finalPrice < 120) {
            months = 6; // 6-month EMI for prices below 120
        } else if (finalPrice >= 120 && finalPrice < 1000) {
            months = 12; // 12-month EMI for prices between 120 and 1000
        } else if (finalPrice >= 1000) {
            months = 24; // 24-month EMI for prices 1000 and above
        }

        let monthlyPayment = finalPrice;
        if (months > 1) {
            // Calculate monthly interest rate
            const monthlyRate = annualRate / 100 / 12;

            // Calculate monthly payment using the EMI formula
            const monthlyPaymentEmi = (finalPrice * monthlyRate * Math.pow(1 + monthlyRate, months))
                                / (Math.pow(1 + monthlyRate, months) - 1);

            monthlyPayment = Math.ceil(monthlyPaymentEmi);
        }

        const { pjnAmount, pafAmount, zipemi } = this.state;

        const {
            asLowAs = {}
        } = breadpayGetaslowas || {}; // Safeguard against breadpayGetaslowas being null or undefined

        let renderLowPaymentAmount = 0;

        if (asLowAs?.paymentAmount) {
            const { paymentAmount = {} } = asLowAs;
            renderLowPaymentAmount = parseFloat(paymentAmount?.Value / 100);
            renderLowPaymentAmount = renderLowPaymentAmount.toFixed(2);
        }

        const { storeId, currencyCode } = window?.storeConfig;

        let currency = '';
        let country = '';
        let minimumEmi = 0;

        minimumEmi = Math.min(pjnAmount, pafAmount);
        let minimumEmiAu = 10;
        if (zipemi > 500) {
            minimumEmiAu = Math.round((zipemi / 6) * 100) / 100;
        }

        let mFinalPrice = finalPrice ?? 0;
        if (finalPrice > 30 && finalPrice < 2000) {
            mFinalPrice = finalPrice / 3;
        }

        const formattedMinimumEmi = formatPrice(roundPrice(minimumEmi), currencyCode);
        const formattedMinimumEmiPrice = formatPrice(roundPrice(renderLowPaymentAmount), currencyCode);
        const formattedMinimumEmiPriceAu = formatPrice(roundPrice(minimumEmiAu), currencyCode);
        const formattedMinimumEmiPriceEur = formatPrice(roundPrice(monthlyPayment), currencyCode);
        const formattedMinimumEmiPriceGbp = formatPrice(roundPrice(mFinalPrice), currencyCode);

        switch (storeId) {
        case 5:
        case 16:
            currency = 'USD';
            country = 'US';
            minimumEmi = formattedMinimumEmiPrice;
            break;
        case 8:
        case 9:
        case 12:
        case 14:
        case 15:
            currency = 'EUR';
            country = 'DE';
            minimumEmi = formattedMinimumEmiPriceEur;
            break;
        case 17:
            currency = 'GBP';
            country = 'GB';
            minimumEmi = formattedMinimumEmiPriceGbp;
            break;
        case 4:
            currency = 'ZAR';
            country = 'ZA';
            minimumEmi = formattedMinimumEmi;
            break;
        case 7:
            currency = 'AUD';
            country = 'AU';
            minimumEmi = formattedMinimumEmiPriceAu;
            break;
        default:
            currency = 'USD';
            country = 'US';
            minimumEmi = formattedMinimumEmiPrice;
            break;
        }

        // Determine the payment frequency based on zipemi
        const paymentFrequency = zipemi < 500 ? __('per week') : __('per month');

        // Conditional rendering based on finalPrice range and isUsaStore
        if ((finalPrice < 50 || finalPrice > 10000) && isUsaStore()) {
            return (
                showOnlyIfLoaded && (
                    <div className="buyNowText">
                        <p>
                        { __('Buy now pay later') }
                        </p>
                        <p>
                        { __('View all available options') }
                        </p>
                    </div>
                )
            );
        }

        return (
            showOnlyIfLoaded && (
                <div className="buyNowText">
                    <p>
                        { __('Buy now pay later') }
                    </p>
                    <p>
                        { __('From') }
                        { ' ' }
                        { minimumEmi }
                        { ' ' }
                        { paymentFrequency }
                    </p>
                </div>
            )
        );
    }

    _renderPayLaterData() {
        const { storeId } = window?.storeConfig;
        switch (storeId) {
        case 5:
        case 16:
            // Bread Payments for specific stores
            return (
                <>
                    {this.renderBuyNowPayLaterOptions()}
                    {this.renderPayLaterStripeOptions()}
                </>
            );
        case 8:
        case 9:
        case 12:
        case 14:
        case 15:
        case 17:
            return (
                <>
                    {this.renderPayLaterStripeOptions()}
                </>
            );
        case 4:
            return (
                <>
                    { this.renderPayjustNowWidget() }
                </>
            );
        case 7:
            return (
                <>
                    {this.renderzipWidget()}
                    {this.renderPayLaterStripeOptions()}
                </>
            );
        default:
            // Default case for other stores
            return (
                null
            );
        }
    }

    renderPaymentDropdown() {
        const { storeId } = window?.storeConfig;
        if (storeId == '11') {
            return null;
        }

        return (
            <div className="paymentDropdown">
                <Accordion
                  allowZeroExpanded
                >
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                        { this.buyNowPayLayerText() }
                        <AccordionItemState>
                            { this.getItemState }
                        </AccordionItemState>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    { this._renderPayLaterData() }
                  </AccordionItemPanel>
                </AccordionItem>
                </Accordion>
            </div>
        );
    }

    openBreadOverlay = () => {
        const breadOverlay = document.querySelector('[id^="zoid-checkout-component"]');
        if (breadOverlay) {
            breadOverlay.style.display = 'block';
        }
    };

    renderBreadPayFinance = () => {
        const {
            breadgetAsLowObj: {
                breadpayGetaslowas = {}
            }
        } = this.props;

        const {
            asLowAs = {}
        } = breadpayGetaslowas || {}; // Safeguard against breadpayGetaslowas being null or undefined

        // const { paymentAmount = {} } = asLowAs;

        let renderLowPaymentAmount = '...';

        if (asLowAs?.paymentAmount) {
            const { paymentAmount = {} } = asLowAs;
            renderLowPaymentAmount = parseFloat(paymentAmount?.Value / 100);
            renderLowPaymentAmount = renderLowPaymentAmount.toFixed(2);
        }
        const url = 'https://www.frontrunneroutfitters.com';

        return (
        <div id="bread-payments">
            <div id="bread-payments-logo">
                {/* <img src="/media/wysiwyg/bread-finance/bread-pay.svg" width="80" height="32" style={{ width: '80px' }} /> */}
                <img src={`${url}/media/wysiwyg/bread-finance/bread-pay.svg`} width="80" height="32" style={{ width: '80px' }} />
            </div>
            <div id="bread-payments-text">
                <Link to="/bread-finance" target="_blank" className="bread-finance-info">
                    <div id="bread-payments-text-apr">
                        <div>0% APR available for 6, 12, and 18-Month Financing*,</div>
                        <div>
                            24-Month Financing available at
                            {' '}
                            <strong>
                            $
                            {renderLowPaymentAmount}
                            /Month
                            </strong>
                            {' '}
                            with Bread Pay.
                            <span id="financing-disclosure">*</span>
                            <FontAwesomeIcon icon={ faInfoCircle } />
                        </div>
                    </div>
                    <div id="bread_div" className="bread-finance-btn">
                        <div id="bread-prequal-btn" onClick={ this.openBreadOverlay } />
                    </div>
                </Link>
            </div>
        </div>
        );
    };

    renderGuarantee() {
        // Check if B2B customer (Use local storage check)
        const isB2B = JSON.parse(sessionStorage.getItem('is_b2b')) ?? false;

        if (!isUsaStore() || isB2B) {
            return null;
        }

        return (
            <div className="fr_product_pills_wrapper">
                <div
                  className="fr_product_pills fr_product_money_back"
                >
                    <div className="fr_product_pills-icon">
                        <FontAwesomeIcon icon={ faCircleStar } size="1x" color="#92A414" />
                    </div>
                    <div className="fr_product_pills-content">
                        <div className="title">
                            30 Day Money Back
                        </div>
                        <div className="des">
                            Guarantee
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderFreeShipping() {
        const {
            product,
            product: {
                discountPercentage,
                shipping_block
            }
        } = this.props;

        if (!hasFreeShipping(product)) {
            return null;
        }

        const PriceSemanticElementName = discountPercentage > 0 ? 'ins' : 'span';
        const free_shipping_product_text = get(shipping_block, '0.free_shipping_product_text', '');
        const free_shipping_product_subtext = get(shipping_block, '0.free_shipping_product_subtext', '');

        if (!free_shipping_product_text) {
            return null;
        }

        return (
            <div className="fr_product_pills_wrapper">
                <div
                  className="fr_product_pills fr_product_free_shipping"
                >
                    <div className="fr_product_pills-icon">
                        <FontAwesomeIcon icon={ faShippingFast } size="1x" color="#222" />
                    </div>
                    <div className="fr_product_pills-content">
                        <div className="title">
                            { free_shipping_product_text }
                        </div>
                        <div className="des">
                            <Html content={ free_shipping_product_subtext } />
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    performStaticAddToCart = () => {
        const { addToCart, staticData: { smartProduct: { ssku, stype_id, squantity } } } = this.props;
        this.setState({ addProgress: true });
        addToCart({
            product: {
                sku: ssku,
                type_id: stype_id
            },
            quantity: squantity

        });
        setTimeout(() => {
            this.setState({ addProgress: false });
        }, 2500);
    };

    // Smart Widget
    renderSmartWidgetDisplay(widget) {
        if (!widget) {
            return null;
        }

        const { customer } = this.props;
        const { is_b2b = false } = customer;
        let userType = 'b2c';
        if (isB2BUser(is_b2b)) {
            userType = 'b2b';
        }

        if (widget?.user_type !== userType && widget?.user_type !== 'both') {
            return null;
        }

        // If widget text is empty, return null
        if (!widget.text) {
            return null;
        }

        return (
            <div className="SmartWidget">
                <div className="SmartWidgetDes">
                    { widget.image && (
                    <div className="image">
                        { parseInt(widget.image_free_banner) === 1 && (
                        <div className="promo_label">
                            { __('Free') }
                        </div>
                        )}
                        { parseInt(widget.image_combo_banner) === 1 && (
                        <div className="promo_label">
                            { __('Combo Deal') }
                        </div>
                        )}
                        { parseInt(widget.image_deal_banner) === 1 && (
                        <div className="promo_label">
                            { __('Deal') }
                        </div>
                        )}
                        <img src={ `${widget.image}` } alt={widget.name} width="200px" />
                    </div>
                    ) }
                    <div className="content">
                        {/* <div className="title">
                            12 Days of Giving
                            { ' - ' }
                            { __('One Day Only') }
                            !
                        </div> */}
                        {(widget.text_title) && (
                            <div className="title">
                                { widget.text_title }
                            </div>
                        )}
                        {(widget.text) && (
                            <Html content={ widget.text } />
                        )}
                        {(widget.text_after) && (
                            <div className="text_after">
                                <Html content={ widget.text_after } />
                            </div>
                        )}
                        {/* { ' ' }
                        <br />
                        <span>
                            *
                            { __('Valid today only!') }
                            { ' ' }
                            { __('While Supplies Last.') }
                        </span> */}
                        { widget.link && (
                        <div className="SmartWidget-actions">
                            <Link to={ widget.link } className="btn btn-primary">
                                { widget.link_text ? widget.link_text : __('Shop Now') }
                            </Link>
                        </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }

    renderSmartWidget() {
        if (isIntStore()) {
            return null;
        }

        const {
            product,
            product: { variants, configurable_product_options_selection = {} },
            configurableVariantIndex,
            staticData: {
                descriptionSave, descriptionCombo, finalPrice, currentPrice, imgSrc
            },
            addToCart,
            customer
        } = this.props;

        // Promotions
        // const { tent045 } = getBuyXFreeYPromo();

        const { variant } = configurable_product_options_selection;
        const { addProgress = false } = this.state;
        const productOrVariant = variant ?? product;
        const addToCartLevel = addProgress ? __('Adding...') : __('Add to cart');
        const { sku } = productOrVariant;
        const currency = window.storeConfig.currencyCode;

        // B2B Info
        let isB2B = false;
        const { is_b2b = false } = customer;
        isB2B = isB2BUser(is_b2b);
        if (!isB2B) {
            // Check if B2B customer (Use local storage check)
            isB2B = JSON.parse(sessionStorage.getItem('is_b2b')) ?? false;
        }

        // Gift Promotions
        if (!isEmpty(sku)) {
            if (!this.state.setGiftWidgets) {
                getGiftPromoWidgets({
                    sku,
                    is_b2b: isB2B
                }).then((giftWidgets) => {
                    if (giftWidgets) {
                        this.setState({ giftWidgets, setGiftWidgets: true });
                    }
                });
            }

            if (this.state.setGiftWidgets && this.state.giftWidgets.length > 0) {
                const { giftWidgets } = this.state;
                return giftWidgets.map((widget) => this.renderSmartWidgetDisplay(widget));
            }
        }

        // Show only for B2C in Europe
        /* if (isEurStore(true) && !isB2B) {
            const allowSku = ['FRID097', 'FRID098', 'FRID099', 'FRID100', 'FRID101', 'FRID129', 'FRID130', 'FRID131', 'FRID141', 'FRID142', 'FRID143', 'FRID144', 'FRID145', 'FRID146', 'FRID147', 'FRID148'];

            let priceBoxText = 'incl. 19% DE VAT, excl. Shipping';
            if (window.storeConfig.code === 'de_de') {
                priceBoxText = 'inkl. 19% DE MwSt., zzgl. Versand';
            }

            if (sku === 'ECOM220') {
                // Change description if store is de_de
                let descriptionSaveText = descriptionSave;
                if (window.storeConfig.code === 'de_de') {
                    descriptionSaveText = '<div><div class="title">JETZT SPAREN! </div> Sichere Dir jetzt die tragbare Dometic PLB 40 Lithiumbatterie für nur <strong>599,00€</strong> ODER kaufe zusätzlich eine beliebige Dometic CFX3 Kühlbox und sichere Dir die tragbare Dometic PLB40 Lithiumbatterie für nur <strong>499,00€!</strong></div>';
                }
                if (window.storeConfig.code === 'es_be') {
                    descriptionSaveText = '<div><div class="title">¡AHORRE A LO GRANDE! </div> Compre la batería portátil de litio Dometic PLB 40 ahora por solo <strong>€599,00</strong> ó agrega cualquier nevera Dometic CFX3 y obtenga la batería portátil de litio Dometic PLB40 por tan solo <strong>€499,00!</strong></div>';
                }
                if (window.storeConfig.code === 'nl_be') {
                    descriptionSaveText = '<div><div class="title">GROOT VOORDEEL! </div> Koop nu de Dometic PLB 40 draagbare lithiumbatterij voor slechts <strong>€ 599,00</strong> OF voeg een Dometic CFX3-koeler toe en ontvang de Dometic PLB40 draagbare lithiumbatterij voor slechts <strong>€ 499,00!</strong></div>';
                }
                if (window.storeConfig.code === 'fr_be') {
                    descriptionSaveText = '<div><div class="title">FAITES DE GROSSES ÉCONOMIES ! </div> Achetez la batterie portable au lithium PLB 40 de Dometic maintenant pour seulement <strong>599,00 €</strong> OU ajoutez une glacière CFX3 de Dometic et obtenez la batterie portable au lithium PLB40 de Dometic pour seulement <strong>499,00 € !</strong></div>';
                }

                return (
                    <div className="SmartWidget">
                        <div className="SmartWidgetDes">
                            <div className="content">
                                <Html content={ descriptionSaveText } />
                            </div>
                        </div>
                    </div>
                );
            }
            if (allowSku.indexOf(sku) !== -1) {
                // Change description if store is de_de
                let descriptionComboText = descriptionCombo;
                if (window.storeConfig.code === 'de_de') {
                    descriptionComboText = '<div><div class="title">KOMBI-DEAL! </div> Kaufe eine beliebige Dometic CFX3 Kühlbox und die tragbare Dometic PLB40 Lithiumbatterie als Kombi und sichere Dir die tragbare Dometic PLB 40 Lithiumbatterie für NUR <strong>499,00€!</strong></div>';
                }
                if (window.storeConfig.code === 'es_be') {
                    descriptionComboText = '<div><div class="title">¡OFERTA COMBO! </div> Compre la batería portátil de litio Dometic PLB 40 ahora por solo €599,00 ó agrega cualquier nevera Dometic CFX3 y obtenga la batería portátil de litio Dometic PLB40 por tan solo <strong>€499,00!</strong></div>';
                }
                if (window.storeConfig.code === 'nl_be') {
                    descriptionComboText = '<div><div class="title">COMBO-DEAL! </div> Voeg de Dometic PLB 40 draagbare lithiumbatterij toe aan elke aankoop van een Dometic CFX3-koeler en ontvang de Dometic PLB 40 draagbare lithiumbatterij voor slechts <strong>€ 499,00!</strong></div>';
                }
                if (window.storeConfig.code === 'fr_be') {
                    descriptionComboText = '<div><div class="title">OFFRE COMBINÉE ! </div> Ajoutez la batterie portable au lithium PLB 40 de Dometic à tout achat d\'une glacière CFX3 de Dometic et obtenez la batterie portable au lithium PLB 40 de Dometic pour seulement <strong>499,00 € !</strong></div>';
                }

                return (
                    <div className="SmartWidget">
                        <div className="SmartWidgetDes">
                            <div className="content">
                                <Html content={ descriptionComboText } />
                            </div>
                        </div>
                        <div className="SmartWidgetImgPrice">
                            <div className="SmartWidgetImg">
                                <Link to="/dometic-plb40-portable-lithium-battery-40-ah.html">
                                    <img src={ imgSrc } alt="img" />
                                </Link>
                            </div>
                            <div className="SmartWidgetPrice">
                                <span className="currentPrice">{ formatPrice(roundPrice(currentPrice), currency) }</span>
                                <span className="listPrice">{ formatPrice(roundPrice(finalPrice), currency) }</span>
                                { isEurStore() && (
                                    <div className="price-box-text">
                                        { priceBoxText }
                                    </div>
                                ) }
                            </div>
                            <div className="SmartWidgetButton">
                                <button
                                  className={`btn btn-primary ${addToCartLevel}`}
                                  onClick={() => this.performStaticAddToCart()}
                                >
                                    { addToCartLevel }
                                </button>
                                <Link to="/dometic-plb40-portable-lithium-battery-40-ah.html">
                                    { __('More Info') }
                                </Link>
                            </div>
                        </div>
                    </div>
                );
            }
        } */

        return null;
    }

    renderCanShipNow() {
        const {
            product,
            product: {
                discountPercentage,
                shipping_block,
                sku,
                variants,
                items
            },
            configurableVariantIndex,
            showOnlyIfLoaded
        } = this.props;

        if (sku === 'VOUC002') {
            return null;
        }

        const PriceSemanticElementName = discountPercentage > 0 ? 'ins' : 'span';
        const canShipNow = get(shipping_block, '0.can_ship_now', '');

        let bundle_stock_status = '';
        if (!isEmpty(items && items[0].options) && items.length > 0 && items[0].options.length > 0) {
            bundle_stock_status = items[0].options[0].product.stock_status;
        }
        const productOrVariant = variants && variants[configurableVariantIndex] !== undefined
            ? variants[configurableVariantIndex]
            : product;
        const { stock_status } = productOrVariant;
        const isOutOfStock = (stock_status === 'OUT_OF_STOCK' || bundle_stock_status === 'OUT_OF_STOCK');

        let shipNowClass = 'can-ship-now can-ship loaded';
        if (!canShipNow || isOutOfStock) {
            shipNowClass = 'cant-ship-now can-ship loaded';
        }

        return (
            <div className={ shipNowClass }>
            { showOnlyIfLoaded(
                product.sku,
                (
                    <>
                    <FontAwesomeIcon icon={ ['fas', 'check'] } />
                    <div>
                        { canShipNow }
                    </div>
                    </>
                ),
                <TextPlaceholder length="short" />
            ) }
            </div>
        );
    }

    renderYotpoLoyaltyEarnPoints = () => {
        if (isEurStore()) {
            return null;
        }

        // Check if B2B customer (Use local storage check)
        const isB2B = JSON.parse(sessionStorage.getItem('is_b2b')) ?? false;

        if (isB2B) {
            return null;
        }

        const {
            product,
            product: {
                sku,
                attributes = {}
            },
            productPrice,
            showOnlyIfLoaded
        } = this.props;

        if (!attributes) {
            return null;
        }

        let price = productPrice?.maximum_price?.final_price?.value;
        if (!price || price === undefined) {
            price = productPrice?.minimum_price?.final_price?.value;
        }

        if (!price || price === undefined) {
            return null;
        }

        // 1 point = $1, calculate points from price, rounded down to the nearest integer
        const points = Math.floor(price);

        // Translated text
        const text = __('Earn {points} Explorer Rewards Points').replace('{points}', `<strong>${points}</strong>`);

        return (
            <>
            { showOnlyIfLoaded(
                product.sku,
                (
                    <Link to="/rewards" className="yotpo-loyalty-earn-points" target="_blank">
                        <FontAwesomeIcon icon={ faTrophy } size="1x" />
                        <div>
                            <Html content={ text } />
                        </div>
                    </Link>
                ),
                <TextPlaceholder length="medium" />
            ) }
            </>
        );
    };

    renderVideosPopup = () => {
        const {
            selectedInxDataVal,
            isEnabledVideoTab,
            product: { additionalInformation = {} }
        } = this.props;
        const { videos = [] } = additionalInformation;
        if (videos && videos[0]?.video?.length === 1 && selectedInxDataVal === 3) {
            const videoLink = videos[0]?.video[0]?.video_link;
            return (
                <div className="VideosPopup" onClick={ isEnabledVideoTab }>
                    <div className="VideosPopupWrapp">
                        <div onClick={ isEnabledVideoTab } className="close">×</div>
                        <iframe src={ videoLink } title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
                    </div>
                </div>
            );
        }

        return null;
    };

    renderExpertVoiceReviewsDisplay = () => <div id="expertvoice-recommendations" />;

    renderWhileStocksLast = () => {
        const {
            product,
            product: {
                price_range,
                type_id,
                variants,
                items
            },
            configurableVariantIndex,
            showOnlyIfLoaded,
            // getBlackFridayPromo = {
            //     active: false
            // },
            // getXmasPromo = {
            //     active: false
            // },
            getSalesPromotion = {
                active: false
            }
        } = this.props;

        const productOrVariant = variants && variants[configurableVariantIndex] !== undefined
            ? variants[configurableVariantIndex]
            : product;
        const { sku, stock_status } = productOrVariant;

        // Check if B2B customer (Use local storage check)
        const isB2B = JSON.parse(sessionStorage.getItem('is_b2b')) ?? false;
        if (isB2B) {
            return null;
        }

        // If out of stock, don't show the limited stock line
        if (stock_status === 'OUT_OF_STOCK') {
            return null;
        }

        // Show Discount percentage for B2C
        let discount = 0;
        if (price_range) {
            if (type_id === 'bundle') {
                if (items && items.length > 0 && items[0].options.length > 0) {
                    discount = items[0]?.options[0]?.product?.price_range?.minimum_price?.discount?.percent_off ?? 0;
                }
            } else {
                discount = price_range?.minimum_price?.discount?.percent_off ?? 0;
            }
        } else {
            return null;
        }

        // If there is no discount, don't show the limited stock line
        if (parseInt(discount) === 0) {
            return null;
        }

        const { active, showProductCountdown } = getSalesPromotion;
        if (!showProductCountdown) {
            return null;
        }

        // If discount > 0, show hurry while stocks last
        if (discount > 0) {
            return (
                <div className="limited-stock">
                    <>
                    <FontAwesomeIcon icon={ faExclamationTriangle } size="1x" color="#222" />
                    { ' ' }
                    { __('Hurry! Limited Quantity available. Only while stocks last!') }
                    </>
                </div>
            );
        }

        /* return (
            <div className="limited-stock">
                { showOnlyIfLoaded(
                    sku, (
                        <>
                        { !active ? (
                            <>
                            <FontAwesomeIcon icon={ faExclamationTriangle } size="1x" color="#222" />
                            { ' ' }
                            { __('Hurry! Limited Quantity available. Only while stocks last!') }
                            </>
                        ) : (
                            <SalesCountdown startDate={getSalesPromotion?.startDate} endDate={getSalesPromotion?.endDate} />
                        )}
                        </>
                    ),
                    <TextPlaceholder content={ name } length="medium" />
                )}
            </div>
        ); */
    };

    renderActionsBottom = () => (
        <div className="product-actions-bottom">
            { this.renderYotpoLoyaltyEarnPoints() }
            { this.renderCanShipNow() }
            { this.renderWhileStocksLast() }
        </div>
    );

    renderCaliforniaNotice = () => {
        if (!isUsaStore()) {
            return null;
        }

        return (
            <div className="warningText">
                <strong>WARNING</strong>
                { ' ' }
                Cancer or Reproductive Harm
                { ' ' }
                <Link to="https://www.P65Warnings.ca.gov" target="_blank" rel="noopener noreferrer nofollow">
                    www.P65Warnings.ca.gov
                </Link>
            </div>
        );
    };

    render() {
        let priceWrapperClass = 'price-wrapper-row';
        if (isUsaStore()) {
            priceWrapperClass = 'price-wrapper-column';
        }
        const {
            product: {
                sku
            }
        } = this.props;

        return (
            <>
                <article block="ProductActions">
                    { this.renderYoptoRatingWidget() }
                    { this.renderName() }
                    <div className="brand-sku">
                        { this.renderBrand() }
                        <span className="separator">|</span>
                        { this.renderSKU() }
                    </div>
                    <div className={ `price-wrapper ${priceWrapperClass}` }>
                        <div className="price-wrapper-price">
                            { this.renderPriceWithGlobalSchema() }
                        </div>
                        <div className="price-wrapper-pills">
                            { this.renderGuarantee() }
                            { this.renderFreeShipping() }
                        </div>
                    </div>
                    {/* <div className={`pay-later-wrap ${isUsaStore() || isAusStore() || isRsaStore() ? 'active' : ''}`}> */}
                    { this.renderPaymentDropdown() }
                    {/* </div> */}
                    { this.renderShortDescription() }
                    { this.renderSmartWidget() }
                    { this.renderActionsBottom() }
                    { this.renderGiftDetails() }
                    { this.renderConfigurableAttributes() }
                    { /* { this.renderCustomizableOptions() } */ }
                    {/* { this.renderBundleItems() } */}
                    { this.renderGroupedItems() }
                    { this.renderSelectedBundlePrice() }
                    { this.renderAddToCartWrapper() }
                    { this.renderWarrantyRow() }
                    { this.renderReviews() }
                    { /* { this.renderNameAndBrand() } */ }
                    { /* { this.renderSkuAndStock() } */ }
                    { /* { this.renderShareOptions() } */ }
                    { this.renderTierPrices() }
                    { this.reviewHighlightsWidget() }
                </article>
                <div className="AdditionalInfo">
                    <div className="product-info tab-data">
                        { this.renderTabs() }
                        <div id="reviews" />
                        <div ref={(el) => {
                            this.scrollRef = el;
                        }}
                        />
                    </div>
                    { this.renderCategoryIdsString() }
                    { this.renderAdditionalSections() }
                    { this.renderYotpoUGC() }
                    { this.renderFaqSections() }
                    { this.renderGotMoreQstSections() }
                    { this.renderYoptoReviews() }
                    { this.renderExpertVoiceReviewsDisplay() }
                    { this.renderProductaAlsoLikeSections() }
                    { this.renderCaliforniaNotice() }
                </div>
                { this.renderPayJustNowPopup() }
                { this.renderVideosPopup() }
            </>
        );
    }
}

export default ProductActions;
