/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

export const UPDATE_TURNSTILE_RECAPTCHA_TOKEN = 'UPDATE_TURNSTILE_RECAPTCHA_TOKEN';
export const UPDATE_TURNSTILE_RECAPTCHA_TOKEN_VALUE = 'UPDATE_TURNSTILE_RECAPTCHA_TOKEN_VALUE';

/** @namespace Pwa/Store/TurnstileRecaptcha/Action/updateTurnstileRecaptcha */
export const updateTurnstileRecaptcha = (turnstileRecaptchaToken) => ({
    type: UPDATE_TURNSTILE_RECAPTCHA_TOKEN,
    turnstileRecaptchaToken
});

/** @namespace Pwa/Store/TurnstileRecaptcha/Action/updateTurnstileRecaptchaValue */
export const updateTurnstileRecaptchaValue = (turnstileRecaptchaTokenValue) => ({
    type: UPDATE_TURNSTILE_RECAPTCHA_TOKEN_VALUE,
    turnstileRecaptchaTokenValue
});
