/* eslint-disable react/jsx-curly-spacing */
/* eslint-disable no-magic-numbers */
/* eslint-disable fp/no-let */
/* eslint-disable @scandipwa/scandipwa-guidelines/derived-class-names */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/* eslint-disable @scandipwa/scandipwa-guidelines/no-jsx-variables */
/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
/* eslint-disable max-lines */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable max-len */

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import {
    cloneElement,
    lazy,
    PureComponent,
    Suspense
} from 'react';
import { Router as ReactRouter } from 'react-router';
import { Route, Switch } from 'react-router-dom';

import Breadcrumbs from 'Component/Breadcrumbs';
import CookiePopup from 'Component/CookiePopup';
import DemoNotice from 'Component/DemoNotice';
import FirstVisitPrivacyPolicyPopup from 'Component/FirstVisitPrivacyPolicyPopup';
import Footer from 'Component/Footer';
import GdprCookieGroupPopup from 'Component/GdprCookieGroupPopup';
import GdprCookiePopup from 'Component/GdprCookiePopup';
import Header from 'Component/Header';
import Loader from 'Component/Loader';
import Meta from 'Component/Meta';
import NavigationTabs from 'Component/NavigationTabs';
import NewVersionPopup from 'Component/NewVersionPopup';
import NotificationList from 'Component/NotificationList';
import OfflineNotice from 'Component/OfflineNotice';
import PrivacyPolicyPopup from 'Component/PrivacyPolicyPopup';
import Maintenance from 'Route/Maintenance';
import QuickOrder from 'Route/QuickOrder';
import SomethingWentWrong from 'Route/SomethingWentWrong';
import UrlRewrites from 'Route/UrlRewrites';
import { getIsAdminLoggedInAsCustomer } from 'Util/Auth';
import history from 'Util/History';

import {
    AFTER_ITEMS_TYPE,
    BEFORE_ITEMS_TYPE,
    BREADCRUMBS_TYPE,
    MAINTENANCE_TYPE,
    SWITCH_ITEMS_TYPE
} from './Router.config';

export const storeBaseName = window.storeConfig.baseName;

export const CartPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "cart" */ 'Route/CartPage'));
export const Checkout = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "checkout" */ 'Route/Checkout'));
export const CheckoutSuccess = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "checkout" */ 'Route/CheckoutSuccess'));
export const CmsPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "cms" */ 'Route/CmsPage'));
export const HomePage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "cms" */ 'Route/HomePage'));
export const MyAccount = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "account" */ 'Route/MyAccount'));
export const Quotation = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "account" */ 'Route/Quotation/Quotation.component'));
export const PasswordChangePage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "misc" */ 'Route/PasswordChangePage'));
export const SearchPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "category" */ 'Route/SearchPage'));
export const SearchPageOverlay = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "category" */ 'Route/SearchPageOverlay'));
export const ConfirmAccountPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "cms" */ 'Route/ConfirmAccountPage'));
export const MenuPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "cms" */ 'Route/MenuPage'));
export const WishlistShared = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "misc" */ 'Route/WishlistSharedPage'));
export const ContactPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "contact" */ 'Route/ContactPage'));
export const PaymentSuccess = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "checkout" */ 'Route/PaymentSuccess'));
export const AmazonPaySuccess = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "checkout" */ 'Route/AmazonPaySuccess'));
export const StripePaySuccess = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "checkout" */ 'Route/StripePaySuccess'));
export const PayflexSuccess = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "checkout" */ 'Route/PayflexSuccess'));

export const ProductComparePage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "compare" */ 'Route/ProductComparePage'));
export const CategoryPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "compare" */ 'Route/CategoryPage'));
export const CreateAccountPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "compare" */ 'Route/CreateAccount'));
export const LoginAccountPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "compare" */ 'Route/LoginAccount'));
export const ForgotPasswordPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "compare" */ 'Route/ForgotPassword'));
export const AdventurePage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "compare" */ 'Route/AdventurePage'));
export const GearzettePage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "compare" */ 'Route/GearzettePage'));
// export const YoptoReviews = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "checkout" */ 'Route/YoptoReviews'));

/** @namespace Pwa/Component/Router/Component/withStoreRegex */
export const withStoreRegex = (path) => window.storeRegexText.concat(path);

/** @namespace Pwa/Component/Router/Component/Router */
export class Router extends PureComponent {
    static propTypes = {
        isBigOffline: PropTypes.bool
    };

    static defaultProps = {
        isBigOffline: false
    };

    [BEFORE_ITEMS_TYPE] = [
        {
            component: <NotificationList />,
            position: 10
        },
        {
            component: <DemoNotice />,
            position: 15
        },
        {
            component: <Header />,
            position: 20
        },
        {
            component: <NavigationTabs />,
            position: 25
        },
        {
            component: <NewVersionPopup />,
            position: 35
        },
        {
            component: <PrivacyPolicyPopup />,
            position: 9999
        },
        {
            component: <FirstVisitPrivacyPolicyPopup />,
            position: 9998
        }
    ];

    [BREADCRUMBS_TYPE] = [
        {
            component: <Breadcrumbs />,
            position: 30
        }
    ];

    [MAINTENANCE_TYPE] = [
        {
            component: <Maintenance />,
            position: 9997
        }
    ];

    [SWITCH_ITEMS_TYPE] = [
        {
            component: <Route path={ withStoreRegex('/') } exact render={ (props) => <HomePage { ...props } /> } />,
            position: 10
        },
        // {
        //     component: <Route path={ withStoreRegex('/loginascustomer/login/') } render={ (props) => <HomePage { ...props } /> } />,
        //     position: 10
        // },
        {
            component: <Route path={ withStoreRegex('/search/:query') } render={ (props) => <SearchPage { ...props } /> } />,
            position: 25
        },
        {
            component: <Route path={ withStoreRegex('/search') } render={ (props) => <SearchPageOverlay { ...props } /> } />,
            position: 25
        },
        {
            component: <Route path={ withStoreRegex('/page') } render={ (props) => <CmsPage { ...props } /> } />,
            position: 40
        },
        {
            component: <Route path={ withStoreRegex('/cart') } exact render={ (props) => <CartPage { ...props } /> } />,
            position: 50
        },
        {
            component: <Route path={ withStoreRegex('/checkout/onepage/success') } exact render={ (props) => <CheckoutSuccess { ...props } /> } />,
            position: 52
        },
        {
            component: <Route path={ withStoreRegex('/paygate/redirect/success') } exact render={ (props) => <CheckoutSuccess { ...props } /> } />,
            position: 53
        },
        {
            component: <Route path={ withStoreRegex('/amazonpay/redirect/success') } exact render={ (props) => <AmazonPaySuccess { ...props } /> } />,
            position: 54
        },
        {
            component: <Route path={ withStoreRegex('/checkout/:step?') } render={ (props) => <Checkout { ...props } /> } />,
            position: 55
        },
        {
            component: <Route path={ withStoreRegex('/:account*/createPassword') } render={ (props) => <PasswordChangePage { ...props } /> } />,
            position: 60
        },
        {
            component: <Route path={ withStoreRegex('/:account*/create') } render={ (props) => <CreateAccountPage { ...props } /> } />,
            position: 61
        },
        {
            component: <Route path={ withStoreRegex('/:account*/login') } render={ (props) => <LoginAccountPage { ...props } /> } />,
            position: 62
        },
        {
            component: <Route path={ withStoreRegex('/:account*/forgotpassword') } render={ (props) => <ForgotPasswordPage { ...props } /> } />,
            position: 63
        },
        {
            component: <Route path={ withStoreRegex('/:account*/confirm') } render={ (props) => <ConfirmAccountPage { ...props } /> } />,
            position: 65
        },
        {
            component: <Route path={ withStoreRegex('/my-account/:tab?') } render={ (props) => <MyAccount { ...props } /> } />,
            position: 70
        },
        {
            component: <Route path={ withStoreRegex('/forgot-password') } render={ (props) => <MyAccount { ...props } /> } />,
            position: 71
        },
        {
            component: <Route path={ withStoreRegex('/menu') } render={ (props) => <MenuPage { ...props } /> } />,
            position: 80
        },
        {
            component: <Route path={ withStoreRegex('/wishlist/shared/:code') } render={ (props) => <WishlistShared { ...props } /> } />,
            position: 81
        },
        {
            component: <Route path={ withStoreRegex('/contact') } render={ (props) => <ContactPage { ...props } /> } />,
            position: 82
        },
        {
            component: <Route path={ withStoreRegex('/compare') } render={ (props) => <ProductComparePage { ...props } /> } />,
            position: 83
        },
        {
            component: <Route path={ withStoreRegex('/vehicle/:vehicle') } render={ (props) => <CategoryPage { ...props } /> } />,
            position: 85
        },
        {
            component: <Route path={ withStoreRegex('/adventure/:slug') } render={ (props) => <AdventurePage { ...props } /> } />,
            position: 86
        },
        {
            component: <Route path={ withStoreRegex('/vehicles/:slug') } render={ (props) => <AdventurePage { ...props } /> } />,
            position: 87
        },
        {
            component: <Route path={ withStoreRegex('/racks/:slug') } render={ (props) => <AdventurePage { ...props } /> } />,
            position: 88
        },
        {
            component: <Route path={ withStoreRegex('/slimline-ii-roof-rack/:slug') } render={ (props) => <AdventurePage { ...props } /> } />,
            position: 89
        },
        {
            component: <Route path={ withStoreRegex('/slimsport-roof-rack/:slug') } render={ (props) => <AdventurePage { ...props } /> } />,
            position: 90
        },
        {
            component: <Route path={ withStoreRegex('/slimpro-van-rack/:slug') } render={ (props) => <AdventurePage { ...props } /> } />,
            position: 91
        },
        {
            component: <Route path={ withStoreRegex('/promotions/:slug') } render={ (props) => <AdventurePage { ...props } /> } />,
            position: 92
        },
        {
            component: <Route path={ withStoreRegex('/deals/:slug') } render={ (props) => <AdventurePage { ...props } /> } />,
            position: 93
        },
        {
            component: <Route path={ withStoreRegex('/deals') } render={ (props) => <AdventurePage { ...props } /> } />,
            position: 94
        },
        {
            component: <Route path={ withStoreRegex('/whats-new') } render={ (props) => <AdventurePage { ...props } /> } />,
            position: 95
        },
        {
            component: <Route path={ withStoreRegex('/gift-guide') } render={ (props) => <AdventurePage { ...props } /> } />,
            position: 96
        },
        {
            component: <Route path={ withStoreRegex('/payflex') } render={ (props) => <AdventurePage { ...props } /> } />,
            position: 97
        },
        {
            component: <Route path={ withStoreRegex('/workshop') } render={ (props) => <AdventurePage { ...props } /> } />,
            position: 98
        },
        {
            component: <Route path={ withStoreRegex('/products/:slug') } render={ (props) => <AdventurePage { ...props } /> } />,
            position: 99
        },
        {
            component: <Route path={ withStoreRegex('/pages/:slug') } render={ (props) => <AdventurePage { ...props } /> } />,
            position: 100
        },
        {
            component: <Route path={ withStoreRegex('/vehicles') } render={ (props) => <AdventurePage { ...props } /> } />,
            position: 101
        },
        {
            component: <Route path={ withStoreRegex('/adventure') } render={ (props) => <AdventurePage { ...props } /> } />,
            position: 102
        },
        {
            component: <Route path={ withStoreRegex('/vehicle-test-fitments') } render={ (props) => <AdventurePage { ...props } /> } />,
            position: 103
        },
        {
            component: <Route path={ withStoreRegex('/outlet') } render={ (props) => <AdventurePage { ...props } /> } />,
            position: 104
        },
        {
            component: <Route path={ withStoreRegex('/outlet-b2b') } render={ (props) => <AdventurePage { ...props } /> } />,
            position: 105
        },
        {
            component: <Route path={ withStoreRegex('/partpay/order/success/mage_order_id/:slug') } exact render={ (props) => <PayflexSuccess { ...props } /> } />,
            position: 991
        },
        {
            component: <Route path={ withStoreRegex('/partpay/order/fail/mage_order_id/:slug') } exact render={ (props) => <CartPage { ...props } /> } />,
            position: 990
        },
        {
            component: <Route path={ withStoreRegex('/stripepay/success') } exact render={ (props) => <StripePaySuccess { ...props } /> } />,
            position: 997
        },
        // {
        //     component: <Route path={ withStoreRegex('/yopto/reviews') } exact render={ (props) => <YoptoReviews { ...props } /> } />,
        //     position: 997
        // },
        {
            component: <Route path={ withStoreRegex('/gearzette') } render={ (props) => <GearzettePage { ...props } /> } />,
            position: 110
        },
        {
            component: <Route path={ withStoreRegex('/gearzette/:slug') } render={ (props) => <GearzettePage { ...props } /> } />,
            position: 111
        },
        {
            component: <Route path={ withStoreRegex('/quickorder') } render={ (props) => <QuickOrder { ...props } /> } />,
            position: 998
        },
        {
            component: <Route path={ withStoreRegex('/company/customer/acceptinvitation') } render={ (props) => <HomePage { ...props } /> } />,
            position: 999
        },
        // {
        //     component: <Route path={ withStoreRegex('/quotation/quote/checkout_customer') } render={ (props) => <MyAccount { ...props } /> } />,
        //     position: 1000
        // },
        {
            component: <Route path={ withStoreRegex('/quotation/quote/') } render={ () => <Quotation /> } />,
            position: 1000
        },
        {
            component: <Route render={ (props) => <UrlRewrites { ...props } /> } />,
            position: 1001
        }
    ];

    [AFTER_ITEMS_TYPE] = [
        {
            component: <Footer />,
            position: 10
        },
        {
            component: <CookiePopup />,
            position: 20
        },
        {
            component: <GdprCookiePopup />,
            position: 250
        },
        {
            component: <GdprCookieGroupPopup />,
            position: 300
        }
    ];

    state = {
        hasError: false,
        errorDetails: {}
    };

    componentDidCatch(err, info) {
        this.setState({
            hasError: true,
            errorDetails: { err, info }
        });
    }

    getSortedItems(type) {
        return this[type].sort(
            (a, b) => a.position - b.position
        ).filter(
            (entry) => {
                if (!entry.component) {
                    // eslint-disable-next-line no-console
                    console.warn('There is an item without a component property declared in main router.');
                    return false;
                }

                return true;
            }
        );
    }

    handleErrorReset = () => {
        this.setState({ hasError: false });
    };

    renderItemsOfType(type) {
        return this.getSortedItems(type)
            .map(({ position, component }) => cloneElement(component, { key: position }));
    }

    renderMainItems() {
        const { isBigOffline } = this.props;

        if (!navigator.onLine && isBigOffline) {
            return <OfflineNotice isPage />;
        }

        return (
            <Suspense fallback={ this.renderFallbackPage() }>
                <Switch>
                    { this.renderItemsOfType(SWITCH_ITEMS_TYPE) }
                </Switch>
            </Suspense>
        );
    }

    renderErrorRouterContent() {
        const { errorDetails } = this.state;

        return (
            <SomethingWentWrong
              onClick={ this.handleErrorReset }
              errorDetails={ errorDetails }
            />
        );
    }

    renderFallbackPage() {
        return (
            <main style={ { height: '100vh' } }>
                <Loader isLoading />
            </main>
        );
    }

    renderDefaultRouterContent() {
        let valueName = '';
        const value = getIsAdminLoggedInAsCustomer();
        if (value) {
            valueName = 'mg_customer';
        }

        return (
            <div className={ `main_wrapper ${valueName}` }>
                { this.renderItemsOfType(MAINTENANCE_TYPE) }
                { this.renderItemsOfType(BEFORE_ITEMS_TYPE) }
                { this.renderMainItems() }
                {/* { this.renderItemsOfType(BREADCRUMBS_TYPE) } */}
                { this.renderItemsOfType(AFTER_ITEMS_TYPE) }
            </div>
        );
    }

    renderRouterContent() {
        const { hasError } = this.state;

        if (hasError) {
            return this.renderErrorRouterContent();
        }

        return this.renderDefaultRouterContent();
    }

    render() {
        return (
            <>
                <Meta />
                <ReactRouter history={ history } basename={ storeBaseName }>
                    { this.renderRouterContent() }
                </ReactRouter>
            </>
        );
    }
}

export default Router;
