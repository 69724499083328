/* eslint-disable react/jsx-curly-spacing */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable prefer-template */
/* eslint-disable no-undef */
/* eslint-disable eqeqeq */
/* eslint-disable react/sort-comp */
/* eslint-disable react/no-unused-state */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable fp/no-let */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-magic-numbers */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-conditional */
/* eslint-disable @scandipwa/scandipwa-guidelines/no-jsx-variables */
/* eslint-disable react/jsx-no-comment-textnodes */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable max-lines */
/* eslint-disable max-len */
/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
/* eslint-disable @scandipwa/scandipwa-guidelines/use-namespace */
/* eslint-disable @scandipwa/scandipwa-guidelines/derived-class-names */
/* eslint-disable no-console */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/prop-types */
/* eslint-disable consistent-return */
/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable @scandipwa/scandipwa-guidelines/create-config-files */
/* eslint-disable no-unused-vars */

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
import { faLinkedinIn, faXTwitter } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import isMobile from '@scandipwa/scandipwa/src/util/Mobile';
import sha256 from 'js-sha256';
import {
    divide, get, isArray, isEmpty, map
} from 'lodash';
import postscribe from 'postscribe';
import PropTypes from 'prop-types';
import {
    cloneElement,
    PureComponent
} from 'react';
// import { GoogleReCaptcha } from 'react-google-recaptcha-v3';
// import LazyLoad from 'react-lazy-load';
import Popup from 'reactjs-popup';
// import { AST_Null } from 'terser';
import Cookies from 'universal-cookie';

import AddToCartPopUp from 'Component/AddToCartPopUp';
// import CookieConsent from "react-cookie-consent";
import CloseIcon from 'Component/CloseIcon';
import Field from 'Component/Field';
import Form from 'Component/Form';
import { WithGoogleRecaptcha } from 'Component/GoogleRecaptchaV3/GoogleRecaptchaV3.component';
import HTML from 'Component/Html';
import Image from 'Component/Image';
import InstagramFeeds from 'Component/InstagramFeeds/InstagramFeeds';
import Link from 'Component/Link';
import Loader from 'Component/Loader';
import NotificationList from 'Component/NotificationList';
import {
    BEFORE_ITEMS_TYPE
} from 'Component/Router/Router.config';
import Html from 'SourceComponent/Html';
import { showNotification } from 'Store/Notification/Notification.action';
// import { isSignedIn } from 'Util/Auth';
import { isEurStore, isIntStore, isUsaStore } from 'Util/FrontRunner/Store';
import { getSubscriberDrivePromo } from 'Util/FrontRunner/Store/Promotions';
import { isB2BUser, removeBaseName, validateEmail } from 'Util/Helper';
import { history } from 'Util/History';
import { removeBaseUrl } from 'Util/Url';

import { isAusStore } from '../../util/FrontRunner/Store';
import { getYotpoApiKey } from '../YotpoLoyalty/YotpoLoyalty.config';

import './Footer.style';

export const cookies = new Cookies();

/**
 * Page footer
 * @class Footer
 * @namespace Pwa/Component/Footer/Component/FooterComponent */
export class Footer extends PureComponent {
    static propTypes = {
        freeShippingFooterText: PropTypes.any,
        free_shipping_footer_text_b2b: PropTypes.any,
        is_free_shipping_enable: PropTypes.any,
        copyright: PropTypes.string,
        data: PropTypes.object,
        headerNotifications: PropTypes.any,
        btnText: PropTypes.any
    };

    static defaultProps = {
        copyright: '',
        data: {}
    };

    state = {
        email: '',
        firstname: '',
        lastname: '',
        is_subscribed: false,
        is_subs_consent: false,
        subscrptionPopup: true,
        reset: true,
        btnText: null,
        is_visible: false

    };

    componentDidMount() {
        const scrollComponent = this;
        document.addEventListener('scroll', (e) => {
            scrollComponent.toggleVisibility();
        });

        // <WithGoogleRecaptcha />;
    }

    renderTrustBadge= () => <div id="FRCustomTrustbadge" />;

    renderWarrantyBlock = (warrantyBlock) => map(warrantyBlock, (wB, key) => (
      <div className="fr_g_footer_block" key={ key }>
        <div className="h2">{ wB.text1 }</div>
        <div className={ `fr_text_lg fr_text_color_${key + 1}` }>
            { key === 2 && (
                <Link to="/warranty" onClick={ () => this.moveToTop() }>
                { wB.text2 }
                </Link>
            ) }
            { key !== 2 && wB.text2 }
        </div>
        <div className="fr_text_muted">{ wB.text3 }</div>
      </div>
    ));

    subscriptionCookies= () => {
        const subsCookieName = `${window.storeConfig.baseName }subsciption_key`;

        return subsCookieName;
    };

    subscriptionCookiesB2b= () => {
        const subsCookieName = `${window.storeConfig.baseName }subsciptionb2b_key`;

        return subsCookieName;
    };

    noticeCookies= () => {
        const noticeCookieName = `${window.storeConfig.baseName }notice_key`;

        return noticeCookieName;
    };

    renderWarrantyRow = () => {
        const { data } = this.props;
        const logo = get(data, 'warranty[0].logo', null);
        const warrantyBlock = get(data, 'warranty[0].warranty_block', null);

        return (
        <div className="fr_f_row1 row">
          { logo && <Image src={ logo } alt="Front Runner Dometic | Off-Road Tough Roof Racks &amp; Vehicle Adventure Gear" loading="lazy" /> }
          { warrantyBlock && this.renderWarrantyBlock(warrantyBlock) }
        </div>
        );
    };

    renderAusAcknowledgementBlock = () => {
        if (!isAusStore()) {
            return null;
        }

        return (
          <div className="fr_f_row2">
            <div className="aus-acknowledgement">
              Front Runner Dometic | Dometic acknowledges the Traditional Custodians of Country throughout Australia and recognises their continuing connection to land, waters and communities. We pay our respect to Aboriginal and Torres Strait Islander cultures; and to Elders past and present.
            </div>
          </div>
        );
    };

    renderStoreDetails = () => {
        const { data } = this.props;
        if (isEmpty(data)) {
            return <></>;
        }

        const {
            label1, label2, label3, store_hours = ''
        } = get(data, 'store_details[0]', {});
        const helpLink = removeBaseUrl(get(data, 'store_details[0].help_link[0].link', ''));
        const helpLabel = get(data, 'store_details[0].help_link[0].label');
        const supportLabel = get(data, 'store_details[0].support[0].label');
        const supportPhone = get(data, 'store_details[0].support[0].phone');
        const supportLabel1 = get(data, 'store_details[0].support[1].label');
        const supportPhone1 = get(data, 'store_details[0].support[1].phone');
        const supportLabel2 = get(data, 'store_details[0].support[2].label');
        const supportPhone2 = get(data, 'store_details[0].support[2].phone');

        const supportPhoneTel = {
            supportPhone,
            supportPhone1,
            supportPhone2
        };

        // Remove spaces, dashes and remove (0) from phone numbers in supportPhoneTel
        Object.keys(supportPhoneTel).forEach((key) => {
            if (supportPhoneTel[key]) {
                supportPhoneTel[key] = supportPhoneTel[key].replace(/\s/g, '').replace(/-/g, '').replace('(0)', '');
            }
        });

        return (
        <div className="fr_f_row2 row">
          { label1 && (
            <div className="fr_text_x-lg">
              { ' ' }
              { label1 }
              { ' ' }
            </div>
          ) }
          <div className="help_wrap">
            { label2
              && (
                <div className="h2 vis_help">
                { label2 }
                  &nbsp;
                  &nbsp;
                  24/7
                  &nbsp;
                  &nbsp;
                { helpLink && (
                  <Link
                    block="footer"
                    elem="btn btn btn-sm btn-primary"
                    to={ helpLink }
                    rel="nofollow noopener noreferrer"
                    target="_blank"
                  >
                    { helpLabel }
                  </Link>
                ) }
                </div>
              ) }
            <div className="talk_sp_wrap">
              { label3 && (
                  <div className="h2 talk_sp">
                  { label3 }
                  { ' ' }
                  </div>
              ) }
              { store_hours
                && (
                  <div className="h2 talk_sp">
                      <HTML content={ store_hours } />
                  </div>
                ) }
              { /* {supportLabel && <div className="h2 talk_sp"> {supportLabel} </div>} */ }
              { supportPhone && !supportLabel && (
                <div className="h2 talk_sp">
                  { ' ' }
                  { supportPhone }
                  { ' ' }
                </div>
              ) }
              <div className="h2 talk_sp ph_num">
                { supportLabel && supportPhone && (
                    <a
                      block="footer"
                      elem="btn btn btn-primary"
                      href={ `tel:${supportPhoneTel?.supportPhone ?? supportPhone}` }
                      rel="nofollow noopener noreferrer"
                    >
                      { supportLabel }
                      { (': ') }
                      { supportPhone }
                    </a>
                ) }
                { supportLabel1 && supportPhone1 && (
                  <a
                    block="footer"
                    elem="btn btn btn-primary"
                    href={ `tel:${supportPhoneTel?.supportPhone1 ?? supportPhone1}` }
                    rel="nofollow noopener noreferrer"
                  >
                      { supportLabel1 }
                      { (': ') }
                      { supportPhone1 }
                  </a>
                ) }
                { supportLabel2 && supportPhone2 && (
                  <a
                    block="footer"
                    elem="btn btn btn-primary"
                    href={ `tel:${supportPhoneTel?.supportPhone2 ?? supportPhone2}` }
                    rel="nofollow noopener noreferrer"
                  >
                      { supportLabel2 }
                      { (': ') }
                      { supportPhone2 }
                  </a>
                ) }
              </div>
            </div>
          </div>

          { (window.storeConfig.code === 'de_de' || window.storeConfig.code === 'en_de') && (
            <div id="FRCustomTrustbadgeMobile" />
          ) }
        </div>
        );
    };

    renderFooterInfo = (footerInfo) => (
        <div className="footer-info">
          { map(footerInfo, (fI, key) => (
            <p key={ key }>
              { fI.heading_label }
              { '*' }
              {' '}
              <span className="footer-info-text"><Html content={ fI.subheading_label } /></span>
            </p>
          )) }
        </div>
    );

    renderShipContent() {
        const { is_free_shipping_enable, freeShippingFooterText = {}, free_shipping_footer_text_b2b = {} } = this.props;
        const { customer = {} } = this.props;
        const { is_b2b } = customer;
        if (isEmpty(free_shipping_footer_text_b2b)) {
            return null;
        }
        if (is_free_shipping_enable === '1' && (isEmpty(is_b2b) || is_b2b == 'false' || is_b2b == false)) {
            return (
                <>
                  { freeShippingFooterText }
                </>
            );
        }
        if (is_free_shipping_enable === '1' && is_b2b) {
            return (
                <>
                { free_shipping_footer_text_b2b }
                </>
            );
        }

        return null;
    }

    renderOurStoryBlock = (ourStoryBlock) => {
        const { label, story, footer_info: footerInfo } = ourStoryBlock;
        return (
        <div className="our-story fr_footer_story">
            { label && (
                <h2>
                    { label }
                </h2>
            ) }
            { story && (
            <p>
                { story }
                <Link
                  className="more fr_text_color_1"
                  onClick={ () => this.moveToTop() }
                  to="/about-us"
                >
                    { ' ' }
                    { __('More') }
                    { ' ' }
                    +
                </Link>
            </p>
            ) }
            <div className="h2">
                { this.renderShipContent() }
            </div>
            { footerInfo && this.renderFooterInfo(footerInfo) }

            { (window.storeConfig.code === 'de_de' || window.storeConfig.code === 'en_de') && (
                <div id="FRCustomTrustbadge" />
            ) }
        </div>
        );
    };

    renderFooterLinkRows = (footerLinkRows) => (
        <div className="fr_list">
          { map(footerLinkRows, ({ label, link }, key) => this.renderLink(label, link, key)) }
        </div>
    );

    handleLinkClick= (link) => {
        const link_url = `/${ link}`;
        history.push(link_url);
    };

    renderLink = (label = '', link = '', key = 1, title = '') => {
        if (label === 'Videos' || link.startsWith('http')) {
            return (
            <Link
              key={ key }
              block="footer"
              elem="link"
              to={ link }
              rel="noopener noreferrer"
              target="_blank"
              title={title}
            >
                  { label }
            </Link>
            );
        }

        return (
          <Link
            key={ key }
            block="footer"
            elem="link"
            to={ `/${link}` }
            onClick={ () => this.moveToTop() }
            title={title}
          >
              { label }
          </Link>
        );
    };

    renderLinks = (label = '', link = '', key = 1, title = '') => (
        isEmpty(link) ? label
            : (
              <Link
                key={ key }
                block="footer"
                elem="link"
                to={ removeBaseUrl(link) }
                rel="nofollow noopener noreferrer"
                target="_blank"
                title={ title }
              >
                  { label }
              </Link>
            )
    );

    renderFooterLinks = (footerLinks) => (
        <div className="footer-links ff_f1">
          { map(footerLinks, (fL, key) => {
              const { label, link, footer_links_rows: footerLinkRows } = fL;
              if (key === 0 || key === 1) {
                  return (
                  <div key={ key } className="links_wrp">
                    <div className="h2">
                      { label }
                    </div>
                    { footerLinkRows && this.renderFooterLinkRows(footerLinkRows) }
                  </div>
                  );
              }

              return null;
          }) }
        </div>
    );

    renderFooterLinksVeh = (footerLinks) => (
        <div className="footer-links ff_f2">
          { map(footerLinks, (fL, key) => {
              const { label, link, footer_links_rows: footerLinkRows } = fL;
              if (key === 2) {
                  return (
                  <div key={ key } className="links_wrp">
                    <div className="h2">
                      { label }
                    </div>
                    { footerLinkRows && this.renderFooterLinkRows(footerLinkRows) }
                  </div>
                  );
              }
              if (key === 3) {
                  return (
                  <div key={ key } className="links_wrp">
                    <div className="h2">
                      { this.renderLink(label, link, key) }
                    </div>
                    { footerLinkRows && this.renderFooterLinkRows(footerLinkRows) }
                  </div>
                  );
              }

              return null;
          }) }
        </div>
    );

    renderFacebookPostBlock = (facebookPostBlock) => {
        const { label, link, facebook_feeds: facebookFeeds } = facebookPostBlock;
        const followUsLabel = 'Follow us on Facebook'; // this should come from graphql
        const followUsLink = 'https://www.facebook.com/FrontRunnerOutfitters';
        return (
        <div className="facebookpost-block">
          <div className="h2">{ this.renderLink(label, link) }</div>
          <p>{ facebookFeeds && this.renderFacebookFeeds(facebookFeeds) }</p>
          { this.renderLink(followUsLabel, followUsLink) }
        </div>
        );
    };

    renderFAIcon = (icon, key) => {
        const {
            title, link, icon_name, prefix
        } = icon;
        const iconPrefix = isEmpty(prefix) ? 'fab' : prefix;
        const iconHtml = <FontAwesomeIcon icon={ [iconPrefix, icon_name] } />;

        return (
        <div key={ key } className={ `${iconPrefix} ${icon_name}` } title={ title }>
          { /* { isEmpty(link) ? iconHtml : this.renderLink(iconHtml, link, key) } */ }
          { iconHtml }
        </div>
        );
    };

   renderFAIcons = (icon, key) => {
       const {
           title, link, icon_name, prefix
       } = icon;
       const iconPrefix = isEmpty(prefix) ? 'fab' : prefix;
       const iconHtml = <FontAwesomeIcon icon={ [iconPrefix, icon_name] } />;

       if (icon_name == 'twitter') {
           return (
          <div key={ key } className={ `${ iconPrefix } ${ icon_name }` }>
             { this.renderLink(<FontAwesomeIcon icon={ faXTwitter } />, link, key, title) }
          </div>
           );
       }

       if (icon_name == 'linkedin') {
           return (
            <div key={ key } className={ `${ iconPrefix } ${ icon_name }` }>
                { this.renderLink(<FontAwesomeIcon icon={ faLinkedinIn } />, link, key, title) }
            </div>
           );
       }

       return (
          <div key={ key } className={ `${ iconPrefix } ${ icon_name }` }>
            { isEmpty(link) ? iconHtml : this.renderLinks(iconHtml, link, key, title) }
          </div>
       );
   };

    renderSocialIconBlock = (socialIconBlock) => {
        const { label, icons } = socialIconBlock;
        return (
        <div className="social-icons">
          { !isEmpty(label) && (
          <div className="title">
            { ' ' }
            { label }
            :
            { ' ' }
          </div>
          ) }
          <div className="icons">
            { map(icons, this.renderFAIcons) }
          </div>
        </div>
        );
    };

    renderAddressLines = ({ line }, key) => (
        <div key={ key } className={ `address-line-${key + 1}` }>
          { line }
        </div>
    );

    renderAddressBlock = (addressBlock) => {
        const { label, address_line: addressLine } = addressBlock;
        return (
        <div className="address">
          { !isEmpty(label) && (
            <div className="title">
              { __('Address') }
              :
              { ' ' }
            </div>
          ) }
          { addressLine && (
            <div className="address-lines">
              { ' ' }
              { map(addressLine, this.renderAddressLines) }
            </div>
          ) }
        </div>
        );
    };

    captchaNotification() {
        const {
            showNotification
        } = this.props;

        showNotification(
            'info',
            __('Incorrect Captcha. Please try again after sometime.')
        );
    }

    renderNewsLetterBlock = () => {
        const { email } = this.state;
        const { isNewsletterLoading, onSubmitSuccess = () => {}, googleRecaptchaToken } = this.props;
        // const { title } = data;
        return (
          <div className="social_newsletter_cont" id="footer_newsletter">
            <Loader isLoading={ isNewsletterLoading } />
            <div className="title">
              { __('Sign up for our newsletter:') }
            </div>
            <div>
              <Form
                key="email-friend"
                mix={ { block: 'EmailFriend' } }
                onSubmit={ googleRecaptchaToken === 'true' ? this.newsLetterSignup : this.captchaNotification.bind(this) }
                onSubmitSuccess={ googleRecaptchaToken === 'true' ? onSubmitSuccess : this.captchaNotification.bind(this) }
                // onSubmitError={ onError }
              >
                <Field
                  type="email"
                  placeholder={ __('Your Email Address') }
                  id="email"
                  name="email"
                  // validation={ ['notEmpty', 'email'] }
                  value={ email }
                  onChange={ this.handleEmailChange }
                />
                <button className="Button">
                  { __('Sign up') }
                </button>
                <WithGoogleRecaptcha updateGoogleRecaptcha={ this.props.updateGoogleRecaptcha } />
              </Form>
            </div>
          </div>
        );
    };

    renderConnectBlock = () => {
        const { data } = this.props;
        const connectBlock = get(data, 'connect[0]');

        const socialIconBlock = get(connectBlock, 'social[0]', {});
        const addressBlock = get(connectBlock, 'address[0]', {});
        // const newsletterBlock = get('newsletter_block[0]', {});
        return (
        <div className="fr_f_row4 row">
          { socialIconBlock && this.renderSocialIconBlock(socialIconBlock) }
          { this.renderNewsLetterBlock() }
          { !isUsaStore() && addressBlock && this.renderAddressBlock(addressBlock) }
        </div>
        );
    };

    toggleVisibility() {
        if (window.pageYOffset > window.innerHeight && !this.setState.is_visible) {
            this.setState({
                is_visible: true
            });
        } else {
            this.setState({
                is_visible: false
            });
        }
    }

    renderBackToTop = () => {
        if (isMobile.any()) {
            return null;
        }
        const { is_visible } = this.state;
        return (
            <>
            { is_visible && (
              <button className="btn btt-button-top" onClick={ () => this.moveToTop() }>
                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="chevron-up" className="svg-inline--fa fa-chevron-up fa-w-14" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M240.971 130.524l194.343 194.343c9.373 9.373 9.373 24.569 0 33.941l-22.667 22.667c-9.357 9.357-24.522 9.375-33.901.04L224 227.495 69.255 381.516c-9.379 9.335-24.544 9.317-33.901-.04l-22.667-22.667c-9.373-9.373-9.373-24.569 0-33.941L207.03 130.525c9.372-9.373 24.568-9.373 33.941-.001z" /></svg>
                { __('Back To Top') }
              </button>
            ) }
            </>
        );
    };

    moveToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    renderFooterBlock = () => {
        const { data } = this.props;
        const ourStoryBlock = get(data, 'footer_block[0].our_story[0]');
        const footerLinks = get(data, 'footer_block[0].footer_links');
        const facebookPostBlock = get(data, 'footer_block[0].facebook_post[0]');

        return (
          <div className="fr_f_row3 row">
            { ourStoryBlock && this.renderOurStoryBlock(ourStoryBlock) }
            { footerLinks && this.renderFooterLinks(footerLinks) }
            { footerLinks && this.renderFooterLinksVeh(footerLinks) }
            { /* { this.renderInstagramFeeds() } */ }
          </div>
        );
    };

    renderInstagramFeeds = () => (
        <InstagramFeeds />
    );

    renderFooterCopyRightBlock = () => {
        const { data } = this.props;
        if (isEmpty(data)) {
            return <></>;
        }
        const footerCopyRight = get(data, 'footer_copyright[0]', {});
        const { label = '', links, icons } = footerCopyRight;

        return (
        <div className="fr_f_row5 row">
          <div className="links_label">
            { isArray(links) && (
              <div className="links">
                { map(links, ({ label, link }, key) => this.renderLink(label, link, key)) }
              </div>
            ) }
            <div className="label">
              <HTML content={ label } />
            </div>
          </div>
          { isArray(icons) && (
            <div className="icons">
              { map(icons, this.renderFAIcon) }
            </div>
          ) }
        </div>
        );
    };

    renderPlaceHolder = () => (
        <>
          <div block="Footer" elem="SectionPlaceholder" />
          <div block="Footer" elem="SectionPlaceholder" />
          <div block="Footer" elem="SectionPlaceholder" />
        </>
    );

    // renderCookieConsent = () => {
    //   return (
    //     <CookieConsent
    //       // debug={true} // Uncomment this line to ensure that ookie consent is always visible.
    //       location="bottom"
    //       cookieName="consent"
    //       style={{ background: "#2B373B" }}
    //       buttonText={"Close"}
    //       buttonStyle={{ color: "#fff", fontSize: "13px", background: "#dd3526" }}
    //       expires={150}
    //     >
    //       {__("We use cookies on this website to improve your shopping experience. We use cookies to remember log-in details and provide secure log-in, collect statistics to optimise site functionality, and deliver content tailored to your interests. Click accept to give your consent to accept cookies and go directly to the site or click on more information to see detailed descriptions of the types of cookies we store.")}
    //       <Link
    //         block="footer"
    //         elem="btn"
    //         to={"/privacy-policy"}
    //         >
    //         { __("Click here to learn more.") }
    //       </Link>
    //     </CookieConsent>
    //   )
    // }

    handleEmailChange = (email) => {
        this.setState({ email });
    };

    handleFnameChange= (firstname) => {
        this.setState({ firstname });
    };

    handleLnameChange= (lastname) => {
        this.setState({ lastname });
    };

    newsLetterSignup = () => {
        const { email, firstname = '', lasttname = '' } = this.state;
        const { newsletterSubscribe, showErrorNotification } = this.props;
        if (isEmpty(email)) {
            showErrorNotification(__('Invalid Email Address'));
        } else {
            newsletterSubscribe({ email, firstname, lasttname });
        }
    };

    newsLetterSignupPopup = () => {
        this.setState({ showValidationError: '' });
        const { email, firstname = '', lasttname = '' } = this.state;
        const { newsletterSubscribePopup } = this.props;

        if (isEmpty(email)) {
            showNotification(__('Invalid Email Address'));
            return;
        }

        if (email && !validateEmail(email)) {
            showNotification(__('Invalid Email Address'));
            return;
        }

        this.setState({ reset: true, btnText: null }, () => {
            newsletterSubscribePopup({ email, firstname, lasttname });
        });
    };

    closeSubscriptionModal = () => {
        const { newLetterConfig: { show_limit = 1, show_again_after = 1 } } = this.props;
        if (show_limit >= 1) {
            const now = new Date();
            now.setTime(now.getTime() + show_again_after * 24 * 3600 * 1000);
            const cname = this.subscriptionCookies();
            cookies.set(cname, 'old', { path: '/', expires: now });
        }
        this.setState({ subscrptionPopup: false });
    };

    closeB2bModal = () => {
        const { newLetterConfig: { show_limit = 1, show_again_after = 1 } } = this.props;
        if (show_limit >= 1) {
            const now = new Date();
            now.setTime(now.getTime() + show_again_after * 24 * 3600 * 1000);
            const cname = this.subscriptionCookiesB2b();
            cookies.set(cname, 'old', { path: '/', expires: now });
        }
        this.setState({ popupMode: false });
    };

    closeNoticeView=() => {
        const now = new Date();
        now.setTime(now.getTime() + 7 * 24 * 3600 * 1000);
        const cname = this.noticeCookies();
        cookies.set(cname, 'pre', { path: '/', expires: now });
        this.setState({ noticeView: false });
    };

    handleSelectSubsConsentChange=() => {
        const { is_subs_consent = false } = this.state;
        const newval = !!(is_subs_consent);
        this.setState({ is_subs_consent: newval });
    };

    addBase = (src) => `https://www.frontrunneroutfitters.com/${src}`;

    reset =(submit_text) => {
        setTimeout(() => {
            this.setState({
                reset: false,
                email: '',
                firstname: '',
                lasttname: '',
                is_subs_consent: false,
                btnText: submit_text
            });
        }, 15000);
    };

    renderItemsOfType(type) {
        return this.getSortedItems(type)
            .map(({ position, component }) => cloneElement(component, { key: position }));
    }

    getSortedItems(type) {
        return this[type].sort(
            (a, b) => a.position - b.position
        ).filter(
            (entry) => {
                if (!entry.component) {
                    // eslint-disable-next-line no-console
                    console.warn('There is an item without a component property declared in main router.');
                    return false;
                }

                return true;
            }
        );
    }

    [BEFORE_ITEMS_TYPE] = [
        {
            component: <NotificationList />,
            position: 10
        }
    ];

    // Popup after a B2B logs
    renderB2BPopup = () => {
        if (!isEurStore(true)) {
            return null;
        }

        const visit = this.getCookieB2b();
        let popupMode = true;
        if (visit === 'old') {
            popupMode = false;
        }
        const { customer = {} } = this.props;
        const { is_b2b } = customer;

        // Check if B2B customer (Use local storage check)
        const isB2Bsession = JSON.parse(sessionStorage.getItem('is_b2b')) ?? false;

        // show popups for only B2B
        if (isB2BUser(is_b2b) || isB2Bsession) {
            return (
              <Popup
                open={ popupMode }
                  // open
                className="b2b_Popup_wrap"
                position="right center"
                closeOnDocumentClick={ false }
                closeOnEscape={ false }
              >
                  <div className="cross_subs_popup">
                      <button
                        key="close"
                        block="Header"
                        elem="Button"
                        mods={ { type: 'close' } }
                        onClick={ this.closeB2bModal }
                        aria-label="Close"
                      >
                          <CloseIcon />
                      </button>
                  </div>
                  <div className="PopUpWrap">
                      { this.renderB2BPopupContent() }
                  </div>
              </Popup>
            );
        }

        return null;
    };

    renderB2BPopupContent() {
        const storeCode = window?.storeConfig?.code ?? null;
        if (!storeCode) {
            return null;
        }

        const bankDetails = {
            en_de: {
                header: 'IMPORTANT NOTICE: NEW COMPANY NAME',
                subheader: 'For future bank transfers, please note the changed company name:',
                bank_name: 'BNP Paribas S.A.',
                bank_account: 'Account Number: 4223680010 EUR',
                bank_iban: 'IBAN: DE95 5121 0600 4223 6800 10',
                bank_swift: 'SWIFT: BNPADEFFXXX',
                footer: 'For invoicing, please use the following address from now on:<br><br><strong>Dometic Vehicle Outfitters EU GmbH</strong><br>Zu den Mergelbrüchen 4<br>30559 Hanover<br><br>If you have any questions, please do not hesitate to contact your contact person from the Sales Team.'
            },
            en_uk: {
                header: 'IMPORTANT NOTICE: NEW COMPANY NAME',
                subheader: 'For future bank transfers, please note the changed company name:',
                bank_name: 'BNP Paribas S.A.',
                bank_account: 'Account Number: 4223680317',
                bank_iban: 'IBAN: DE95 5121 0600 4223 6803 17',
                bank_swift: 'SWIFT: BNPADEFFXXX',
                footer: 'For invoicing, please use the following address from now on:<br><br><strong>Dometic Vehicle Outfitters EU GmbH</strong><br>Zu den Mergelbrüchen 4<br>30559 Hanover<br><br>If you have any questions, please do not hesitate to contact your contact person from the Sales Team.'
            },
            de_de: {
                header: 'WICHTIGER HINWEIS: NEUE FIRMIERUNG',
                subheader: 'Für künftige Überweisungen beachten Sie bitte den geänderten Firmennamen:',
                bank_name: 'BNP Paribas S.A.',
                bank_account: 'Kontonummer: 4223680010 EUR',
                bank_iban: 'IBAN: DE95 5121 0600 4223 6800 10',
                bank_swift: 'SWIFT: BNPADEFFXXX ',
                footer: 'Für die Rechnungsstellung nutzen Sie bitte ab sofort folgende Adresse:<br><br><strong>Dometic Vehicle Outfitters EU GmbH</strong><br>Zu den Mergelbrüchen 4<br>30559 Hanover<br><br>Sollten Sie Fragen haben, kontaktieren Sie gerne Ihren Ansprechpartner aus dem Sales Team.'
            },
            fr_be: {
                header: 'AVIS IMPORTANT : NOUVELLE DÉNOMINATION SOCIALE',
                subheader: 'Pour les futurs virements bancaires, veuillez noter le changement de nom de la société :',
                bank_name: 'BNP Paribas S.A.',
                bank_account: 'Numéro de compte : 4223680010 EUR',
                bank_iban: 'IBAN : DE95 5121 0600 4223 6800 10',
                bank_swift: 'SWIFT : BNPADEFFXXX ',
                footer: 'Pour la facturation, veuillez désormais utiliser l\'adresse suivante :<br><br><strong>Dometic Vehicle Outfitters EU GmbH</strong><br>Zu den Mergelbrüchen 4<br>30559 Hanover<br><br>Si vous avez des questions, n\'hésitez pas à contacter votre représentant de l\'équipe de vente.'
            },
            nl_be: {
                header: 'BELANGRIJKE MEDEDELING: NIEUWE BEDRIJFSNAAM',
                subheader: 'Let bij toekomstige bankoverschrijvingen op de gewijzigde bedrijfsnaam:',
                bank_name: 'BNP Paribas S.A.',
                bank_account: 'Rekeningnummer: 4223680010 EUR',
                bank_iban: 'IBAN: DE95 5121 0600 4223 6800 10',
                bank_swift: 'SWIFT: BNPADEFFXXX ',
                footer: 'Gebruik voor facturatie voortaan het volgende adres:<br><br><strong>Dometic Vehicle Outfitters EU GmbH</strong><br>Zu den Mergelbrüchen 4<br>30559 Hanover<br><br>Voor vragen kunt u altijd contact opnemen met uw contactpersoon van het Sales Team.'
            },
            es_be: {
                header: 'AVISO IMPORTANTE: NUEVO NOMBRE DE EMPRESA',
                subheader: 'Para futuras transferencias bancarias, tenga en cuenta el cambio de nombre de la empresa:',
                bank_name: 'BNP Paribas S.A.',
                bank_account: 'Número de cuenta: 4223680010 EUR',
                bank_iban: 'IBAN: DE95 5121 0600 4223 6800 10',
                bank_swift: 'SWIFT: BNPADEFFXXX ',
                footer: 'Para la facturación, por favor utilice la siguiente dirección a partir de ahora:<br><br><strong>Dometic Vehicle Outfitters EU GmbH</strong><br>Zu den Mergelbrüchen 4<br>30559 Hanover<br><br>Si tiene alguna pregunta, no dude en ponerse en contacto con su persona de contacto del equipo de ventas.'
            }
        };

        // Check if storeCode exists in bankDetails
        if (!bankDetails[storeCode]) {
            return null;
        }

        const {
            header,
            subheader,
            bank_name,
            bank_account,
            bank_iban,
            bank_swift,
            footer
        } = bankDetails[storeCode];

        return (
            <div className="b2b_Popup_content">
                <h2>{ header }</h2>
                <p><HTML content={ subheader } /></p>
                <ul>
                    <li>{ bank_name }</li>
                    <li><strong>Dometic Vehicle Outfitters EU GmbH</strong></li>
                    <li>{ bank_account }</li>
                    <li>{ bank_iban }</li>
                    <li>{ bank_swift }</li>
                </ul>
                <HTML content={ footer } />
            </div>
        );
    }

    // Subscriber Drive Promotion
    renderSubscriberDrivePromoPopup = (subscriberDrivePromo) => {
        if (isMobile.any()) {
            return null;
        }

        const {
            btnText, reset, email, firstname, lastname, is_subscribed = false, subscrptionPopup = true
        } = this.state;

        const {
            subscriptionResponse: { data }, isNewsletterLoading, newLetterConfig: {
                active, heading_text, message_text, ask_firstname, ask_lastname, ask_consent, consent_text, submit_text, can_close, popup_delay, show_again_after, show_limit, background_image, terms_url
            },
            // showValidationError1 = 'noError'
            showValidationError = 'noError',
            googleRecaptchaToken
        } = this.props;
        let message;
        let popupMode = subscrptionPopup;
        if (data) {
            message = data.message;
            // popupMode=false;
        } else {
            message = null;
        }

        if (!(active)) {
            return (null);
        }

        const visit = this.getCookie();
        if (message) {
            // this.closeSubscriptionModal();
        }
        if (visit === 'old') {
            popupMode = false;
        }

        const text_message = message_text;
        // const text_consent = this.stripHtml(consent_text);
        const text_consent = consent_text;
        const popup_bg_image = this.addBase(background_image);

        if (!active) {
            return null;
        }

        let submittxt = isEmpty(btnText) ? submit_text : btnText;

        if (message && reset) {
            // this.closeSubscriptionModal();
            submittxt = message;
        }

        if (reset && message != null) {
            this.reset(submit_text);
        }
        const isVisible = true;
        const {
            onCreateAccountAttempt, onCreateAccountSuccess, isLoadingMember, showNotification = () => {}
        } = this.props;

        const storeCode = window?.storeConfig?.code ?? null;
        const storeClass = `fr-${storeCode}`;

        return (
          <Popup
            open={ popupMode }
            // open
            className="newsletter_wrap"
            // onClose={ this.closeSubscriptionModal }
            position="right center"
            closeOnDocumentClick={ false }
            closeOnEscape={ false }
          >
              <div
                className="social_popup_wrapper"
                style={ {
                    backgroundImage: `url(${subscriberDrivePromo.popupImage})`
                } }
              >
                  <div className={ `FrontRunnerMember-Popup ${storeClass}` }>
                      { this.renderItemsOfType(BEFORE_ITEMS_TYPE) }
                      <Loader isLoading={ isLoadingMember } />
                      <div className="cross_subs_popup">
                          <button
                            key="close"
                            block="Header"
                            elem="Button"
                            mods={ { type: 'close', isVisible } }
                            onClick={ this.closeSubscriptionModal }
                            aria-label="Close"
                          >
                              <CloseIcon />
                          </button>
                      </div>
                      <h2>
                          <Html content={ subscriberDrivePromo.popupHeaderText } />
                      </h2>
                      <p className="fr-email-text-header">
                          <Html content={ subscriberDrivePromo.popupBodyText } />
                      </p>

                      { /* <Form
                        key="frontrunner-member"
                        mix={ { block: `FrontRunnerMember-Popup ${ showValidationError1}` } }
                        onSubmit={ onCreateAccountAttempt }
                        onSubmitSuccess={ onCreateAccountSuccess }
                        onSubmitError={ onCreateAccountAttempt }
                      >
                          <div className="FrontRunnerMember-Popup-Wrapper">
                              <Field
                                type="text"
                                placeholder={ __('Email Address') }
                                id="email"
                                name="email"
                                validation={ ['notEmpty', 'email'] }
                                value={ email }
                                onChange={ this.handleEmailChange }
                                style={ { flex: 1 } }
                              />
                          </div>
                          { (email.length > 5 && validateEmail(email)) && (
                              <>
                              <div className="FrontRunnerMember-Popup-Wrapper">
                                  <Field
                                    type="text"
                                    placeholder={ __('First Name') }
                                    id="firstname"
                                    name="firstname"
                                    validation={ ['notEmpty', 'text'] }
                                    value={ firstname }
                                    onChange={ this.handleFnameChange }
                                  />
                                  <Field
                                    type="text"
                                    placeholder={ __('Last Name') }
                                    id="lastname"
                                    name="lastname"
                                    validation={ ['notEmpty', 'text'] }
                                    value={ lastname }
                                    onChange={ this.handleLnameChange }
                                  />
                              </div>
                              <div className="FrontRunnerMember-Popup-Wrapper">
                                  <Field
                                    type="password"
                                    placeholder={ __('Password') }
                                    id="password"
                                    name="password"
                                    autocomplete="new-password"
                                    validation={ ['notEmpty', 'password'] }
                                  />
                              </div>
                              <div className="FrontRunnerMember-Popup-Wrapper">
                                  <Field
                                    type="password"
                                    placeholder={ __('Confirm password') }
                                    id="confirm_password"
                                    name="confirm_password"
                                    autocomplete="new-password"
                                    validation={ ['notEmpty', 'password', 'password_match'] }
                                  />
                              </div>
                              <div className="FrontRunnerMember-Popup-Wrapper">
                                  <Field
                                    id="is_subscribed"
                                    name="is_subscribed"
                                    type="checkbox"
                                    value={ is_subscribed }
                                    mix={ { block: 'text_consent', elem: 'text_consent-Checkbox' } }
                                    checked={ isEurStore() ? '' : 'checked' }
                                    label={ isEurStore() ? __('Subscribe to newsletter') : 'Subscribe to our newsletter for the latest products, offers and more!' }
                                    onChange={ this.handleSelectSubsConsentChange }
                                  />
                              </div>
                              <div className="FrontRunnerMember-Popup-Wrapper">
                                  <button type="submit">
                                      { __('Sign up') }
                                  </button>
                              </div>
                              <div className="FrontRunnerMember-Popup-Login">
                                  <Link
                                    block="FrontRunnerMember-Popup"
                                    elem="LoginLink"
                                    to="/account/login"
                                    onClick={ () => this.memberPopupSignupClose() }
                                  >
                                      { __('Already have an account?') }
                                      <span>
                                        { __('Sign in here') }
                                      </span>
                                  </Link>
                              </div>
                              </>
                          ) }
                      </Form> */ }

                      <Form
                        key="email-subscriber-drive"
                        mix={ { block: `EmailFriend ${ showValidationError}` } }
                        onSubmit={ googleRecaptchaToken === 'true' ? this.newsLetterSignupPopup : this.captchaNotification.bind(this) }
                      >
                        <div className="EmailFriend_wrap">
                          <Field
                            type="email"
                            placeholder={ __('Email Address') }
                            id="email"
                            name="email"
                            validation={ ['notEmpty', 'email'] }
                            value={ email }
                            onChange={ this.handleEmailChange }
                          />
                          { /* { (ask_firstname) && (
                              <Field
                                type="text"
                                placeholder={ __('First Name') }
                                id="firstname"
                                name="firstname"
                                validation={ ['notEmpty', 'text'] }
                                value={ firstname }
                                onChange={ this.handleFnameChange }
                              />
                          ) }

                          { (ask_lastname) && (
                              <Field
                                type="text"
                                placeholder={ __('Last Name') }
                                id="lasttname"
                                name="lasttname"
                                validation={ ['notEmpty', 'text'] }
                                value={ lasttname }
                                onChange={ this.handleLnameChange }
                              />
                          ) } */ }
                          { (ask_consent) && (
                              <button>
                                { submittxt }
                              </button>
                          ) }
                          { !(ask_consent) && (
                              <button className="btn btn-primary">
                                { submittxt }
                              </button>
                          ) }
                        </div>
                        { (ask_consent) && (
                          <div
                            block="subs_popup"
                            elem="subs_popup"
                          >
                            <Field
                              id="text_consent"
                              name="text_consent"
                              type="checkbox"
                              value={ is_subs_consent }
                              mix={ { block: 'text_consent', elem: 'text_consent-Checkbox' } }
                              checked={ is_subs_consent }
                              onChange={ this.handleSelectSubsConsentChange }
                            />

                                <label
                                  htmlFor="text_consent"
                                  block="subs_popup_consent"
                                  elem="Link"
                                >
                                    <Html content={ text_consent } />

                                </label>

                          </div>
                        ) }

                        <WithGoogleRecaptcha updateGoogleRecaptcha={ this.props.updateGoogleRecaptcha } />
                      </Form>

                      <p className="fr-email-text-color">
                          <Html content={ subscriberDrivePromo.popupBodyText2 } />
                      </p>

                      <div className="FrontRunnerMember-Popup-Actions">
                          <button className="FrontRunnerMember-Popup-SkipButton" onClick={ this.closeSubscriptionModal }>
                              { __('Skip for now') }
                              <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="arrow-right" className="svg-inline--fa fa-arrow-right fa-w-14" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M190.5 66.9l22.2-22.2c9.4-9.4 24.6-9.4 33.9 0L441 239c9.4 9.4 9.4 24.6 0 33.9L246.6 467.3c-9.4 9.4-24.6 9.4-33.9 0l-22.2-22.2c-9.5-9.5-9.3-25 .4-34.3L311.4 296H24c-13.3 0-24-10.7-24-24v-32c0-13.3 10.7-24 24-24h287.4L190.9 101.2c-9.8-9.3-10-24.8-.4-34.3z" /></svg>
                          </button>
                      </div>
                  </div>
              </div>
          </Popup>
        );
    };

    // memberOnCreateAccountSuccess = () => {
    //     const { onCreateAccountSuccess } = this.props;

    //     onCreateAccountSuccess.then(() => {
    //         this.memberPopupSignupClose();
    //     });
    // };

    memberPopupSignupClose = () => {
        this.closeSubscriptionModal();
        this.moveToTop();
    };

    renderSubscriptionPopup = () => {
        if (isMobile.any()) {
            return null;
        }

        const { customer = {}, googleRecaptchaToken } = this.props;
        const { is_b2b } = customer;
        const subscriberDrivePromo = getSubscriberDrivePromo();

        // Check if B2B customer (Use local storage check)
        const isB2Bsession = JSON.parse(sessionStorage.getItem('is_b2b')) ?? false;

        // Don't show newsletter popups for B2B
        if (isB2BUser(is_b2b) || isB2Bsession) {
            return null;
        }

        // Subscriber Drive Promotion
        // && !isSignedIn()
        if (subscriberDrivePromo.active) {
            return this.renderSubscriberDrivePromoPopup(subscriberDrivePromo);
        }

        const pathname = window.location.pathname.length;
        if (pathname > 7) {
            return null;
        }

        const {
            btnText, reset, email, firstname, lasttname, is_subs_consent = false, subscrptionPopup = true,
            showValidationError = 'noError'
        } = this.state;

        const {
            subscriptionResponse: { data }, isNewsletterLoading, newLetterConfig: {
                active, heading_text, message_text, ask_firstname, ask_lastname, ask_consent, consent_text, submit_text, can_close, popup_delay, show_again_after, show_limit, background_image, terms_url
            }
        } = this.props;
        let message;
        let popupMode = subscrptionPopup;
        if (data) {
            message = data.message;
            // popupMode=false;
        } else {
            message = null;
        }

        if (!(active)) {
            return null;
        }

        const visit = this.getCookie();
        if (message) {
            // this.closeSubscriptionModal();
        }
        if (visit === 'old') {
            popupMode = false;
        }

        const text_message = message_text;
        // const text_consent = this.stripHtml(consent_text);
        const text_consent = consent_text;
        const popup_bg_image = this.addBase(background_image);

        if (!active) {
            return null;
        }

        let submittxt = isEmpty(btnText) ? submit_text : btnText;

        if (message && reset) {
            // this.closeSubscriptionModal();
            submittxt = message;
        }

        if (reset && message != null) {
            this.reset(submit_text);
        }
        const isVisible = true;

        return (
            <Popup
              open={ popupMode }
              className="newsletter_wrap"
              // onClose={ this.closeSubscriptionModal }
              position="right center"
              closeOnDocumentClick={ false }
              closeOnEscape={ false }
            >
              <div className="Overlay_outer" onClick={ this.closeSubscriptionModal } />
              <div className="social_popup_wrapper" style={ { width: '100%', height: '100%', backgroundImage: `url(${popup_bg_image})` } }>
              <div className="social_newsletter_popup">

              <Loader isLoading={ isNewsletterLoading } />
                <div className="cross_subs_popup">
                    <button
                      key="close"
                      block="Header"
                      elem="Button"
                      mods={ { type: 'close', isVisible } }
                      onClick={ this.closeSubscriptionModal }
                      aria-label="Close"
                    >
                        <CloseIcon />
                    </button>
                </div>
              <h2>{ heading_text }</h2>
              <p className="fr-email-text-color"><Html content={ text_message } /></p>

              <Form
                key="email-friend"
                mix={ { block: `EmailFriend ${ showValidationError}` } }
                onSubmit={ googleRecaptchaToken === 'true' ? this.newsLetterSignupPopup : this.captchaNotification.bind(this) }
              >
                <div className="EmailFriend_wrap">
                  <Field
                    type="text"
                    placeholder={ __('Email Address') }
                    id="email"
                    name="email"
                    validation={ ['notEmpty', 'email'] }
                    value={ email }
                    onChange={ this.handleEmailChange }
                  />
                  { (ask_firstname) && (
                      <Field
                        type="text"
                        placeholder={ __('First Name') }
                        id="firstname"
                        name="firstname"
                        validation={ ['notEmpty', 'text'] }
                        value={ firstname }
                        onChange={ this.handleFnameChange }
                      />
                  ) }

                  { (ask_lastname) && (
                      <Field
                        type="text"
                        placeholder={ __('Last Name') }
                        id="lasttname"
                        name="lasttname"
                        validation={ ['notEmpty', 'text'] }
                        value={ lasttname }
                        onChange={ this.handleLnameChange }
                      />
                  ) }
                </div>
                 { (ask_consent) && (
                  <div
                    block="subs_popup"
                    elem="subs_popup"
                  >
                    <Field
                      id="text_consent"
                      name="text_consent"
                      type="checkbox"
                      value={ is_subs_consent }
                      mix={ { block: 'text_consent', elem: 'text_consent-Checkbox' } }
                      checked={ is_subs_consent }
                      onChange={ this.handleSelectSubsConsentChange }
                    />

                        <label
                          htmlFor="text_consent"
                          block="subs_popup_consent"
                          elem="Link"
                        >
                            <Html content={ text_consent } />

                        </label>

                  </div>
                 ) }
                { (ask_consent) && (
                    <button>
                      { submittxt }
                    </button>
                ) }
                { !(ask_consent) && (
                    <button className="fr-popup-btns btn">
                      { submittxt }
                    </button>
                ) }
                <WithGoogleRecaptcha updateGoogleRecaptcha={ this.props.updateGoogleRecaptcha } />
              </Form>

              <div className="subs_popup_once" onClick={ this.closeSubscriptionModal }>
                { __('Skip for now') }
                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="arrow-right" className="svg-inline--fa fa-arrow-right fa-w-14" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M190.5 66.9l22.2-22.2c9.4-9.4 24.6-9.4 33.9 0L441 239c9.4 9.4 9.4 24.6 0 33.9L246.6 467.3c-9.4 9.4-24.6 9.4-33.9 0l-22.2-22.2c-9.5-9.5-9.3-25 .4-34.3L311.4 296H24c-13.3 0-24-10.7-24-24v-32c0-13.3 10.7-24 24-24h287.4L190.9 101.2c-9.8-9.3-10-24.8-.4-34.3z" /></svg>
              </div>
              </div>
              </div>
            </Popup>
        );
    };

    renderNoticeText(text) {
        // Check if B2B customer (Use local storage check)
        const isB2B = JSON.parse(sessionStorage.getItem('is_b2b')) ?? false;

        if (!isB2B || isIntStore()) {
            return <Html content={ text } />;
        }

        // Deals page for B2B
        // TODO: Make dynamic in Magento
        return (
            <>
            Front Runner Dometic Deals
            <Link to="/deals/b2b">
                { __('Shop Now') }
            </Link>
            </>
        );
    }

    // renderNoticeRow=() => {
    //     const { isLoading, headerNotifications = {} } = this.props;
    //     const { enable = '0', text = null, buttontext = '' } = headerNotifications;
    //     const { noticeView = true } = this.state;
    //     console.log('44444 props footer', this.props);
    //     const visit = this.getNoticeCookie();
    //     if (visit === 'pre' || noticeView === false || text === null) {
    //         return null;
    //     }

    //     return (
    //         <div className="notice-row">
    //             <div className="notice-row-wrap">
    //             <label
    //               htmlFor="CheckoutBilling"
    //               block="CheckoutBilling"
    //               elem="Link"
    //             >
    //                 { this.renderNoticeText(text) }
    //             </label>
    //                 <button className="nt_btn" onClick={ () => this.closeNoticeView() }>{ buttontext }</button>
    //             </div>
    //         </div>
    //     );
    // };

    getCookie=() => {
        const c_name = this.subscriptionCookies();
        let c_start = cookies.get(c_name);
        if (c_start) {
            //
        } else {
            c_start = null;
        }

        return c_start;
    };

    getCookieB2b=() => {
        const c_name = this.subscriptionCookiesB2b();
        let c_start = cookies.get(c_name);
        if (c_start) {
            //
        } else {
            c_start = null;
        }

        return c_start;
    };

  getNoticeCookie=() => {
      const c_name = this.noticeCookies();
      let c_start = cookies.get(c_name);
      if (c_start) {
          //
      } else {
          c_start = null;
      }

      return c_start;
  };

  stripHtml(html) {
      // Create a new div element
      const temporalDivElement = document.createElement('div');
      // Set the HTML content with the providen
      temporalDivElement.innerHTML = html;
      // Retrieve the text property of the element (cross-browser support)
      return temporalDivElement.textContent || temporalDivElement.innerText || '';
  }

  renderYotpoUserIdentification() {
      const {
          customer
      } = this.props;

      const { email, id } = customer;

      const loggedInCustomer = JSON.parse(localStorage.getItem('customer', '{}'));
      const customerId = localStorage.getItem('id') || id || '';

      let loggedInUserEmail;

      if (loggedInCustomer && !isEmpty(loggedInCustomer)) {
          const { data = {} } = loggedInCustomer;

          const { email: loggedInUser } = data;

          loggedInUserEmail = loggedInUser || email || '';
      }

      const userYotpoToken = loggedInUserEmail + getYotpoApiKey();
      const userTokenHashValue = sha256(userYotpoToken);
      return (
        <div>
            <div
              id="swell-customer-identification"
              data-authenticated="true"
              data-email={ loggedInUserEmail }
              data-id={ customerId }
              data-tags=""
              data-token={ userTokenHashValue }
            />
        </div>
      );
  }

  render() {
      const { isLoading, headerNotifications = {} } = this.props;
      const { enable = '1' } = headerNotifications;
      const newLetterPopupConfig = get(this.props, 'newLetterConfig', {});
      const { maintenance_data = {} } = this.props;
      const { enabled = false } = maintenance_data;
      if (enabled) {
          return null;
      }

      // if(isLoading) return this.renderPlaceHolder()
      return (
        <footer block="Footer" aria-label="Footer" className="footer">
          {this.renderYotpoUserIdentification()}
          { isLoading && this.renderPlaceHolder() }
          <div id="footer_section" className="FooterSection">
          { !isLoading && (
              <div className="container">
                { this.renderWarrantyRow() }
                { this.renderAusAcknowledgementBlock() }
                { this.renderStoreDetails() }
                { this.renderFooterBlock() }
                { this.renderConnectBlock() }
                { this.renderFooterCopyRightBlock() }
                { this.renderBackToTop() }
                { /* this.renderCookieConsent() */ }
              </div>
          ) }
          </div>
          <AddToCartPopUp />
          { /* TODO: @Ajeet - Newsletter has some overlay on mobile and causing people to click buttons 2 or 3 times before working */ }
          { !isEmpty(newLetterPopupConfig) && this.renderSubscriptionPopup() }

          { /* { (enable === '1') && this.renderNoticeRow() } */ }
          {
            /*
            B2B popup was used to display change in bank account details, not required anymore
            { this.renderB2BPopup() }
            */
          }
        </footer>
      );
  }
}

export default Footer;
