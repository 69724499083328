/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-magic-numbers */
/* eslint-disable max-lines */
/* eslint-disable react/no-unused-state */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-curly-spacing */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable @scandipwa/scandipwa-guidelines/derived-class-names */
/* eslint-disable @scandipwa/scandipwa-guidelines/use-namespace */
/* eslint-disable no-undef */
/* eslint-disable prefer-const */
/* eslint-disable fp/no-let */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/prop-types */
/* eslint-disable react/sort-comp */
/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
import { Component, PureComponent } from 'react';
import Select from 'react-select';

import Field from 'Component/Field';
import Form from 'Component/Form';
import WithGoogleRecaptcha from 'Component/GoogleRecaptchaV3/GoogleRecaptchaV3.component';
import Loader from 'Component/Loader';
import {
    isEurStore
} from 'Util/FrontRunner/Store';
import { isB2BUser } from 'Util/Helper';

import imgPlaceholder from '../../images/front-thumb.jpg';
import { B2B_PREFFERD_OUTCOME, B2C_PREFFERD_OUTCOME, REASON_FOR_RETURN } from './ReturnsForm.config';

import './ReturnsForm.style';

/** @namespace Pwa/Component/ReturnsForm/Component/ReturnsFormComponent */
export class ReturnsForm extends PureComponent {
  renderProduct = (product) => {
      const {
          brand, id, image, name, qty, sku
      } = product;

      let imgSrc = image;
      if (!image) {
          imgSrc = imgPlaceholder;
      }

      const {
          setFormState, setProductSelection, selectedItems, onProductSelect, setProductDropdownSelection, formErrorSecond
      } = this.props;

      const trueVal = true;
      const falseVal = false;

      let obj = selectedItems.find((o) => o.sku === sku);

      const errorMessage = (fieldName) => {
          if (formErrorSecond === true && fieldName === '') {
              return (
          <p className="Field-Message">
            { __('This field is required!') }
          </p>
              );
          }

          return false;
      };

      return (
      <div className="fieldTopWrap productSec FieldMessage">
      <input
        type="checkbox"
        onChange={ onProductSelect.bind(this, product) }
      />
      <div className="prodWrap">
          <div className="proImag">
              <img src={imgSrc} alt="img" />
          </div>
          <div className="proName">
              <h3>{ name }</h3>
              <p>
                { brand }
                { ' | ' }
                { __('SKU: %s', sku) }
              </p>
          </div>
          <div className="proQnt">
              <input
                type="number"
                name={`${id}-qtyReturn`}
                value={obj && obj.qtyReturn}
                onChange={ setProductSelection.bind(this, product) }
                // disabled={(obj && obj.qtyReturn >= 1) ? '' : 'disabled'}
              />
          </div>
      </div>
      <div className="FieldWrapMain">
      <div className="FieldWrap">
        <label className="Field-Label Field-Label_isRequired">
          {__('In new/unused condition?')}
        </label>
        <div className="radio_wrap_container">
            <div className="radio_wrap">
                <input
                  type="radio"
                  id={`${id}-isProductNewYes`}
                  name={`${id}-isProductNew`}
                  value={trueVal}
                  onChange={ setProductSelection.bind(this, product) }
                  required
                />
                <label htmlFor={`${id}-isProductNewYes`}>
                    {__('Yes')}
                </label>
            </div>
            <div className="radio_wrap">
                <input
                  type="radio"
                  id={`${id}-isProductNewNo`}
                  name={`${id}-isProductNew`}
                  value={falseVal}
                  onChange={ setProductSelection.bind(this, product) }
                  required
                />
                <label htmlFor={`${id}-isProductNewNo`}>
                    {__('No')}
                </label>
            </div>
        </div>
        {obj && errorMessage(obj?.isProductNew)}
      </div>
      <div className="FieldWrap">
        <label className="Field-Label Field-Label_isRequired">
          {__('In the original manufacturer packaging?')}
        </label>
        <div className="radio_wrap_container">
            <div className="radio_wrap">
                <input
                  type="radio"
                  id={`${id}-isInOriginalPackagingYes`}
                  name={`${id}-isInOriginalPackaging`}
                  value={trueVal}
                  onChange={ setProductSelection.bind(this, product) }
                  required
                />
                <label htmlFor={`${id}-isInOriginalPackagingYes`}>
                {__('Yes')}
                </label>
            </div>
            <div className="radio_wrap">
                <input
                  type="radio"
                  id={`${id}-isInOriginalPackagingNo`}
                  name={`${id}-isInOriginalPackaging`}
                  value={falseVal}
                  onChange={ setProductSelection.bind(this, product) }
                  required
                />
                <label htmlFor={`${id}-isInOriginalPackagingNo`}>
                {__('No')}
                </label>
            </div>
        </div>
        {obj && errorMessage(obj?.isInOriginalPackaging)}
      </div>

      <div className="FieldWrap">
        <label className="Field-Label Field-Label_isRequired">
          {__('Includes all original hardware / components?')}
        </label>
        <div className="radio_wrap_container">
            <div className="radio_wrap">
            <input
              type="radio"
              id={`${id}-isIncludeAllComponentsYes`}
              name={`${id}-isIncludeAllComponents`}
              value={trueVal}
              onChange={ setProductSelection.bind(this, product) }
              required
            />
            <label htmlFor={`${id}-isIncludeAllComponentsYes`}>
            {__('Yes')}
            </label>
            </div>
            <div className="radio_wrap">
            <input
              type="radio"
              id={`${id}-isIncludeAllComponentsNo`}
              name={`${id}-isIncludeAllComponents`}
              value={falseVal}
              onChange={ setProductSelection.bind(this, product) }
              required
            />
            <label htmlFor={`${id}-isIncludeAllComponentsNo`}>
                {__('No')}
            </label>
            </div>
        </div>
        {obj && errorMessage(obj?.isIncludeAllComponents)}
      </div>
        <div className="FieldWrap">
        <label className="Field-Label Field-Label_isRequired">
          {__('Reason for Return')}
        </label>
        <Select
          onChange={setProductDropdownSelection.bind(this, product)}
          options={REASON_FOR_RETURN}
          placeholder={ __('Please Select') }
        />
        </div>
        {obj && errorMessage(obj?.reason)}
      </div>
      </div>
      );
  };

  renderFirstForm = () => {
      const {
          setFormState, fullName, orderNumber, contactNumber, contactEmail, fetchProductsFromInvoice, formErrorFirst
      } = this.props;

      const errorMessage = (fieldName) => {
          if (formErrorFirst === true && fieldName === '') {
              return (
            <p className="Field-Message">
              { __('This field is required!') }
            </p>
              );
          }

          return false;
      };

      return (
          <div className="FieldTopWrap">
            <h2>
               { __('Return Request') }
            </h2>
            <div className="decp">
              { __('Please filL out your details below. Click continue when ready to proceed.') }
            </div>
            <div className="FieldBox">
              <Field
                type="text"
                label={ __('Full Name') }
                id="name"
                name="fullName"
                value={fullName}
                validation={ ['notEmpty'] }
                onChange={ setFormState }
                placeholder={ __('John/Jane Doe') }
                required
              />
              { errorMessage(fullName)}
            </div>
            <div className="FieldBox">
              <Field
                type="text"
                label={ __('Order or Invoice Number') }
                id="orderNumber"
                name="orderNumber"
                value={orderNumber}
                validation={ ['notEmpty'] }
                onChange={ setFormState }
                placeholder={ `${window?.storeConfig?.storeId}000123456SO` }
                required
              />
              { errorMessage(orderNumber)}
            </div>
            <div className="FieldBox">
              <Field
                type="email"
                label={ __('Email Address (As used on order)') }
                id="email"
                name="contactEmail"
                value={contactEmail}
                validation={ ['notEmpty', 'email'] }
                onChange={ setFormState }
                placeholder="example@gmail.com"
                required
              />
                { errorMessage(contactEmail)}
            </div>
            <div className="FieldBox">
            <Field
              type="tel"
              label={ __('Contact Number (Optional)') }
              id="contact"
              name="contactNumber"
              value={contactNumber}
              onChange={ setFormState }
            />
            </div>
            <div className="actions">
              <div onClick={fetchProductsFromInvoice} className="btn btn-primary continueBtn">
                { __('Continue') }
              </div>
            </div>
            <WithGoogleRecaptcha />
          </div>
      );
  };

  renderSecondForm = () => {
      const {
          setFormState, handleFileChange, customer, returnFromSecond, proceedNext, invoiceLinkedProducts, checkSelectedItems, orderNumber
      } = this.props;

      return (
        <div className="FieldTopWrap">
          <h2>{orderNumber}</h2>
          <div className="decp">
            { __('SELECT THE PRODUCTS BELOW YOU would LIKE to return?') }
            <br />
            { __('Please indicate the quantity you would like to return as well.') }
          </div>
          <div className="proTopSec">
            <div className="title">
              { __('Product') }
            </div>
            <div className="qty">
            { __('Qty To Return') }
            </div>
          </div>
          { invoiceLinkedProducts.ReturnMerchandiseAuthorizationQuery.map((product) => this.renderProduct(product)) }
          <div className="actions">
            <div onClick={returnFromSecond} className="btn prevStep">
              { __('Back') }
            </div>
            <div onClick={checkSelectedItems} className="btn btn-primary continueBtn">
              { __('Continue') }
            </div>
          </div>
        </div>
      );
  };

  renderThirdForm = () => {
      const {
          setFormState, handleFileChange, customer, prevStep, renderSubmitButton, setPreferredOutcome, preferredOutcome, formErrorThird, returnFromThird
      } = this.props;

      const { is_b2b } = customer;

      const { storeConfig } = window;

      const checkB2BCustomerFields = () => {
          if (customer && isB2BUser(is_b2b)) {
              return (
                  B2B_PREFFERD_OUTCOME
              );
          }

          return B2C_PREFFERD_OUTCOME;
      };

      const errorMessage = (fieldName) => {
          if (formErrorThird === true && fieldName === '') {
              return (
          <p className="Field-Message">
            { __('This field is required!') }
          </p>
              );
          }

          return false;
      };

      const renderRequiredFileds = () => {
          const { selectedItems } = this.props;
          const requiredImageReasonsEurope = ['defective_products', 'damaged_products', 'other'];

          const hasValidReason = !Array.isArray(selectedItems)
          || selectedItems.some((item) => requiredImageReasonsEurope.includes(item.reason));

          if (!isEurStore()) {
              return false;
          }

          if (isEurStore() && !hasValidReason) {
              return false;
          }

          return true;
      };

      const renderRequiredClass = () => {
          const { selectedItems } = this.props;
          const requiredImageReasonsEurope = ['defective_products', 'damaged_products', 'other'];

          const hasValidReason = !Array.isArray(selectedItems)
          || selectedItems.some((item) => requiredImageReasonsEurope.includes(item.reason));

          if (!isEurStore()) {
              return null;
          }

          if (isEurStore() && !hasValidReason) {
              return null;
          }

          return 'Field-Label_isRequired';
      };

      return (
        <div className="fieldTopWrap finalStep">
          <h2>
            { __('Final Step') }
          </h2>
          <div className="decp">
            { __('Almost done, please fill out the last few fields') }
          </div>
          <div className="FieldBox">
            <label className={`Field-Label ${renderRequiredClass()}`}>
              {__('Upload product / packaging photos:')}
            </label>
            <input
              type="file"
              multiple
              id="myFile"
              name="filename"
              placeholder="File"
              onChange={handleFileChange}
              required={renderRequiredFileds()}

            />
            <div className="FieldLimit">
              { __(' Limited to jpeg, png, jpg, gif, 8mb Size') }
            </div>
          </div>
          <div className="FieldBox">
            <label className="Field-Label Field-Label_isRequired">
              {__('Preferred outcome')}
            </label>
            <Select
              onChange={setPreferredOutcome}
              options={checkB2BCustomerFields()}
              placeholder={ __('Please Select') }
            />
            {errorMessage(preferredOutcome)}
          </div>
          <div>
            <Field
              type="textarea"
              label={ __('Comments (Optional)') }
              id="topic"
              name="returnComments"
              onChange={ setFormState }
            />
          </div>
          <div className="finalBtnBottom actions">
            <div onClick={returnFromThird} className="btn prevBtn">
              { __('Back') }
            </div>
            { renderSubmitButton() }
          </div>
          <WithGoogleRecaptcha />
        </div>
      );
  };

  renderSuccess() {
      return (
        <div className="fieldTopWrap">
          <h2>
            { __('Submitted') }
            !
          </h2>
          <p className="decp">
            { __('THANK YOU FOR SUBMITING YOUR RETURN REQUEST') }
          </p>
          <p>
            { __('WHAT HAPPENS NEXT?') }
          </p>
          <p>
            { __("We've received your return request and it's now being reviewed by our team. Our dedicated team will carefully review your request and get back to you with a resolution as soon as possible.") }
          </p>
          <p>
            { __("In the meantime, we've sent you an email confirming that we've received your request. This email contains important details about your return request, so please keep it safe for future reference. ") }
          </p>
          <p>
            { __("We understand that returning products can be frustrating, and we appreciate your patience as we work to resolve this issue for you. If you have any further questions or concerns, please don't hesitate to contact us.") }
          </p>
          <div>
            { __('Your Front Runner Dometic Team') }
          </div>
        </div>
      );
  }

  renderFormContent() {
      const { step } = this.props;
      switch (step) {
      case 1:
          return (
              this.renderFirstForm()
          );
      case 2:
          return (
              this.renderSecondForm()
          );
      case 3:
          return (
              this.renderThirdForm()
          );
      case 4:
          return (
              this.renderSuccess()
          );
      default:
          (console.log('This is a multi-step form built with React.'));
      }

      return (
        <div
          block="ReturnsForm"
          elem="Wrapper"
        />
      );
  }

  captchaNotification() {
      const {
          showNotification
      } = this.props;

      showNotification(
          'info',
          __('Incorrect Captcha. Please try again after sometime.')
      );
  }

  renderButton() {
      return (
          <button
            block="Contact"
            elem="Button"
            mix={ { block: 'Button' } }
          >
            { __('Submit') }
          </button>
      );
  }

  render() {
      const {
          renderSubmitButton, mutationRequest, onCreateAccountAttempt, onCreateAccountSuccess, googleRecaptchaToken, loading, onError,
          showValidationError = 'noError'
      } = this.props;

      return (
          <div className="ReturnsForm">
              <Loader isLoading={ loading } />
              <Form
                key="returnsform"
                mix={ { block: `returnsForm ${ showValidationError}` } }
                onSubmit={ onCreateAccountAttempt }
                onSubmitSuccess={ googleRecaptchaToken === 'true' ? onCreateAccountSuccess : this.captchaNotification.bind(this) }
                onSubmitError={ onError }
              >
                { this.renderFormContent() }
              </Form>
          </div>
      );
  }
}

export default ReturnsForm;
