/* eslint-disable no-magic-numbers */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-console */
/* eslint-disable max-len */
/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable fp/no-let */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-unused-vars */

import { isEmpty, map } from 'lodash';
import PropTypes from 'prop-types';
import { Component } from 'react';
import OwlCarousel from 'react-owl-carousel';

import Html from 'Component/Html';
import Link from 'Component/Link';

/** @namespace Pwa/Component/CmsSlider/CmsSlider */
export class CmsSlider extends Component {
  static propTypes = {
      slides: PropTypes.any
  };

  static defaultProps = {
      slides: []
  };

  addBase = (src) => {
      const baseUrl = window.location.origin;
      let imgUrl = '';
      if (baseUrl && baseUrl.includes('localhost')) {
          imgUrl = `https://pwa.hypernode.frontrunneroutfitters.com/${src}`;
      } else {
          imgUrl = `${baseUrl}/${src}`;
      }

      // Remove double slashes from url except for the protocol
      imgUrl = imgUrl.replace(/([^:]\/)\/+/g, '$1');

      return imgUrl;
  };

  getSliderContent = (gallery) => {
      const {
          button_text,
          button_url,
          content_bottom = '',
          content_bottom_color,
          content_bottom_size = '32',
          content_bottom_font,
          content_position,
          content_position_vertical,
          content_top = '',
          content_top_color,
          content_top_size = '18',
          content_top_font
      } = gallery;

      const topTextShadowClass = ((content_top_color === '#000') || (content_top_color === '#000000')) ? '' : 'text-shadow';
      const bottomTextShadowClass = ((content_bottom_color === '#000') || (content_bottom_color === '#000000')) ? '' : 'text-shadow';

      const fontMapping = {
          regular_bold: '"regular-bold", sans-serif',
          intro: '"Intro", sans-serif'
      };
      const contentTopFont = fontMapping[content_top_font] || '"regular-bold", sans-serif';
      const contentBottomFont = fontMapping[content_bottom_font] || '"Intro", sans-serif';

      return (
        <div
          className={ `content ${content_position} ${content_position_vertical}` }
          style={ { verticalAlign: content_position_vertical, textAlign: content_position } }
        >
          <div
            className={ `fr_slider_content_top ${topTextShadowClass}` }
            style={ { color: content_top_color, fontSize: `${content_top_size}px`, fontFamily: contentTopFont } }
          >
            <Html content={ content_top } />
          </div>
          <div
            className={ `fr_slider_content_bottom ${bottomTextShadowClass}` }
            style={ { color: content_bottom_color, fontSize: `${content_bottom_size}px`, fontFamily: contentBottomFont } }
          >
            <Html content={ content_bottom } />
          </div>
          { !isEmpty(button_url) && (
            <div className="link">
              <Link
                block="Homepage"
                elem="Link btn btn-primary"
                to={ button_url }
              >
                { button_text }
              </Link>
            </div>
          ) }
        </div>
      );
  };

  renderSliderImages = (gallery) => (map(gallery, (g, key) => {
      const mobile_image = this.addBase(g.image_mobile);
      const desktop_image = this.addBase(g.image);
      const contentDiv = this.getSliderContent(g);
      const sliderId = g.slider_category_id;

      let clsName = 'item';
      if (sliderId && sliderId === 14) {
          clsName = 'item_14';
      }

      let loadingType = 'lazy';
      let fetchpriority = 'low';
      if (key === 0) {
          loadingType = 'eager';
          fetchpriority = 'high';
      }

      return (
        <div className={ clsName } key={ key }>
          { contentDiv }
          <picture>
            <source media="(max-width: 800px)" srcSet={ mobile_image } />
            <source media="(min-width: 801px)" srcSet={ desktop_image } />
            <img src={ desktop_image } loading={ loadingType } fetchpriority={ fetchpriority } />
          </picture>
        </div>
      );
  }));

  render() {
      let { slides = [] } = this.props;
      let autoplay = true;
      slides = atob(slides);
      slides = JSON.parse(slides);

      if (slides.length < 2) {
          autoplay = false;
      }

      return (
        <OwlCarousel
          className="owl-theme"
          loop
          margin={ 0 }
          nav={ false }
          items={ 1 }
          dots={ false }
          autoplay={ autoplay }
          autoplayTimeout={ 5000 }
          autoplayHoverPause
          lazyLoad
        >
            { this.renderSliderImages(slides) }
        </OwlCarousel>
      );
  }
}
export default CmsSlider;
