/* eslint-disable react/no-unused-prop-types */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-magic-numbers */
/* eslint-disable max-lines */
/* eslint-disable react/no-unused-state */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-curly-spacing */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable @scandipwa/scandipwa-guidelines/derived-class-names */
/* eslint-disable @scandipwa/scandipwa-guidelines/use-namespace */
/* eslint-disable no-undef */
/* eslint-disable prefer-const */
/* eslint-disable fp/no-let */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/prop-types */
/* eslint-disable react/sort-comp */
/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
import {
    isEmpty, map
} from 'lodash';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Field from 'Component/Field';
import Form from 'Component/Form';
import WithGoogleRecaptcha from 'Component/GoogleRecaptchaV3/GoogleRecaptchaV3.component';
import Loader from 'Component/Loader';

import {
    PLEASE_SELECT, PLEASE_SELECT_MAKE, PLEASE_SELECT_MODEL, PLEASE_SELECT_OPTION, PLEASE_SELECT_YEAR
} from './constants';

import './WebshopForm.style';

/** @namespace Pwa/Component/WebshopForm/Component/WebshopFormComponent */
export class WebshopForm extends PureComponent {
  static propTypes = {
      getAttribute: PropTypes.func.isRequired
  };

  __construct(props) {
      super.__construct(props);
      this.state = {
          checked: true
      };
  }

    getSelectOptions = (options) => map(options, (option) => ({
        ...option,
        id: option.value
    }));

    getWorkshopSelectOptions = (options) => map(options, (option) => ({
        ...option,
        id: option.value,
        label: option.name + option.country_id
    }));

  renderFirstForm = () => {
      const {
          handleFormChange,
          formData,
          contactEmail,
          formErrorFirst,
          proceedNext
      } = this.props;

      const {
          fullName,
          emailAddress,
          contactNumber
      } = formData;

      const errorMessage = (fieldName) => {
          if (formErrorFirst === true && fieldName === '') {
              return (
                  <p className="Field-Message">
                    { __('This field is required!') }
                  </p>
              );
          }

          return false;
      };

      return (
          <div className="FieldTopWrap">
              <h2>
                { __('WORKSHOP BOOKING') }
              </h2>
            <div className="decp">
              { __('PLEASE FILL OUT YOUR DETAILS BELOW AND CLICK CONTINUE.') }
            </div>
            <div className="FieldBox">
              <Field
                type="text"
                label={ __('Full Name') }
                id="name"
                name="fullName"
                value={fullName}
                validation={ ['notEmpty'] }
                onChange={ handleFormChange }
                placeholder={ __('John/Jane Doe') }
                required
              />
              { errorMessage(fullName)}
            </div>
            <div className="FieldBox">
              <Field
                type="email"
                label={ __('Email Address') }
                id="email"
                name="emailAddress"
                value={emailAddress}
                validation={ ['notEmpty', 'email'] }
                onChange={ handleFormChange }
                placeholder="example@gmail.com"
                required
              />
                { errorMessage(contactEmail)}
            </div>
            <div className="FieldBox">
            <Field
              type="tel"
              label={ __('Contact Number (Optional)') }
              id="contact"
              name="contactNumber"
              value={contactNumber}
              onChange={ handleFormChange }
            />
            </div>
            <div className="FieldBox" />
            <div className="actions">
              <div onClick={() => proceedNext('step1')} className="btn btn-primary continueBtn">
                { __('Continue') }
              </div>
            </div>

            <WithGoogleRecaptcha />
          </div>
      );
  };

  renderSecondForm = () => {
      const {
          handleFormChange,
          formErrorSecond,
          formErrorSecondCustomVehicle,
          handleFileChange,
          returnFromSecond,
          proceedNext
      } = this.props;

      const {
          handleYearsDataChange,
          handleMakesDataChange,
          handleModelDataChange,
          handleOptionsDataChange,
          formData = {},
          yearsData = [],
          makesData = [],
          modelsData = [],
          optionsData = [],
          handleCustomCheckbox
      } = this.props;

      const {
          specialAccessories,
          modificationsDetail,
          productsMounted,
          customYear,
          customMake,
          customModel,
          customVehicle
      } = formData;

      const {
          year = '',
          make = '',
          model = '',
          option = ''
      } = formData;

      const { checked = true } = this.state;

      const isMakesDisabled = isEmpty(makesData);
      const makesSelectData = (isMakesDisabled || customVehicle) ? [] : [PLEASE_SELECT_MAKE, ...makesData];

      const isModelsDisabled = isEmpty(modelsData);
      const modelsSelectData = (isModelsDisabled || customVehicle) ? [] : [PLEASE_SELECT_MODEL, ...modelsData];

      const isOptionsDisabled = isEmpty(optionsData);
      const optionsSelectData = (isOptionsDisabled || customVehicle) ? [] : [PLEASE_SELECT_OPTION, ...optionsData];

      const yearsSelectData = [PLEASE_SELECT_YEAR, ...yearsData];
      const yearSelectDataDisabled = !customVehicle ? yearsSelectData : [];

      const vehicleCustom = customVehicle ? 'custom-vehicle-active' : '';

      const errorMessage = (fieldName) => {
          if (formErrorSecond === true && fieldName === '') {
              return (
                <p className="Field-Message">
                  { __('This field is required!') }
                </p>
              );
          }

          return false;
      };

      const errorMessageCustomVehicle = (fieldName) => {
          if (formErrorSecondCustomVehicle === true && fieldName === '') {
              return (
              <p className="Field-Message">
                { __('This field is required!') }
              </p>
              );
          }

          return false;
      };

      return (
        <div className="FieldTopWrap">
          <h2>
            { __('VEHICLE SPECIFICS') }
          </h2>
          <div className="decp mb_20">
            { __('PLEASE ENTER YOUR VEHICLE INFORMATION BELOW AND CLICK CONTINUE.') }
          </div>
          <div className="cnt_vehicle_wrap">
            <label className="Field-Label_isRequired">
                { __('Your Vehicle') }
            </label>
            <div className="cnt_vehicle">
              { (checked) && (
                <Field
                  type="select"
                  id="year"
                  name="year"
                  sorting="0"
                  isDisabled={ customVehicle }
                  value={ year }
                  onChange={ handleYearsDataChange }
                  selectOptions={ this.getSelectOptions(yearSelectDataDisabled) }
                />
              ) }
              { errorMessage(year)}
                { (checked) && (
                  <Field
                    type="select"
                    id="make"
                    isDisabled={ isMakesDisabled }
                    name="make"
                    sorting="0"
                    value={ make }
                    onChange={ handleMakesDataChange }
                    selectOptions={ this.getSelectOptions(makesSelectData) }
                  />
                ) }
                { errorMessage(make)}
                { (checked) && (
                  <Field
                    type="select"
                    id="model"
                    isDisabled={ isModelsDisabled }
                    name="model"
                    sorting="0"
                    value={ model }
                    onChange={ handleModelDataChange }
                    selectOptions={ this.getSelectOptions(modelsSelectData) }
                  />
                ) }
                { errorMessage(model)}
                { (checked) && (
                    <Field
                      type="select"
                      id="option"
                      isDisabled={ isOptionsDisabled }
                      name="option"
                      sorting="0"
                      value={ option }
                      onChange={ handleOptionsDataChange }
                      selectOptions={ this.getSelectOptions(optionsSelectData) }
                    />
                ) }
                { errorMessage(option)}
            </div>
          </div>
          <div className="differentVehicle">
            <input
              type="checkbox"
              id="customVehicle"
              name="customVehicle"
              onChange={ handleCustomCheckbox.bind(this) }
              checked={customVehicle ? 'checked' : ''}
            />
            <label htmlFor="customVehicle">
            { __('I have a different vehicle not listed above') }
            </label>
          </div>
          <div className={`FieldBoxVeh ${vehicleCustom}`}>
              <Field
                type="text"
                id="name"
                name="customYear"
                value={customYear}
                onChange={ handleFormChange }
                placeholder={__('Year')}
                required
                isDisabled={!customVehicle}
              />
              { errorMessageCustomVehicle(customYear)}
              <Field
                type="text"
                id="name"
                name="customMake"
                value={customMake}
                onChange={ handleFormChange }
                placeholder={__('Make')}
                required
                isDisabled={!customVehicle}

              />
              { errorMessageCustomVehicle(customMake)}
              <Field
                type="text"
                id="name"
                name="customModel"
                value={customModel}
                onChange={ handleFormChange }
                placeholder={__('Model')}
                required
                isDisabled={!customVehicle}

              />
              { errorMessageCustomVehicle(customModel)}
          </div>
          <div className="textAreaBox">
            <Field
              type="textarea"
              label={ __('Do you have any special accessories on your vehicle?') }
              id="topic"
              name="specialAccessories"
              placeholder={ __('E.g. Panoramic Sunroof, any OEM rails etc. ') }
              value={specialAccessories}
              onChange={ handleFormChange }
              validation={ ['notEmpty'] }
            />
            { errorMessage(specialAccessories)}
          </div>
          <div className="textAreaBox">
            <Field
              type="textarea"
              label={ __('Have you made any modifications to your vehicle? ') }
              id="topic"
              name="modificationsDetail"
              placeholder={ __('Both internal and external (inside/outside)') }
              value={modificationsDetail}
              onChange={ handleFormChange }
              validation={ ['notEmpty'] }
            />
            { errorMessage(modificationsDetail)}
          </div>
          <div className="textAreaBox mb_20">
            <Field
              type="textarea"
              label={ __('What products are currently mounted/ installed?') }
              id="topic"
              name="productsMounted"
              placeholder={ __('Front Runner Dometic products and other brands') }
              value={productsMounted}
              onChange={ handleFormChange }
              validation={ ['notEmpty'] }
            />
            { errorMessage(productsMounted)}
          </div>
          <div className="textAreaBox">
            <label className="Field-Label">
              {__('Pictures of your Vehicle (Optional)')}
              <br />
              {__('Exterior complete, roof (if possible) and interior (roof lining)')}
            </label>
            <input
              type="file"
              multiple
              id="myFile"
              name="filename"
              placeholder="File"
              onChange={handleFileChange}
              // required={renderRequiredFileds()}
            />
            <div className="FieldLimit">
              { __(' Limited to jpeg, png, jpg, gif, 2mb Size') }
            </div>
          </div>
          <div className="textAreaBox actions">
            <div onClick={returnFromSecond} className="btn prevStep">
              { __('Back') }
            </div>
            <div onClick={() => proceedNext('step2')} className="btn btn-primary continueBtn">
              { __('Continue') }
            </div>
          </div>
        </div>
      );
  };

  _findObjectBySku(arrayOfObjects, targetSku) {
      // eslint-disable-next-line eqeqeq
      return arrayOfObjects.find((obj) => obj.sku == targetSku);
  }

  renderAdditionalProductDetails(item) {
      const { getAttribute } = this.props;
      const { product: { sku, brand, attributes = {} } } = item;
      if (!sku) {
          return null;
      }

      let brandText = brand;
      if (isEmpty(brandText)) {
          // Try get from attributes
          if (!attributes) {
              return null;
          }

          const { attribute_value: brand } = getAttribute('brand') || {};
          if (isEmpty(brand)) {
              // Find attribute_code = "brand" in attributes
              let brandAttributeValue = null;
              if (Array.isArray(attributes)) {
                  const brandAttribute = attributes.find((attribute) => attribute.attribute_code === 'brand');
                  brandAttributeValue = brandAttribute?.attribute_value ?? null;
              }
              if (!isEmpty(brandAttributeValue)) {
                  // Find brandAttributeValue in attribute_options
                  const brandAttribute = attributes.find((attribute) => attribute.attribute_code === 'brand');
                  const brandOption = brandAttribute?.attribute_options?.find((option) => option.value === brandAttributeValue);
                  brandText = brandOption?.label ?? null;
              }
          } else {
              brandText = attributes?.brand?.attribute_options[brand]?.label ?? null;
          }
      }

      if (sku && isEmpty(brandText)) {
          return null;
      }

      return (
        <p
          block="ProductCard"
          elem="Brand"
          mods={ { isLoaded: !!sku } }
        >
            { brandText }
        </p>
      );
  }

  renderProductListCard = (item) => {
      const { product: { small_image: { url }, name } } = item;
      const { product, sku } = item;
      const { onProductSelect, selectedProducts } = this.props;
      const productIsSelected = this._findObjectBySku(selectedProducts, sku);
      return (
      <div className="productWrap">
      <input
        type="checkbox"
        id={ `option-${ sku }` }
        name={ `option-${ sku }` }
        onChange={ onProductSelect.bind(this, product) }
        checked={ productIsSelected ? 'checked' : '' }
      />
      <img src={url} alt="img" />
      <div className="prodTextWrap">
        <p className="title">{name}</p>
        {this.renderAdditionalProductDetails(item)}
      </div>
      </div>
      );
  };

  renderThirdForm = () => {
      const {
          proceedNext,
          cart,
          customer,
          returnFromThird
      } = this.props;

      const { is_b2b } = customer;

      const { storeConfig } = window;

      const { items } = cart;

      if (isEmpty(items)) {
          return (
              <>
              <h2>
                { __('PRODUCTS TO FIT') }
              </h2>
              <div className="decp">
                { __('SELECT THE PRODUCTS BELOW YOU WOULD LIKE TO HAVE INSTALLED.') }
              <br />
                { __('THE ITEMS BELOW ARE AUTOMATICALLY PULLED FROM YOUR SHOPPING CART') }
              </div>
             <div className="list-heading">
                { __('Product') }
             </div>
             <div className="list-heading">
                { __('No Products added in the cart, please click continue to proceed.') }
             </div>
              <div className="textAreaBox actions">
                <div onClick={returnFromThird} className="btn prevBtn">
                  {__('Back')}
                </div>
                <div onClick={() => proceedNext('step3')} className="btn btn-primary continueBtn">
                  {__('Continue')}
                </div>
              </div>
              </>
          );
      }

      return (
        <div className="fieldTopWrap finalStep">
          <h2>
            { __('PRODUCTS TO FIT') }
          </h2>
          <div className="decp">
            { __('SELECT THE PRODUCTS BELOW YOU WOULD LIKE TO HAVE INSTALLED.') }
            <br />
            { __('THE ITEMS BELOW ARE AUTOMATICALLY PULLED FROM YOUR SHOPPING CART') }
          </div>
          <div className="list-heading">
            { __('Product') }
          </div>

          { items.map((item) => (this.renderProductListCard(item))) }

          <div className="textAreaBox actions">
            <div onClick={returnFromThird} className="btn prevBtn">
              { __('Back') }
            </div>
            <div onClick={() => proceedNext('step3')} className="btn btn-primary continueBtn">
                { __('Continue') }
            </div>
          </div>
          <WithGoogleRecaptcha />
        </div>
      );
  };

  _createSelectOptionsArray(locations) {
      return locations.map((location) => ({
          value: location.id,
          label: `${location.name},  ${location.country_id}`
      }));
  }

  _findObjectById(arrayOfObjects, targetId) {
      // eslint-disable-next-line eqeqeq
      return arrayOfObjects.find((obj) => obj.id == targetId);
  }

  _handleMapClick = (latitude = 1.1, longitude = 1.1) => {
      const externalLink = `https://www.google.com/maps?q=${latitude},${longitude}`;
      window.open(externalLink, '_blank');
  };

  renderFourthForm = () => {
      const {
          handleWorkshopLocationChange,
          workshopLocations,
          renderSubmitButton,
          handleFormChange,
          formData,
          returnFromThird,
          setFormState,
          formErrorFirst,
          appointmentTypes
      } = this.props;

      const { comments, appointment_type_id, workshopLocationId } = formData;

      const selectedWorkshopLocation = this._findObjectById(workshopLocations, workshopLocationId);

      const { latitude = 1.1, longitude = 1.1 } = selectedWorkshopLocation;

      const {
          name,
          province,
          street_1,
          street_2,
          city,
          postal_code,
          country_id
      } = selectedWorkshopLocation;

      const errorMessage = (fieldName) => {
          if (formErrorFirst === true && fieldName === '') {
              return (
        <p className="Field-Message">
          { __('This field is required!') }
        </p>
              );
          }

          return false;
      };

      return (
      <div className="FieldTopWrap">
        <h2>
           { __('APPOINTMENT') }
        </h2>
        <div className="decp mb_20">
          { __('Almost done, please fill out the last few fields') }
        </div>
        <div className="textAreaBox">
          <p className="Field-Label_isRequired">
            { __('When do you need the fitment done?')}
          </p>
        </div>
        { appointmentTypes.map((item) => (
                  <div className="radio_wrap textAreaBox">
                  <input
                    type="radio"
                    id="appointment_type_id"
                    name="appointment_type_id"
                    value={item.id}
                    onChange={ handleFormChange.bind(this) }
                    required
                    // eslint-disable-next-line eqeqeq
                    checked={ appointment_type_id == item.id ? 'checked' : '' }
                  />
                  <label htmlFor="14days">
                  {__(item.name)}
                  </label>
                  </div>
        )) }

        <div className="radio_wrap textAreaBox">
            <input
              type="radio"
              id="appointment_type_id"
              name="appointment_type_id"
              value="null"
              onChange={ handleFormChange.bind(this) }
              required
              // eslint-disable-next-line eqeqeq
              checked={ appointment_type_id == 'null' ? 'checked' : '' }
            />
          <div>
            <Field
              type="text"
              id="appointment_type_comment"
              name="appointment_type_comment"
              onChange={ handleFormChange }
              placeholder={ __('Other') }
              // eslint-disable-next-line no-unneeded-ternary, eqeqeq
              isDisabled={appointment_type_id == 'null' ? false : true}
            />
          </div>
        </div>
        <div className="textAreaBox workshopLoc">
          <label className="Field-Label_isRequired">
              { __('Select a Workshop location ') }
          </label>
          <Field
            type="select"
            id="workshopLocationId"
            name="workshopLocationId"
            sorting="0"
            selectOptions={ this._createSelectOptionsArray(workshopLocations) }
            onChange={ handleWorkshopLocationChange }
          />
        </div>
        <div className="textAreaBox selAddress">
          <div className="selAddressWrap">
            <p className="title">
              { __('Address') }
            </p>
            <p>
              { street_1 }
              <br />
              { `${postal_code} ${city}, ${country_id}` }
            </p>
          </div>
          <div className="btn" onClick={() => this._handleMapClick(latitude, longitude)}>
              { __('View map') }
          </div>
        </div>
        <div className="textAreaBox">
            <Field
              type="textarea"
              label={ __('Comments (Optional)') }
              id="topic"
              name="comments"
              value={comments}
              onChange={ handleFormChange }
            />
        </div>
        <div className=" actions">
            <div onClick={returnFromThird} className="btn prevBtn">
              { __('Back') }
            </div>
            { renderSubmitButton() }
        </div>
        <WithGoogleRecaptcha />
      </div>
      );
  };

  renderSuccess() {
      return (
        <div className="fieldTopWrap">
          <h2>
            { __('Submitted') }
            !
          </h2>
          <p className="decp">
            { __('THANK YOU FOR SUBMITTING YOUR WORKSHOP BOOKING REQUEST') }
          </p>
          <h3 className="title_h4">
            { __('WHAT HAPPENS NEXT?') }
          </h3>
          <p>
            { __('We have received your booking request it is now being reviewed by our team.') }
          </p>
          <p>
            { __('Our team will contact you as soon as possible.') }
          </p>
          <div className="strong">
            { __('Your Front Runner Dometic Team') }
          </div>
        </div>
      );
  }

  renderFormContent() {
      const { step, cart } = this.props;
      const { items } = cart;
      switch (step) {
      case 1:
          return (
              this.renderFirstForm()
          );
      case 2:
          return (
              this.renderSecondForm()
          );
      case 3:
          if (isEmpty(items)) {
              return (
                  this.renderFourthForm()
              );
          }

          return (
              this.renderThirdForm()
          );
      case 4:
          return (
              this.renderFourthForm()
          );
      case 5:
          return (
              this.renderSuccess()
          );
      default:
          (console.log('This is a multi-step form built with React.'));
      }

      return (
        <div
          block="WebshopForm"
          elem="Wrapper"
        />
      );
  }

  captchaNotification() {
      const {
          showNotification
      } = this.props;

      showNotification(
          'info',
          __('Incorrect Captcha. Please try again after sometime.')
      );
  }

  renderButton() {
      return (
          <button
            block="Contact"
            elem="Button"
            mix={ { block: 'Button' } }
          >
            { __('Submit') }
          </button>
      );
  }

  render() {
      const {
          onCreateAccountAttempt,
          onCreateAccountSuccess,
          googleRecaptchaToken,
          loading,
          onError,
          showValidationError = 'noError'
      } = this.props;

      return (
          <div className="ReturnsForm">
              <Loader isLoading={ loading } />
              <Form
                key="Returnsform"
                mix={ { block: `ReturnsFormInner ${ showValidationError}` } }
                onSubmit={ onCreateAccountAttempt }
                onSubmitSuccess={ googleRecaptchaToken === 'true' ? onCreateAccountSuccess : this.captchaNotification.bind(this) }
                onSubmitError={ onError }
              >
                { this.renderFormContent() }
              </Form>
          </div>
      );
  }
}

export default WebshopForm;
