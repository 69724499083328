/* eslint-disable @scandipwa/scandipwa-guidelines/derived-class-names */
/* eslint-disable @scandipwa/scandipwa-guidelines/use-namespace */
/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
/* eslint-disable no-console */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable @scandipwa/scandipwa-guidelines/create-config-files */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

// eslint-disable-next-line simple-import-sort/sort
import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, map, get } from 'lodash';

import Popup from 'Component/Popup';
import { ProductType } from 'Type/ProductList';
import ProductQAForm from 'Component/ProductQAForm';
import ContentWrapper from 'Component/ContentWrapper';
import Html from 'Component/Html';
import './ProductQA.style';

export const QA_POPUP_ID = 'QA_POPUP_ID';

/** @namespace Pwa/Component/ProductQA/Component/ProductQAComponent */
export class ProductQA extends PureComponent {
    static propTypes = {
        product: ProductType.isRequired,
        showPopup: PropTypes.func.isRequired
    };

    // constructor(props) {
    //   super(props)
    //   state = {
    //     qa
    //   }

    // }

    renderPopup() {
        const { product } = this.props;

        return (
            <Popup
              id={ QA_POPUP_ID }
              mix={ { block: 'ProductQA', elem: 'Popup' } }
            >
                <ProductQAForm product={ product } />
            </Popup>
        );
    }

    renderButton() {
        const { showPopup } = this.props;

        return (
            <button
              block="ProductQA"
              elem="Button"
              mix={ { block: 'btn btn-default' } }
              onClick={ showPopup }
            >
                { __('ASK QUESTION') }
            </button>
        );
    }

    renderNoQA() {
        return (
        <div className="qa-des">
          { __('No questions yet. Be the first to ask a question!') }
        </div>
        );
    }

    toggleQA = (key) => {
        const isExpanded = get(this.state, `show_qa_${key}`, true);
        this.setState({
            [`show_qa_${key}`]: !isExpanded
        });
    };

    renderSingleQA = (data, key) => {
        const {
            answer,
            // author_name,
            question
        } = data;
        const isExpanded = get(this.state, `show_qa_${key}`, true);
        const arrowClass = isExpanded ? '' : 'arrow_down';
        return (
        <div className="singleQA" key={ key }>
          <div onClick={ () => this.toggleQA(key) } className={ `question-content ${arrowClass}` }>
            { question }
          </div>
          { !isExpanded && (
            <div className="answer-content-wrap">
              { /* <div className="question-by">
                { __('Question by:') }
                { author_name }
              </div> */ }
              <div className="answer-content">
                { answer && <Html content={ answer } /> }
              </div>
              { /* <div className="answer-by"> Answer by: Front Runner Dometic</div> */ }
            </div>
          ) }
        </div>
        );
    };

    renderPreviousQA(data) {
        return (
        <div className="ProductQAList">
          { map(data, this.renderSingleQA) }
        </div>
        );
    }

    renderSummary() {
        const { data = {} } = this.props;
        const { product_qa = [] } = data;
        return (
            <div block="ProductQA" elem="QA">
                { this.renderButton() }
                <h3 block="ProductQA" elem="Title">
                    { __('PRODUCT QUESTIONS') }
                </h3>
                { isEmpty(product_qa) && this.renderNoQA() }
                { !isEmpty(product_qa) && this.renderPreviousQA(product_qa) }
            </div>
        );
    }

    render() {
        return (
            <ContentWrapper
              label="Product QA"
              mix={ { block: 'ProductQA' } }
              wrapperMix={ { block: 'ProductQA', elem: 'Wrapper' } }
            >
              { this.renderSummary() }
              { this.renderPopup() }
            </ContentWrapper>
        );
    }
}

export default ProductQA;
