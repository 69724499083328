/* eslint-disable max-len */
import { Field } from 'Util/Query';

/** @namespace Pwa/Query/Recaptcha/Query/RecaptchaQuery */
export class RecaptchaQuery {
    recapchavar(secret, response) {
        const input = {
            secret,
            response
        };

        console.log('query', input);

        return new Field('recapchavar')

            .addArgument('secret', 'String!', secret)
            .addArgument('response', 'String!', response)
            .addFieldList([
                'success',
                'challenge_ts',
                'hostname'
            ]);
    }

    turnstileRecaptchaVerfication(token) {
        return new Field('verifyCaptcha')

            .addArgument('cf_turnstile_response', 'String!', token)
            .addFieldList([
                'success',
                'challenge_ts',
                'hostname'
            ]);
    }
}

export default new RecaptchaQuery();
