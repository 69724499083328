/* eslint-disable react/no-string-refs */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/jsx-curly-spacing */
/* eslint-disable @scandipwa/scandipwa-guidelines/always-both-mappings */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable no-console */
/* eslint-disable max-lines */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unused-state */
/* eslint-disable fp/no-let */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/sort-comp */

import {
    get, isEmpty,
    map, upperCase
} from 'lodash';
import { connect } from 'react-redux';

import Link from 'Component/Link';
import InstafeedsQuery from 'Query/Instafeeds.query';
import { showNotification } from 'Store/Notification/Notification.action';
import { formatCleanQuery } from 'Util/Helper';
import DataContainer from 'Util/Request/DataContainer';

export const NUMBER_OF_DISPLAYED_PRODUCTS = 4;
/** @namespace Pwa/Component/InstagramFeeds/mapStateToProps */
export const mapStateToProps = (state) => ({
    store_id: window.storeConfig.storeId
});
/** @namespace Pwa/Component/InstagramFeeds/InstagramFeeds */
export class InstagramFeeds extends DataContainer {
//   static propTypes = {
//     storeId: PropTypes.any
//   };

    __construct(props) {
        super.__construct(props);

        this.state = {
            instagram: {}
        };
    }

    componentDidMount() {
        this.storeIdLookupAndRequest();
    }

  _handleError = (error) => {
      const [{ message, debugMessage }] = error;
      showNotification('error', debugMessage || message);
  };

  storeIdLookupAndRequest= () => {
      const period = 1000; // ms
      const sleepyAlert = setInterval(() => {
          const { store_id } = this.props;
          if (store_id !== undefined) {
              clearInterval(sleepyAlert);
              this._fetchInstaFeeds();
          }
      }, period);
  };

  _fetchInstaFeeds() {
      const { store_id = '7' } = this.props;
      //   const options = { InstoreId: storeId };
      const params = {
          storeId: store_id
      };

      this.setState({ isLoading: true });
      const graphql = InstafeedsQuery.getQuery(params);
      this.fetchFsyncData(
          formatCleanQuery(graphql),
          '/instagram?',
          this.onPageLoad,
          () => this.setState({ isLoading: false })
      );
  }

  onPageLoad = (data) => {
      const { instagram = [] } = data;
      this.setState({ instagram, isLoading: false, isPageLoaded: true });
  };

  getInstContent= () => {
      const { instagram = [] } = this.state;
      if (isEmpty(instagram) || instagram.includes('{')) {
          return null;
      }
      let handler = '';
      let linked_profle_url = '';
      const InstData = instagram.map((it) => {
          const {
              id,
              region,
              type,
              thumbnail,
              link,
              handle,
              profile_url,
              comments,
              likes,
              created_at,
              updated_at
          } = it;

          handler = handle;
          linked_profle_url = profile_url;

          return (
            <div className="inst_img" key={id}>
                <Link
                  block="inst"
                  elem="link"
                  to={ `${link}` }
                  target="_blank"
                  rel="noopener noreferrer"
                >
                    <img
                      src={ `${thumbnail}` }
                      alt="Follow Front Runner Dometic on Instagram"
                      loading="lazy"
                      width="130"
                      height="130"
                    />
                </Link>
            </div>
          );
      });

      return (
          <>
            <div className="InstagramFeeds">
                { InstData }
            </div>
            {/* {handler} */}
            <div className="instagram_posts_follow">
                <Link
                  block="inst"
                  elem="link"
                  to={ `${linked_profle_url}` }
                  target="_blank"
                  rel="noopener noreferrer"
                >
                    {/* <FontAwesomeIcon icon={ faUserCircle } /> */}
                    <svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="instagram" className="svg-inline--fa fa-instagram fa-w-14 " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z" /></svg>
                    { __(' ') }
                    { __('Follow us on Instagram') }
                </Link>
            </div>
          </>
      );
  };

  render() {
      const { instagram = [] } = this.state;
      return (
          isEmpty(instagram) ? <> </>
              : (
                <div block="InstagramFeeds" elem="block">
                    <div className="h2">{ __('Recent Instagram Posts') }</div>
                    { this.getInstContent() }
                </div>
              )
      );
  }
}

export default connect(mapStateToProps)(InstagramFeeds);
