/* eslint-disable no-console */
/* eslint-disable max-len */
/* eslint-disable max-lines */
/* eslint-disable no-useless-escape */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { isEmpty } from 'lodash';

import {
    TOGGLE_HEADER_AND_FOOTER,
    UPDATE_MENU
} from './HeaderAndFooter.action';

export const initialState = {
    mobileMenu: [
        {
            title: 'Home',
            url: '\/',
            color: null,
            text_color: null,
            type: null,
            child: null
        },
        {
            title: 'Shop',
            url: null,
            color: '#2db0bc',
            text_color: null,
            type: 'shop',
            child: [
                {
                    title: 'RACKS',
                    url: '\/roof-racks.html',
                    url_resolver: {
                        id: 3,
                        type: 'CATEGORY',
                        url_key: 'roof-racks'
                    },
                    child: [
                        {
                            title: 'ROOF RACKS',
                            url: '\/roof-racks\/slimline-ii-roof-rack-kits.html',
                            url_resolver: {
                                id: 8,
                                type: 'CATEGORY',
                                url_key: 'slimline-ii-roof-rack-kits'
                            }
                        },
                        {
                            title: 'FACTORY\/OEM RAIL ROOF RACKS',
                            url: '\/roof-racks\/factory-roof-rail-rack-kits.html',
                            url_resolver: {
                                id: 78,
                                type: 'CATEGORY',
                                url_key: 'factory-roof-rail-rack-kits'
                            }
                        },
                        {
                            title: 'PICK-UP TRUCK BED RACKS',
                            url: '\/roof-racks\/pick-up-bed-racks.html',
                            url_resolver: {
                                id: 84,
                                type: 'CATEGORY',
                                url_key: 'pick-up-bed-racks'
                            }
                        },
                        {
                            title: 'CANOPY\/CAP\/TRAILER RACKS ',
                            url: '\/roof-racks\/canopy-cap-trailer-racks.html',
                            url_resolver: {
                                id: 90,
                                type: 'CATEGORY',
                                url_key: 'canopy-cap-trailer-racks'
                            }
                        },
                        {
                            title: 'CANOPY RACK KITS',
                            url: '\/roof-racks\/rsi-canopy-racks.html',
                            url_resolver: {
                                id: 119,
                                type: 'CATEGORY',
                                url_key: 'rsi-canopy-racks'
                            }
                        },
                        {
                            title: 'EXPEDITION RAILS',
                            url: '\/roof-racks\/rack-expedition-rails.html',
                            url_resolver: {
                                id: 88,
                                type: 'CATEGORY',
                                url_key: 'rack-expedition-rails'
                            }
                        },
                        {
                            title: 'INTERIOR RACKS',
                            url: '\/roof-racks\/interior-racks.html',
                            url_resolver: {
                                id: 117,
                                type: 'CATEGORY',
                                url_key: 'interior-racks'
                            }
                        },
                        {
                            title: 'RACK COMPONENTS',
                            url: '\/roof-racks\/rack-components.html',
                            url_resolver: {
                                id: 9,
                                type: 'CATEGORY',
                                url_key: 'rack-components'
                            }
                        }
                    ]
                },
                {
                    title: 'RACK ACCESSORIES',
                    url: '\/rack-accessories.html',
                    url_resolver: {
                        id: 11,
                        type: 'CATEGORY',
                        url_key: 'rack-accessories'
                    },
                    child: [
                        {
                            title: 'SPORT',
                            url: '\/rack-accessories\/sport-accessories.html',
                            url_resolver: {
                                id: 108,
                                type: 'CATEGORY',
                                url_key: 'sport-accessories'
                            }
                        },
                        {
                            title: 'CAMP',
                            url: '\/rack-accessories\/camp-accessories.html',
                            url_resolver: {
                                id: 116,
                                type: 'CATEGORY',
                                url_key: 'camp-accessories'
                            }
                        },
                        {
                            title: 'STORAGE',
                            url: '\/rack-accessories\/storage-accessories.html',
                            url_resolver: {
                                id: 107,
                                type: 'CATEGORY',
                                url_key: 'storage-accessories'
                            }
                        }
                    ]
                },
                {
                    title: 'CAMPING',
                    url: '\/camping-gear.html',
                    url_resolver: {
                        id: 18,
                        type: 'CATEGORY',
                        url_key: 'camping-gear'
                    },
                    child: [
                        {
                            title: 'TENTS & AWNINGS',
                            url: '\/camping-gear\/tents-awnings.html',
                            url_resolver: {
                                id: 17,
                                type: 'CATEGORY',
                                url_key: 'tents-awnings'
                            }
                        },
                        {
                            title: 'CAMP KITCHEN',
                            url: '\/camping-gear\/camp-kitchen.html',
                            url_resolver: {
                                id: 19,
                                type: 'CATEGORY',
                                url_key: 'camp-kitchen'
                            }
                        },
                        {
                            title: 'CHAIRS & TABLES',
                            url: '\/camping-gear\/chairs-tables.html',
                            url_resolver: {
                                id: 20,
                                type: 'CATEGORY',
                                url_key: 'chairs-tables'
                            }
                        },
                        {
                            title: 'STORAGE & BAGS',
                            url: '\/camping-gear\/storage-bags.html',
                            url_resolver: {
                                id: 21,
                                type: 'CATEGORY',
                                url_key: 'storage-bags'
                            }
                        },
                        {
                            title: 'TOOLS & OTHER',
                            url: '\/camping-gear\/other-camping-gear.html',
                            url_resolver: {
                                id: 22,
                                type: 'CATEGORY',
                                url_key: 'other-camping-gear'
                            }
                        }
                    ]
                },
                {
                    title: 'LOAD BARS',
                    url: '\/load-bars.html',
                    url_resolver: {
                        id: 74,
                        type: 'CATEGORY',
                        url_key: 'load-bars'
                    },
                    child: [
                        {
                            title: 'ROOF LOAD BAR KITS ',
                            url: '\/load-bars\/roof-load-bar-kits.html',
                            url_resolver: {
                                id: 75,
                                type: 'CATEGORY',
                                url_key: 'roof-load-bar-kits'
                            }
                        },
                        {
                            title: 'PICK-UP BED LOAD BAR KITS ',
                            url: '\/load-bars\/pick-up-bed-load-bar-kits.html',
                            url_resolver: {
                                id: 77,
                                type: 'CATEGORY',
                                url_key: 'pick-up-bed-load-bar-kits'
                            }
                        },
                        {
                            title: 'LOAD BAR COMPONENTS',
                            url: '\/load-bars\/load-bar-components.html',
                            url_resolver: {
                                id: 76,
                                type: 'CATEGORY',
                                url_key: 'load-bar-components'
                            }
                        }
                    ]
                },
                {
                    title: 'STORAGE ',
                    url: '\/storage-systems.html',
                    url_resolver: {
                        id: 26,
                        type: 'CATEGORY',
                        url_key: 'storage-systems'
                    },
                    child: [
                        {
                            title: 'BOXES & BAGS',
                            url: '\/storage-systems\/boxes-bags.html',
                            url_resolver: {
                                id: 30,
                                type: 'CATEGORY',
                                url_key: 'boxes-bags'
                            }
                        },
                        {
                            title: 'DRAWERS',
                            url: '\/storage-systems\/drawers.html',
                            url_resolver: {
                                id: 27,
                                type: 'CATEGORY',
                                url_key: 'drawers'
                            }
                        },
                        {
                            title: 'SLIDERS',
                            url: '\/storage-systems\/sliders.html',
                            url_resolver: {
                                id: 28,
                                type: 'CATEGORY',
                                url_key: 'sliders'
                            }
                        },
                        {
                            title: 'SAFES',
                            url: '\/storage-systems\/safes.html',
                            url_resolver: {
                                id: 29,
                                type: 'CATEGORY',
                                url_key: 'safes'
                            }
                        },
                        {
                            title: 'LASHING & SECURING',
                            url: '\/storage-systems\/lashing-and-securing.html',
                            url_resolver: {
                                id: 33,
                                type: 'CATEGORY',
                                url_key: 'lashing-and-securing'
                            }
                        },
                        {
                            title: 'GULLWING',
                            url: '\/storage-systems\/gullwing.html',
                            url_resolver: {
                                id: 89,
                                type: 'CATEGORY',
                                url_key: 'gullwing'
                            }
                        }
                    ]
                },
                {
                    title: 'RECOVERY',
                    url: '\/recovery-equipment.html',
                    url_resolver: {
                        id: 39,
                        type: 'CATEGORY',
                        url_key: 'recovery-equipment'
                    },
                    child: [
                        {
                            title: 'JACK & ACCESSORIES',
                            url: '\/recovery-equipment\/jack-accessories.html',
                            url_resolver: {
                                id: 41,
                                type: 'CATEGORY',
                                url_key: 'jack-accessories'
                            }
                        },
                        {
                            title: 'RECOVERY ACCESSORIES',
                            url: '\/recovery-equipment\/recovery-accessories.html',
                            url_resolver: {
                                id: 43,
                                type: 'CATEGORY',
                                url_key: 'recovery-accessories'
                            }
                        },
                        {
                            title: 'SAND LADDERS',
                            url: '\/recovery-equipment\/sand-ladders.html',
                            url_resolver: {
                                id: 44,
                                type: 'CATEGORY',
                                url_key: 'sand-ladders'
                            }
                        },
                        {
                            title: 'TYRE \/ TIRE',
                            url: '\/recovery-equipment\/tyre-tire.html',
                            url_resolver: {
                                id: 46,
                                type: 'CATEGORY',
                                url_key: 'tyre-tire'
                            }
                        }
                    ]
                },
                {
                    title: 'COOLERS',
                    url: '\/refrigeration.html',
                    url_resolver: {
                        id: 24,
                        type: 'CATEGORY',
                        url_key: 'refrigeration'
                    },
                    child: null
                },
                {
                    title: 'POWER & LIGHTING ',
                    url: '\/battery-solutions.html',
                    url_resolver: {
                        id: 35,
                        type: 'CATEGORY',
                        url_key: 'battery-solutions'
                    },
                    child: [
                        {
                            title: 'BATTERY MANAGEMENT',
                            url: '\/battery-solutions\/battery-management.html',
                            url_resolver: {
                                id: 37,
                                type: 'CATEGORY',
                                url_key: 'battery-management'
                            }
                        },
                        {
                            title: 'BATTERY ACCESSORIES',
                            url: '\/battery-solutions\/battery-accessories.html',
                            url_resolver: {
                                id: 38,
                                type: 'CATEGORY',
                                url_key: 'battery-accessories'
                            }
                        }
                    ]
                },
                {
                    title: 'LADDERS',
                    url: '\/ladders.html',
                    url_resolver: {
                        id: 47,
                        type: 'CATEGORY',
                        url_key: 'ladders'
                    },
                    child: null
                },
                {
                    title: 'PROTECTION & TRIM',
                    url: '\/protection-equipment.html',
                    url_resolver: {
                        id: 48,
                        type: 'CATEGORY',
                        url_key: 'protection-equipment'
                    },
                    child: [
                        {
                            title: 'UNDER VEHICLE PROTECTION',
                            url: '\/protection-equipment\/under-vehicle-protection.html',
                            url_resolver: {
                                id: 50,
                                type: 'CATEGORY',
                                url_key: 'under-vehicle-protection'
                            }
                        },
                        {
                            title: 'BODY PROTECTION',
                            url: '\/protection-equipment\/vehicle-protection.html',
                            url_resolver: {
                                id: 49,
                                type: 'CATEGORY',
                                url_key: 'vehicle-protection'
                            }
                        },
                        {
                            title: 'LIGHT GUARDS',
                            url: '\/protection-equipment\/light-guards.html',
                            url_resolver: {
                                id: 51,
                                type: 'CATEGORY',
                                url_key: 'light-guards'
                            }
                        }
                    ]
                },
                {
                    title: 'SPARE WHEEL CARRIERS',
                    url: '\/bumpers-carriers.html',
                    url_resolver: {
                        id: 61,
                        type: 'CATEGORY',
                        url_key: 'bumpers-carriers'
                    },
                    child: null
                },
                {
                    title: 'MERCH',
                    url: '\/merch.html',
                    url_resolver: {
                        id: 115,
                        type: 'CATEGORY',
                        url_key: 'merch'
                    },
                    child: null
                }
            ]
        },
        {
            title: 'Shop by vehicle',
            url: null,
            color: '#a8be18',
            text_color: null,
            type: 'vehicle_finder',
            child: null
        },
        {
            title: 'Shop by adventure',
            url: null,
            color: '#de2f0c',
            text_color: null,
            type: 'adventure',
            child: [
                {
                    title: 'Camp',
                    url: '\/adventure\/camp',
                    url_resolver: null,
                    child: null
                },
                {
                    title: 'Expedition',
                    url: '\/adventure\/expedition',
                    url_resolver: null,
                    child: null
                },
                {
                    title: 'Fish',
                    url: '\/adventure\/fish',
                    url_resolver: null,
                    child: null
                },
                {
                    title: 'Surf',
                    url: '\/adventure\/surf',
                    url_resolver: null,
                    child: null
                },
                {
                    title: 'Snow',
                    url: '\/adventure\/snow',
                    url_resolver: null,
                    child: null
                },
                {
                    title: 'Paddle',
                    url: '\/adventure\/paddle',
                    url_resolver: null,
                    child: null
                },
                {
                    title: 'Bike',
                    url: '\/adventure\/bike',
                    url_resolver: null,
                    child: null
                }
            ]
        },
        {
            title: 'Explore',
            url: null,
            color: '#777',
            text_color: null,
            type: 'explore',
            child: [
                {
                    title: 'Slimline II Roof Rack',
                    url: '\/slimline2',
                    url_resolver: null,
                    child: null
                },
                {
                    title: 'About Us',
                    url: '\/about-us',
                    url_resolver: null,
                    child: null
                },
                {
                    title: 'Customer Commitment',
                    url: '\/customer-commitment',
                    url_resolver: null,
                    child: null
                },
                {
                    title: 'Gearzette',
                    url: '\/gearzette',
                    url_resolver: null,
                    child: null
                }
                // {
                //     title: 'The Pride',
                //     url: '\/http:\/\/frontrunnerpride.com',
                //     url_resolver: null,
                //     child: null
                // }
            ]
        },
        {
            title: 'About',
            url: '\/about-us',
            color: null,
            text_color: null,
            type: null,
            child: [
                {
                    title: 'Careers',
                    url: '\/jobs',
                    url_resolver: null,
                    child: null
                },
                {
                    title: 'Our story',
                    url: '\/about-us',
                    url_resolver: null,
                    child: null
                },
                {
                    title: 'Warranty',
                    url: '\/warranty',
                    url_resolver: null,
                    child: null
                },
                {
                    title: 'Returns',
                    url: '\/return-rights',
                    url_resolver: null,
                    child: null
                },
                {
                    title: 'Customer commitment',
                    url: '\/customer-commitment',
                    url_resolver: null,
                    child: null
                },
                {
                    title: 'Payment & Shipping Policies',
                    url: '\/shipping-policies',
                    url_resolver: null,
                    child: null
                },
                {
                    title: 'Legal Notice',
                    url: '\/legal-notice',
                    url_resolver: null,
                    child: null
                }
            ]
        },
        {
            title: 'Downloads',
            url: null,
            color: null,
            text_color: null,
            type: null,
            child: [
                {
                    title: 'Brochures',
                    url: '\/download-brochures',
                    url_resolver: null,
                    child: null
                },
                {
                    title: 'videos',
                    url: 'https:\/\/m.youtube.com\/user\/FrontRunnerOutfitter',
                    url_resolver: null,
                    child: null
                },
                {
                    title: 'Installation Guides',
                    url: '\/installation-guides',
                    url_resolver: null,
                    child: null
                }
            ]
        },
        {
            title: 'My Account',
            url: '\/my-account',
            color: null,
            text_color: null,
            type: null,
            child: null
        },
        {
            title: 'My Wishlist',
            url: '\/my-account\/my-wishlist',
            color: null,
            text_color: null,
            type: null,
            child: null
        },
        {
            title: 'Contact',
            url: '\/contact',
            color: null,
            text_color: null,
            type: null,
            child: null
        }
    ],
    desktopMenu: {
        header_ship: [
            {
                label: 'ALL RACK KITS SHIP FREE*',
                url: '/'
            }
        ],
        /* store_switcher: [
            {
                continent: [
                    {
                        countries: [
                            {
                                name: 'Anguilla',
                                country_code: 'AI',
                                region: 'americas',
                                store_id: '5',
                                store_url: '\/en\/us\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Antigua and Barbuda',
                                country_code: 'AG',
                                region: 'americas',
                                store_id: '5',
                                store_url: '\/en\/us\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Argentina',
                                country_code: 'AR',
                                region: 'americas',
                                store_id: '16',
                                store_url: '\/es\/us\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Aruba',
                                country_code: 'AW',
                                region: 'americas',
                                store_id: '5',
                                store_url: '\/en\/us\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Bahamas',
                                country_code: 'BS',
                                region: 'americas',
                                store_id: '5',
                                store_url: '\/en\/us\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Barbados',
                                country_code: 'BB',
                                region: 'americas',
                                store_id: '5',
                                store_url: '\/en\/us\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Belize',
                                country_code: 'BZ',
                                region: 'americas',
                                store_id: '16',
                                store_url: '\/es\/us\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Bermuda',
                                country_code: 'BM',
                                region: 'americas',
                                store_id: '5',
                                store_url: '\/en\/us\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Bolivia',
                                country_code: 'BO',
                                region: 'americas',
                                store_id: '16',
                                store_url: '\/es\/us\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Brazil',
                                country_code: 'BR',
                                region: 'americas',
                                store_id: '5',
                                store_url: '\/en\/us\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'British Virgin Islands',
                                country_code: 'VG',
                                region: 'americas',
                                store_id: '5',
                                store_url: '\/en\/us\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Canada',
                                country_code: 'CA',
                                region: 'americas',
                                store_id: '5',
                                store_url: '\/en\/us\/',
                                sort_order: '2',
                                pinned: '1'
                            },
                            {
                                name: 'Cayman Islands',
                                country_code: 'KY',
                                region: 'americas',
                                store_id: '5',
                                store_url: '\/en\/us\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Chile',
                                country_code: 'CL',
                                region: 'americas',
                                store_id: '16',
                                store_url: '\/es\/us\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Colombia',
                                country_code: 'CO',
                                region: 'americas',
                                store_id: '16',
                                store_url: '\/es\/us\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Costa Rica',
                                country_code: 'CR',
                                region: 'americas',
                                store_id: '16',
                                store_url: '\/es\/us\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Cuba',
                                country_code: 'CU',
                                region: 'americas',
                                store_id: '16',
                                store_url: '\/es\/us\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Cura\u00e7ao',
                                country_code: 'CW',
                                region: 'americas',
                                store_id: '5',
                                store_url: '\/en\/us\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Dominica',
                                country_code: 'DM',
                                region: 'americas',
                                store_id: '5',
                                store_url: '\/en\/us\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Dominican Republic',
                                country_code: 'DO',
                                region: 'americas',
                                store_id: '16',
                                store_url: '\/es\/us\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Ecuador',
                                country_code: 'EC',
                                region: 'americas',
                                store_id: '16',
                                store_url: '\/es\/us\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'El Salvador',
                                country_code: 'SV',
                                region: 'americas',
                                store_id: '16',
                                store_url: '\/es\/us\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Falkland Islands',
                                country_code: 'FK',
                                region: 'americas',
                                store_id: '5',
                                store_url: '\/en\/us\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Greenland',
                                country_code: 'GL',
                                region: 'americas',
                                store_id: '5',
                                store_url: '\/en\/us\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Grenada',
                                country_code: 'GD',
                                region: 'americas',
                                store_id: '5',
                                store_url: '\/en\/us\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Guatemala',
                                country_code: 'GT',
                                region: 'americas',
                                store_id: '16',
                                store_url: '\/es\/us\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Guyana',
                                country_code: 'GY',
                                region: 'americas',
                                store_id: '5',
                                store_url: '\/en\/us\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Haiti',
                                country_code: 'HT',
                                region: 'americas',
                                store_id: '5',
                                store_url: '\/en\/us\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Honduras',
                                country_code: 'HN',
                                region: 'americas',
                                store_id: '16',
                                store_url: '\/es\/us\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Jamaica',
                                country_code: 'JM',
                                region: 'americas',
                                store_id: '5',
                                store_url: '\/en\/us\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Mexico',
                                country_code: 'MX',
                                region: 'americas',
                                store_id: '16',
                                store_url: '\/es\/us\/',
                                sort_order: '3',
                                pinned: '1'
                            },
                            {
                                name: 'Montserrat',
                                country_code: 'MS',
                                region: 'americas',
                                store_id: '5',
                                store_url: '\/en\/us\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Nicaragua',
                                country_code: 'NI',
                                region: 'americas',
                                store_id: '16',
                                store_url: '\/es\/us\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Panama',
                                country_code: 'PA',
                                region: 'americas',
                                store_id: '16',
                                store_url: '\/es\/us\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Paraguay',
                                country_code: 'PY',
                                region: 'americas',
                                store_id: '16',
                                store_url: '\/es\/us\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Peru',
                                country_code: 'PE',
                                region: 'americas',
                                store_id: '16',
                                store_url: '\/es\/us\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Puerto Rico',
                                country_code: 'PR',
                                region: 'americas',
                                store_id: '16',
                                store_url: '\/es\/us\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Saint Barth\u00e9lemy',
                                country_code: 'BL',
                                region: 'americas',
                                store_id: '5',
                                store_url: '\/en\/us\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Saint Kitts and Nevis',
                                country_code: 'KN',
                                region: 'americas',
                                store_id: '5',
                                store_url: '\/en\/us\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Saint Lucia',
                                country_code: 'LC',
                                region: 'americas',
                                store_id: '5',
                                store_url: '\/en\/us\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Saint Martin',
                                country_code: 'MF',
                                region: 'americas',
                                store_id: '5',
                                store_url: '\/en\/us\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Saint Pierre and Miquelon',
                                country_code: 'PM',
                                region: 'americas',
                                store_id: '5',
                                store_url: '\/en\/us\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Saint Vincent and the Grenadines',
                                country_code: 'VC',
                                region: 'americas',
                                store_id: '5',
                                store_url: '\/en\/us\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Serranilla Bank',
                                country_code: 'SER',
                                region: 'americas',
                                store_id: '11',
                                store_url: '\/en\/int\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Sint Maarten',
                                country_code: 'SX',
                                region: 'americas',
                                store_id: '5',
                                store_url: '\/en\/us\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Suriname',
                                country_code: 'SR',
                                region: 'americas',
                                store_id: '5',
                                store_url: '\/en\/us\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Trinidad and Tobago',
                                country_code: 'TT',
                                region: 'americas',
                                store_id: '5',
                                store_url: '\/en\/us\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Turks and Caicos Islands',
                                country_code: 'TC',
                                region: 'americas',
                                store_id: '5',
                                store_url: '\/en\/us\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'USNB Guantanamo Bay',
                                country_code: 'USG',
                                region: 'americas',
                                store_id: '5',
                                store_url: '\/en\/us\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'United States',
                                country_code: 'US',
                                region: 'americas',
                                store_id: '5',
                                store_url: '\/en\/us\/',
                                sort_order: '1',
                                pinned: '1'
                            },
                            {
                                name: 'United States Virgin Islands',
                                country_code: 'VI',
                                region: 'americas',
                                store_id: '5',
                                store_url: '\/en\/us\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Uruguay',
                                country_code: 'UY',
                                region: 'americas',
                                store_id: '16',
                                store_url: '\/es\/us\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Venezuela',
                                country_code: 'VE',
                                region: 'americas',
                                store_id: '16',
                                store_url: '\/es\/us\/',
                                sort_order: '0',
                                pinned: '0'
                            }
                        ],
                        pinned_countries: [
                            {
                                name: 'United States',
                                country_code: 'US',
                                region: 'americas',
                                store_id: '5',
                                store_url: '\/en\/us\/',
                                sort_order: '1',
                                pinned: '1'
                            },
                            {
                                name: 'Canada',
                                country_code: 'CA',
                                region: 'americas',
                                store_id: '5',
                                store_url: '\/en\/us\/',
                                sort_order: '2',
                                pinned: '1'
                            },
                            {
                                name: 'Mexico',
                                country_code: 'MX',
                                region: 'americas',
                                store_id: '16',
                                store_url: '\/es\/us\/',
                                sort_order: '3',
                                pinned: '1'
                            }
                        ],
                        label: 'americas'
                    },
                    {
                        countries: [
                            {
                                name: 'Albania',
                                country_code: 'AL',
                                region: 'europe',
                                store_id: '9',
                                store_url: '\/en\/de\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Andorra',
                                country_code: 'AD',
                                region: 'europe',
                                store_id: '9',
                                store_url: '\/en\/de\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Austria',
                                country_code: 'AT',
                                region: 'europe',
                                store_id: '8',
                                store_url: '\/de\/de\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Belarus',
                                country_code: 'BY',
                                region: 'europe',
                                store_id: '9',
                                store_url: '\/en\/de\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Belgium',
                                country_code: 'BE',
                                region: 'europe',
                                store_id: '9',
                                store_url: '\/en\/de\/',
                                sort_order: '1',
                                pinned: '1'
                            },
                            {
                                name: 'Bosnia and Herzegovina',
                                country_code: 'BA',
                                region: 'europe',
                                store_id: '9',
                                store_url: '\/en\/de\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Bulgaria',
                                country_code: 'BG',
                                region: 'europe',
                                store_id: '9',
                                store_url: '\/en\/de\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Croatia',
                                country_code: 'HR',
                                region: 'europe',
                                store_id: '9',
                                store_url: '\/en\/de\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Czechia',
                                country_code: 'CZ',
                                region: 'europe',
                                store_id: '9',
                                store_url: '\/en\/de\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Denmark',
                                country_code: 'DK',
                                region: 'europe',
                                store_id: '9',
                                store_url: '\/en\/de\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Estonia',
                                country_code: 'EE',
                                region: 'europe',
                                store_id: '9',
                                store_url: '\/en\/de\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Faroe Islands',
                                country_code: 'FO',
                                region: 'europe',
                                store_id: '9',
                                store_url: '\/en\/de\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Finland',
                                country_code: 'FI',
                                region: 'europe',
                                store_id: '9',
                                store_url: '\/en\/de\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'France',
                                country_code: 'FR',
                                region: 'europe',
                                store_id: '12',
                                store_url: '\/fr\/be\/',
                                sort_order: '2',
                                pinned: '1'
                            },
                            {
                                name: 'Germany',
                                country_code: 'DE',
                                region: 'europe',
                                store_id: '8',
                                store_url: '\/de\/de\/',
                                sort_order: '3',
                                pinned: '1'
                            },
                            {
                                name: 'Gibraltar',
                                country_code: 'GI',
                                region: 'europe',
                                store_id: '9',
                                store_url: '\/en\/de\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Greece',
                                country_code: 'GR',
                                region: 'europe',
                                store_id: '9',
                                store_url: '\/en\/de\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Guernsey',
                                country_code: 'GG',
                                region: 'europe',
                                store_id: '9',
                                store_url: '\/en\/de\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Hungary',
                                country_code: 'HU',
                                region: 'europe',
                                store_id: '9',
                                store_url: '\/en\/de\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Iceland',
                                country_code: 'IS',
                                region: 'europe',
                                store_id: '9',
                                store_url: '\/en\/de\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Ireland',
                                country_code: 'IE',
                                region: 'europe',
                                store_id: '9',
                                store_url: '\/en\/de\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Isle of Man',
                                country_code: 'IM',
                                region: 'europe',
                                store_id: '9',
                                store_url: '\/en\/de\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Italy',
                                country_code: 'IT',
                                region: 'europe',
                                store_id: '9',
                                store_url: '\/en\/de\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Jersey',
                                country_code: 'JE',
                                region: 'europe',
                                store_id: '9',
                                store_url: '\/en\/de\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Kosovo',
                                country_code: 'XK',
                                region: 'europe',
                                store_id: '9',
                                store_url: '\/en\/de\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Latvia',
                                country_code: 'LV',
                                region: 'europe',
                                store_id: '9',
                                store_url: '\/en\/de\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Liechtenstein',
                                country_code: 'LI',
                                region: 'europe',
                                store_id: '8',
                                store_url: '\/de\/de\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Lithuania',
                                country_code: 'LT',
                                region: 'europe',
                                store_id: '9',
                                store_url: '\/en\/de\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Luxembourg',
                                country_code: 'LU',
                                region: 'europe',
                                store_id: '12',
                                store_url: '\/fr\/be\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Macedonia',
                                country_code: 'MK',
                                region: 'europe',
                                store_id: '9',
                                store_url: '\/en\/de\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Malta',
                                country_code: 'MT',
                                region: 'europe',
                                store_id: '9',
                                store_url: '\/en\/de\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Moldova',
                                country_code: 'MD',
                                region: 'europe',
                                store_id: '9',
                                store_url: '\/en\/de\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Monaco',
                                country_code: 'MC',
                                region: 'europe',
                                store_id: '12',
                                store_url: '\/fr\/be\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Montenegro',
                                country_code: 'ME',
                                region: 'europe',
                                store_id: '9',
                                store_url: '\/en\/de\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Netherlands',
                                country_code: 'NL',
                                region: 'europe',
                                store_id: '15',
                                store_url: '\/nl\/be\/',
                                sort_order: '4',
                                pinned: '1'
                            },
                            {
                                name: 'Norway',
                                country_code: 'NO',
                                region: 'europe',
                                store_id: '9',
                                store_url: '\/en\/de\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Poland',
                                country_code: 'PL',
                                region: 'europe',
                                store_id: '9',
                                store_url: '\/en\/de\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Portugal',
                                country_code: 'PT',
                                region: 'europe',
                                store_id: '9',
                                store_url: '\/en\/de\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Romania',
                                country_code: 'RO',
                                region: 'europe',
                                store_id: '9',
                                store_url: '\/en\/de\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Russia',
                                country_code: 'RU',
                                region: 'europe',
                                store_id: '9',
                                store_url: '\/en\/de\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'San Marino',
                                country_code: 'SM',
                                region: 'europe',
                                store_id: '9',
                                store_url: '\/en\/de\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Serbia',
                                country_code: 'RS',
                                region: 'europe',
                                store_id: '9',
                                store_url: '\/en\/de\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Slovakia',
                                country_code: 'SK',
                                region: 'europe',
                                store_id: '9',
                                store_url: '\/en\/de\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Slovenia',
                                country_code: 'SI',
                                region: 'europe',
                                store_id: '9',
                                store_url: '\/en\/de\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Spain',
                                country_code: 'ES',
                                region: 'europe',
                                store_id: '14',
                                store_url: '\/es\/be\/',
                                sort_order: '5',
                                pinned: '1'
                            },
                            {
                                name: 'Sweden',
                                country_code: 'SE',
                                region: 'europe',
                                store_id: '9',
                                store_url: '\/en\/de\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Switzerland',
                                country_code: 'CH',
                                region: 'europe',
                                store_id: '8',
                                store_url: '\/de\/de\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Ukraine',
                                country_code: 'UA',
                                region: 'europe',
                                store_id: '9',
                                store_url: '\/en\/de\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'United Kingdom',
                                country_code: 'GB',
                                region: 'europe',
                                store_id: '17',
                                store_url: '\/en\/uk\/',
                                sort_order: '6',
                                pinned: '1'
                            },
                            {
                                name: 'Vatican City',
                                country_code: 'VA',
                                region: 'europe',
                                store_id: '9',
                                store_url: '\/en\/de\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: '\u00c5land Islands',
                                country_code: 'AX',
                                region: 'europe',
                                store_id: '9',
                                store_url: '\/en\/de\/',
                                sort_order: '0',
                                pinned: '0'
                            }
                        ],
                        pinned_countries: [
                            {
                                name: 'Belgium',
                                country_code: 'BE',
                                region: 'europe',
                                store_id: '9',
                                store_url: '\/en\/de\/',
                                sort_order: '1',
                                pinned: '1'
                            },
                            {
                                name: 'France',
                                country_code: 'FR',
                                region: 'europe',
                                store_id: '12',
                                store_url: '\/fr\/be\/',
                                sort_order: '2',
                                pinned: '1'
                            },
                            {
                                name: 'Germany',
                                country_code: 'DE',
                                region: 'europe',
                                store_id: '8',
                                store_url: '\/de\/de\/',
                                sort_order: '3',
                                pinned: '1'
                            },
                            {
                                name: 'Netherlands',
                                country_code: 'NL',
                                region: 'europe',
                                store_id: '15',
                                store_url: '\/nl\/be\/',
                                sort_order: '4',
                                pinned: '1'
                            },
                            {
                                name: 'Spain',
                                country_code: 'ES',
                                region: 'europe',
                                store_id: '14',
                                store_url: '\/es\/be\/',
                                sort_order: '5',
                                pinned: '1'
                            },
                            {
                                name: 'United Kingdom',
                                country_code: 'GB',
                                region: 'europe',
                                store_id: '17',
                                store_url: '\/en\/uk\/',
                                sort_order: '6',
                                pinned: '1'
                            }
                        ],
                        label: 'europe'
                    },
                    {
                        countries: [
                            {
                                name: 'Algeria',
                                country_code: 'DZ',
                                region: 'africa',
                                store_id: '9',
                                store_url: '\/en\/de\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Angola',
                                country_code: 'AO',
                                region: 'africa',
                                store_id: '9',
                                store_url: '\/en\/de\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Benin',
                                country_code: 'BJ',
                                region: 'africa',
                                store_id: '12',
                                store_url: '\/fr\/be\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Botswana',
                                country_code: 'BW',
                                region: 'africa',
                                store_id: '4',
                                store_url: '\/en\/za\/',
                                sort_order: '2',
                                pinned: '1'
                            },
                            {
                                name: 'Burkina Faso',
                                country_code: 'BF',
                                region: 'africa',
                                store_id: '12',
                                store_url: '\/fr\/be\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Burundi',
                                country_code: 'BI',
                                region: 'africa',
                                store_id: '12',
                                store_url: '\/fr\/be\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Cameroon',
                                country_code: 'CM',
                                region: 'africa',
                                store_id: '12',
                                store_url: '\/fr\/be\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Cape Verde',
                                country_code: 'CV',
                                region: 'africa',
                                store_id: '9',
                                store_url: '\/en\/de\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Central African Republic',
                                country_code: 'CF',
                                region: 'africa',
                                store_id: '12',
                                store_url: '\/fr\/be\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Chad',
                                country_code: 'TD',
                                region: 'africa',
                                store_id: '12',
                                store_url: '\/fr\/be\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Comoros',
                                country_code: 'KM',
                                region: 'africa',
                                store_id: '12',
                                store_url: '\/fr\/be\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'DR Congo',
                                country_code: 'CD',
                                region: 'africa',
                                store_id: '9',
                                store_url: '\/en\/de\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Djibouti',
                                country_code: 'DJ',
                                region: 'africa',
                                store_id: '12',
                                store_url: '\/fr\/be\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Egypt',
                                country_code: 'EG',
                                region: 'africa',
                                store_id: '9',
                                store_url: '\/en\/de\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Equatorial Guinea',
                                country_code: 'GQ',
                                region: 'africa',
                                store_id: '12',
                                store_url: '\/fr\/be\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Eritrea',
                                country_code: 'ER',
                                region: 'africa',
                                store_id: '9',
                                store_url: '\/en\/de\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Ethiopia',
                                country_code: 'ET',
                                region: 'africa',
                                store_id: '9',
                                store_url: '\/en\/de\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Gabon',
                                country_code: 'GA',
                                region: 'africa',
                                store_id: '12',
                                store_url: '\/fr\/be\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Gambia',
                                country_code: 'GM',
                                region: 'africa',
                                store_id: '9',
                                store_url: '\/en\/de\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Ghana',
                                country_code: 'GH',
                                region: 'africa',
                                store_id: '9',
                                store_url: '\/en\/de\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Guinea',
                                country_code: 'GN',
                                region: 'africa',
                                store_id: '12',
                                store_url: '\/fr\/be\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Guinea-Bissau',
                                country_code: 'GW',
                                region: 'africa',
                                store_id: '9',
                                store_url: '\/en\/de\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Ivory Coast',
                                country_code: 'CI',
                                region: 'africa',
                                store_id: '9',
                                store_url: '\/en\/de\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Kenya',
                                country_code: 'KE',
                                region: 'africa',
                                store_id: '4',
                                store_url: '\/en\/za\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Lesotho',
                                country_code: 'LS',
                                region: 'africa',
                                store_id: '4',
                                store_url: '\/en\/za\/',
                                sort_order: '3',
                                pinned: '1'
                            },
                            {
                                name: 'Liberia',
                                country_code: 'LR',
                                region: 'africa',
                                store_id: '9',
                                store_url: '\/en\/de\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Libya',
                                country_code: 'LY',
                                region: 'africa',
                                store_id: '9',
                                store_url: '\/en\/de\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Madagascar',
                                country_code: 'MG',
                                region: 'africa',
                                store_id: '12',
                                store_url: '\/fr\/be\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Malawi',
                                country_code: 'MW',
                                region: 'africa',
                                store_id: '9',
                                store_url: '\/en\/de\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Mali',
                                country_code: 'ML',
                                region: 'africa',
                                store_id: '12',
                                store_url: '\/fr\/be\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Mauritania',
                                country_code: 'MR',
                                region: 'africa',
                                store_id: '9',
                                store_url: '\/en\/de\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Mauritius',
                                country_code: 'MU',
                                region: 'africa',
                                store_id: '9',
                                store_url: '\/en\/de\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Morocco',
                                country_code: 'MA',
                                region: 'africa',
                                store_id: '9',
                                store_url: '\/en\/de\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Mozambique',
                                country_code: 'MZ',
                                region: 'africa',
                                store_id: '4',
                                store_url: '\/en\/za\/',
                                sort_order: '4',
                                pinned: '1'
                            },
                            {
                                name: 'Namibia',
                                country_code: 'NA',
                                region: 'africa',
                                store_id: '4',
                                store_url: '\/en\/za\/',
                                sort_order: '5',
                                pinned: '1'
                            },
                            {
                                name: 'Niger',
                                country_code: 'NE',
                                region: 'africa',
                                store_id: '12',
                                store_url: '\/fr\/be\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Nigeria',
                                country_code: 'NG',
                                region: 'africa',
                                store_id: '9',
                                store_url: '\/en\/de\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Republic of the Congo',
                                country_code: 'CG',
                                region: 'africa',
                                store_id: '12',
                                store_url: '\/fr\/be\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Rwanda',
                                country_code: 'RW',
                                region: 'africa',
                                store_id: '12',
                                store_url: '\/fr\/be\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Saint Helena',
                                country_code: 'SH',
                                region: 'africa',
                                store_id: '9',
                                store_url: '\/en\/de\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Senegal',
                                country_code: 'SN',
                                region: 'africa',
                                store_id: '12',
                                store_url: '\/fr\/be\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Seychelles',
                                country_code: 'SC',
                                region: 'africa',
                                store_id: '12',
                                store_url: '\/fr\/be\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Sierra Leone',
                                country_code: 'SL',
                                region: 'africa',
                                store_id: '9',
                                store_url: '\/en\/de\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Somalia',
                                country_code: 'SO',
                                region: 'africa',
                                store_id: '9',
                                store_url: '\/en\/de\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Somaliland',
                                country_code: 'SOL',
                                region: 'africa',
                                store_id: '9',
                                store_url: '\/en\/de\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'South Africa',
                                country_code: 'ZA',
                                region: 'africa',
                                store_id: '4',
                                store_url: '\/en\/za\/',
                                sort_order: '1',
                                pinned: '1'
                            },
                            {
                                name: 'South Sudan',
                                country_code: 'SS',
                                region: 'africa',
                                store_id: '9',
                                store_url: '\/en\/de\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Sudan',
                                country_code: 'SD',
                                region: 'africa',
                                store_id: '9',
                                store_url: '\/en\/de\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Swaziland',
                                country_code: 'SZ',
                                region: 'africa',
                                store_id: '4',
                                store_url: '\/en\/za\/',
                                sort_order: '6',
                                pinned: '1'
                            },
                            {
                                name: 'S\u00e3o Tom\u00e9 and Pr\u00edncipe',
                                country_code: 'ST',
                                region: 'africa',
                                store_id: '9',
                                store_url: '\/en\/de\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Tanzania',
                                country_code: 'TZ',
                                region: 'africa',
                                store_id: '9',
                                store_url: '\/en\/de\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Togo',
                                country_code: 'TG',
                                region: 'africa',
                                store_id: '12',
                                store_url: '\/fr\/be\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Tunisia',
                                country_code: 'TN',
                                region: 'africa',
                                store_id: '9',
                                store_url: '\/en\/de\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Uganda',
                                country_code: 'UG',
                                region: 'africa',
                                store_id: '9',
                                store_url: '\/en\/de\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Western Sahara',
                                country_code: 'EH',
                                region: 'africa',
                                store_id: '9',
                                store_url: '\/en\/de\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Zambia',
                                country_code: 'ZM',
                                region: 'africa',
                                store_id: '4',
                                store_url: '\/en\/za\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Zimbabwe',
                                country_code: 'ZW',
                                region: 'africa',
                                store_id: '4',
                                store_url: '\/en\/za\/',
                                sort_order: '0',
                                pinned: '0'
                            }
                        ],
                        pinned_countries: [
                            {
                                name: 'South Africa',
                                country_code: 'ZA',
                                region: 'africa',
                                store_id: '4',
                                store_url: '\/en\/za\/',
                                sort_order: '1',
                                pinned: '1'
                            },
                            {
                                name: 'Botswana',
                                country_code: 'BW',
                                region: 'africa',
                                store_id: '4',
                                store_url: '\/en\/za\/',
                                sort_order: '2',
                                pinned: '1'
                            },
                            {
                                name: 'Lesotho',
                                country_code: 'LS',
                                region: 'africa',
                                store_id: '4',
                                store_url: '\/en\/za\/',
                                sort_order: '3',
                                pinned: '1'
                            },
                            {
                                name: 'Mozambique',
                                country_code: 'MZ',
                                region: 'africa',
                                store_id: '4',
                                store_url: '\/en\/za\/',
                                sort_order: '4',
                                pinned: '1'
                            },
                            {
                                name: 'Namibia',
                                country_code: 'NA',
                                region: 'africa',
                                store_id: '4',
                                store_url: '\/en\/za\/',
                                sort_order: '5',
                                pinned: '1'
                            },
                            {
                                name: 'Swaziland',
                                country_code: 'SZ',
                                region: 'africa',
                                store_id: '4',
                                store_url: '\/en\/za\/',
                                sort_order: '6',
                                pinned: '1'
                            }
                        ],
                        label: 'africa'
                    },
                    {
                        countries: [
                            {
                                name: 'American Samoa',
                                country_code: 'AS',
                                region: 'oceania',
                                store_id: '5',
                                store_url: '\/en\/us\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Ashmore and Cartier Is.',
                                country_code: 'ATC',
                                region: 'oceania',
                                store_id: '5',
                                store_url: '\/en\/us\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Australia',
                                country_code: 'AU',
                                region: 'oceania',
                                store_id: '7',
                                store_url: '\/en\/au\/',
                                sort_order: '1',
                                pinned: '1'
                            },
                            {
                                name: 'Cook Islands',
                                country_code: 'CK',
                                region: 'oceania',
                                store_id: '5',
                                store_url: '\/en\/us\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Coral Sea Is.',
                                country_code: 'CSI',
                                region: 'oceania',
                                store_id: '5',
                                store_url: '\/en\/us\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Fiji',
                                country_code: 'FJ',
                                region: 'oceania',
                                store_id: '5',
                                store_url: '\/en\/us\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'French Polynesia',
                                country_code: 'PF',
                                region: 'oceania',
                                store_id: '5',
                                store_url: '\/en\/us\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Guam',
                                country_code: 'GU',
                                region: 'oceania',
                                store_id: '5',
                                store_url: '\/en\/us\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Kiribati',
                                country_code: 'KI',
                                region: 'oceania',
                                store_id: '5',
                                store_url: '\/en\/us\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Marshall Islands',
                                country_code: 'MH',
                                region: 'oceania',
                                store_id: '5',
                                store_url: '\/en\/us\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Micronesia',
                                country_code: 'FM',
                                region: 'oceania',
                                store_id: '5',
                                store_url: '\/en\/us\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Nauru',
                                country_code: 'NR',
                                region: 'oceania',
                                store_id: '5',
                                store_url: '\/en\/us\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'New Caledonia',
                                country_code: 'NC',
                                region: 'oceania',
                                store_id: '5',
                                store_url: '\/en\/us\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'New Zealand',
                                country_code: 'NZ',
                                region: 'oceania',
                                store_id: '7',
                                store_url: '\/en\/au\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Niue',
                                country_code: 'NU',
                                region: 'oceania',
                                store_id: '5',
                                store_url: '\/en\/us\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Norfolk Island',
                                country_code: 'NF',
                                region: 'oceania',
                                store_id: '5',
                                store_url: '\/en\/us\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Northern Mariana Islands',
                                country_code: 'MP',
                                region: 'oceania',
                                store_id: '5',
                                store_url: '\/en\/us\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Palau',
                                country_code: 'PW',
                                region: 'oceania',
                                store_id: '5',
                                store_url: '\/en\/us\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Papua New Guinea',
                                country_code: 'PG',
                                region: 'oceania',
                                store_id: '5',
                                store_url: '\/en\/us\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Pitcairn Islands',
                                country_code: 'PN',
                                region: 'oceania',
                                store_id: '5',
                                store_url: '\/en\/us\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Samoa',
                                country_code: 'WS',
                                region: 'oceania',
                                store_id: '5',
                                store_url: '\/en\/us\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Solomon Islands',
                                country_code: 'SB',
                                region: 'oceania',
                                store_id: '5',
                                store_url: '\/en\/us\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Tonga',
                                country_code: 'TO',
                                region: 'oceania',
                                store_id: '5',
                                store_url: '\/en\/us\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Tuvalu',
                                country_code: 'TV',
                                region: 'oceania',
                                store_id: '5',
                                store_url: '\/en\/us\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Vanuatu',
                                country_code: 'VU',
                                region: 'oceania',
                                store_id: '12',
                                store_url: '\/fr\/be\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Wallis and Futuna',
                                country_code: 'WF',
                                region: 'oceania',
                                store_id: '5',
                                store_url: '\/en\/us\/',
                                sort_order: '0',
                                pinned: '0'
                            }
                        ],
                        pinned_countries: [
                            {
                                name: 'Australia',
                                country_code: 'AU',
                                region: 'oceania',
                                store_id: '7',
                                store_url: '\/en\/au\/',
                                sort_order: '1',
                                pinned: '1'
                            }
                        ],
                        label: 'oceania'
                    },
                    {
                        countries: [
                            {
                                name: 'Afghanistan',
                                country_code: 'AF',
                                region: 'asia',
                                store_id: '5',
                                store_url: '\/en\/us\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Akrotiri',
                                country_code: 'WSB',
                                region: 'asia',
                                store_id: '9',
                                store_url: '\/en\/de\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Armenia',
                                country_code: 'AM',
                                region: 'asia',
                                store_id: '9',
                                store_url: '\/en\/de\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Azerbaijan',
                                country_code: 'AZ',
                                region: 'asia',
                                store_id: '9',
                                store_url: '\/en\/de\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Baikonur',
                                country_code: 'KAB',
                                region: 'asia',
                                store_id: '9',
                                store_url: '\/en\/de\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Bangladesh',
                                country_code: 'BD',
                                region: 'asia',
                                store_id: '9',
                                store_url: '\/en\/de\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Bhutan',
                                country_code: 'BT',
                                region: 'asia',
                                store_id: '9',
                                store_url: '\/en\/de\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Brunei',
                                country_code: 'BN',
                                region: 'asia',
                                store_id: '9',
                                store_url: '\/en\/de\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Cambodia',
                                country_code: 'KH',
                                region: 'asia',
                                store_id: '9',
                                store_url: '\/en\/de\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'China',
                                country_code: 'CN',
                                region: 'asia',
                                store_id: '9',
                                store_url: '\/en\/de\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Dhekelia',
                                country_code: 'ESB',
                                region: 'asia',
                                store_id: '9',
                                store_url: '\/en\/de\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Georgia',
                                country_code: 'GE',
                                region: 'asia',
                                store_id: '9',
                                store_url: '\/en\/de\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Hong Kong',
                                country_code: 'HK',
                                region: 'asia',
                                store_id: '9',
                                store_url: '\/en\/de\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'India',
                                country_code: 'IN',
                                region: 'asia',
                                store_id: '9',
                                store_url: '\/en\/de\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Indonesia',
                                country_code: 'ID',
                                region: 'asia',
                                store_id: '9',
                                store_url: '\/en\/de\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Japan',
                                country_code: 'JP',
                                region: 'asia',
                                store_id: '9',
                                store_url: '\/en\/de\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Kazakhstan',
                                country_code: 'KZ',
                                region: 'asia',
                                store_id: '9',
                                store_url: '\/en\/de\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Kyrgyzstan',
                                country_code: 'KG',
                                region: 'asia',
                                store_id: '9',
                                store_url: '\/en\/de\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Laos',
                                country_code: 'LA',
                                region: 'asia',
                                store_id: '9',
                                store_url: '\/en\/de\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Macau',
                                country_code: 'MO',
                                region: 'asia',
                                store_id: '9',
                                store_url: '\/en\/de\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Malaysia',
                                country_code: 'MY',
                                region: 'asia',
                                store_id: '9',
                                store_url: '\/en\/de\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Maldives',
                                country_code: 'MV',
                                region: 'asia',
                                store_id: '9',
                                store_url: '\/en\/de\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Mongolia',
                                country_code: 'MN',
                                region: 'asia',
                                store_id: '9',
                                store_url: '\/en\/de\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Myanmar',
                                country_code: 'MM',
                                region: 'asia',
                                store_id: '5',
                                store_url: '\/en\/us\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Nepal',
                                country_code: 'NP',
                                region: 'asia',
                                store_id: '9',
                                store_url: '\/en\/de\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'North Korea',
                                country_code: 'KP',
                                region: 'asia',
                                store_id: '11',
                                store_url: '\/en\/int\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Pakistan',
                                country_code: 'PK',
                                region: 'asia',
                                store_id: '9',
                                store_url: '\/en\/de\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Philippines',
                                country_code: 'PH',
                                region: 'asia',
                                store_id: '9',
                                store_url: '\/en\/de\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Scarborough Reef',
                                country_code: 'SCR',
                                region: 'asia',
                                store_id: '9',
                                store_url: '\/en\/de\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Siachen Glacier',
                                country_code: 'KAS',
                                region: 'asia',
                                store_id: '9',
                                store_url: '\/en\/de\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Singapore',
                                country_code: 'SG',
                                region: 'asia',
                                store_id: '9',
                                store_url: '\/en\/de\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'South Korea',
                                country_code: 'KR',
                                region: 'asia',
                                store_id: '9',
                                store_url: '\/en\/de\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Spratly Is.',
                                country_code: 'PGA',
                                region: 'asia',
                                store_id: '9',
                                store_url: '\/en\/de\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Sri Lanka',
                                country_code: 'LK',
                                region: 'asia',
                                store_id: '9',
                                store_url: '\/en\/de\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Taiwan',
                                country_code: 'TW',
                                region: 'asia',
                                store_id: '9',
                                store_url: '\/en\/de\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Tajikistan',
                                country_code: 'TJ',
                                region: 'asia',
                                store_id: '9',
                                store_url: '\/en\/de\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Thailand',
                                country_code: 'TH',
                                region: 'asia',
                                store_id: '9',
                                store_url: '\/en\/de\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Timor-Leste',
                                country_code: 'TL',
                                region: 'asia',
                                store_id: '9',
                                store_url: '\/en\/de\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Turkmenistan',
                                country_code: 'TM',
                                region: 'asia',
                                store_id: '9',
                                store_url: '\/en\/de\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Uzbekistan',
                                country_code: 'UZ',
                                region: 'asia',
                                store_id: '9',
                                store_url: '\/en\/de\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Vietnam',
                                country_code: 'VN',
                                region: 'asia',
                                store_id: '9',
                                store_url: '\/en\/de\/',
                                sort_order: '0',
                                pinned: '0'
                            }
                        ],
                        pinned_countries: null,
                        label: 'asia'
                    },
                    {
                        countries: [
                            {
                                name: 'Bahrain',
                                country_code: 'BH',
                                region: 'middle east',
                                store_id: '9',
                                store_url: '\/en\/de\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Cyprus',
                                country_code: 'CY',
                                region: 'middle east',
                                store_id: '9',
                                store_url: '\/en\/de\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Iran',
                                country_code: 'IR',
                                region: 'middle east',
                                store_id: '5',
                                store_url: '\/en\/us\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Iraq',
                                country_code: 'IQ',
                                region: 'middle east',
                                store_id: '5',
                                store_url: '\/en\/us\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Israel',
                                country_code: 'IL',
                                region: 'middle east',
                                store_id: '9',
                                store_url: '\/en\/de\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Jordan',
                                country_code: 'JO',
                                region: 'middle east',
                                store_id: '9',
                                store_url: '\/en\/de\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Kuwait',
                                country_code: 'KW',
                                region: 'middle east',
                                store_id: '9',
                                store_url: '\/en\/de\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Lebanon',
                                country_code: 'LB',
                                region: 'middle east',
                                store_id: '9',
                                store_url: '\/en\/de\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Oman',
                                country_code: 'OM',
                                region: 'middle east',
                                store_id: '9',
                                store_url: '\/en\/de\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Palestine',
                                country_code: 'PS',
                                region: 'middle east',
                                store_id: '9',
                                store_url: '\/en\/de\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Qatar',
                                country_code: 'QA',
                                region: 'middle east',
                                store_id: '9',
                                store_url: '\/en\/de\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Saudi Arabia',
                                country_code: 'SA',
                                region: 'middle east',
                                store_id: '9',
                                store_url: '\/en\/de\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Syria',
                                country_code: 'SY',
                                region: 'middle east',
                                store_id: '11',
                                store_url: '\/en\/int\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Turkey',
                                country_code: 'TR',
                                region: 'middle east',
                                store_id: '9',
                                store_url: '\/en\/de\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'United Arab Emirates',
                                country_code: 'AE',
                                region: 'middle east',
                                store_id: '9',
                                store_url: '\/en\/de\/',
                                sort_order: '0',
                                pinned: '0'
                            },
                            {
                                name: 'Yemen',
                                country_code: 'YE',
                                region: 'middle east',
                                store_id: '11',
                                store_url: '\/en\/int\/',
                                sort_order: '0',
                                pinned: '0'
                            }
                        ],
                        pinned_countries: null,
                        label: 'middle east'
                    }
                ],
                label: 'Choose Your Country\/Region'
            }
        ], */
        header_content: [{
            logo: [{
                logo: '/media\/skin\/images\/logo-white-bold-wide.svg', checkout_logo: '/media\/skin\/images\/logo.svg', url: '/', alt: null
            }],
            shop: [{
                category: [{
                    sub_category: [{
                        name: 'Roof Racks', image: '/media\/catalog\/category\/Roof_Rack_Kits_600x315_Block.jpg', category_id: '8', url: '/roof-racks\/slimline-ii-roof-rack-kits.html', description: 'Front Runner Dometic Roof Rack Kits include a Front Runner Dometic off-road rated all aluminum roof rack tray, wind deflector, and the vehicle specific mounting system. All hardware components needed to install the tray on a specific vehicle are included. Installation guides are available in the INSTALL tab of each product.  SHOP BY VEHICLE to find the rack kits available for your vehicle, or browse to shop for an appropriate rack.'
                    }, {
                        name: 'Factory\/oem Rail Roof Racks', image: '/media\/catalog\/category\/Factory-OEM_Rail_Rack_Kits_600x315_Block.jpg', category_id: '78', url: '/roof-racks\/factory-roof-rail-rack-kits.html', description: 'Factory\/OEM Rail Roof Kits include a Front Runner Dometic off-road rated all aluminum roof rack tray, wind deflector, and a mounting system compatible with existing factory \/ OEM roof rails. All hardware components needed to install the tray on a specific vehicle included. SHOP BY VEHICLE and use our vehicle selection tool to find a rack that is right for your vehicle. Or browse to shop for the rack size appropriate for your roof top. '
                    }, {
                        name: 'Pick-up Truck Bed Racks', image: '/media\/catalog\/category\/Pick-Up_Truck_Bed_Kits_600x315_Block.jpg', category_id: '84', url: '/roof-racks\/pick-up-bed-racks.html', description: 'Take your truck storage capacity to the next level with a Front Runner Dometic pick-up \/ bakkie Bed Rack. Our Bed Rack Kits consist of a Front Runner Dometic off-road rated all aluminum roof rack tray and a truck specific mounting system. SHOP BY VEHICLE and use our vehicle selection tool to find the bed rack kits available for your vehicle, or browse to shop for an appropriate size rack. Installation guides are available for download in the INSTALL tab of each product.  '
                    }, {
                        name: 'Canopy\/cap\/trailer Racks ', image: '/media\/catalog\/category\/Canopy-Cap-Trailer_Rack_Kits_Block.jpg', category_id: '90', url: '/roof-racks\/canopy-cap-trailer-racks.html', description: 'Our Canopy\/Cap\/Trailer Rack Kits consist of a Front Runner Dometic off-road rated all aluminum roof rack tray and an appropriately sized track and foot mounting system. The included track can be easily drilled for the end user to customize placement of the rack mounting system. Browse to shop for an appropriate size rack for your camper shell \/ canopy cap or trailer top.  Need help choosing which solution is right for you?  Simply contact us.'
                    }, {
                        name: 'Expedition Rails', image: '/media\/catalog\/category\/Expedition_Rails_600x315_Block.jpg', category_id: '88', url: '/roof-racks\/rack-expedition-rails.html', description: 'Convert any size Slimline II Rack to an expedition style rack at any time. Available in full perimeter kits, side kits, and front or back kits. The Expedition Rails are engineered for full functionality and built with the same durability and strength as all Front Runner Dometic Roof Racks. '
                    }, {
                        name: 'Interior Racks', image: '/media\/catalog\/category\/Interior_Racks_600x315_Block.jpg', category_id: '117', url: '/roof-racks\/interior-racks.html', description: 'Front Runner Dometic Roof Racks aren\u2019t just for roofs anymore!   Mount many of the same Front Runner Dometic Roof Rack Accessories to the top, bottom and sides of an interior rack to increase storage space and optimize organization.   \r\n'
                    }, {
                        name: 'Rack Components', image: '/media\/catalog\/category\/Rack_Components_600x315_Block_2.jpg', category_id: '9', url: '/roof-racks\/rack-components.html', description: 'Find spares, additional components and replacement parts for Front Runner Dometic Rack Trays and Mounting Systems.  Browse here to shop for additional slats to turn a Front Runner Dometic Rack Tray into a platform, different mounting systems to repurpose a Front Runner Dometic Tray for another vehicle, or to purchase feet and track to create a custom installation.'
                    }],
                    name: 'Racks',
                    image: '/media\/catalog\/category\/Block_35.jpg',
                    category_id: '3',
                    url: '/roof-racks.html',
                    description: "Find strong, lightweight, versatile, and modular off-road rated racks for roof tops, pick-up\/bakkie beds, canopy tops, trailers and vehicle interiors. Options available to add Expedition Rails and additional slats to create solid platforms. Use 50+ accessories to mount gear and toys to the top, bottom or sides of the rack.  Front Runner Dometic's Slimline II Rack is one of the most intelligent vehicle based storage systems in the world. All Rack Kits SHIP FREE.*"
                },
                {
                    sub_category: [{
                        name: 'Sport', image: '/media\/catalog\/category\/Block.jpg', category_id: '108', url: '/rack-accessories\/sport-accessories.html', description: 'Browse and shop Front Runner Dometic\u2019s off-road tough gear transport solutions. Front Runner Dometic\u2019s simple bolt on system makes arranging accessories across the top, bottom or sides of the rack effortless. Secure bikes, surfboards, wakeboards, SUPs, skis, fishing poles, snowboards, canoes\/kayaks, kite boards and more. A few clicks is all it takes to design and customize the perfect sport gear carrying system.'
                    }, {
                        name: 'Camp', image: '/media\/catalog\/category\/Block_1.jpg', category_id: '116', url: '/rack-accessories\/camp-accessories.html', description: 'Browse and shop Front Runner Dometic\u2019s off-road tough gear transport solutions. Front Runner Dometic\u2019s simple bolt on system makes arranging accessories across the top, bottom or sides of the rack effortless. Secure water tanks, lights, shower arms, propane\/gas tanks, cast iron pots, roof top tents, chairs, firewood, bbq\/braai cooking grates, tables and more. A few clicks is all it takes to design and customize the perfect camping gear carrying system.'
                    }, {
                        name: 'Storage', image: '/media\/catalog\/category\/Block_2.jpg', category_id: '107', url: '/rack-accessories\/storage-accessories.html', description: 'Browse and shop Front Runner Dometic\u2019s off-road tough gear transport solutions. Front Runner Dometic\u2019s simple bolt on system makes arranging accessories across the top, bottom or sides of the rack effortless. Secure axes, spade\/shovels, jacks, rescue tracks, jerry cans, Wolf Packs, tyres\/tires, luggage and more. A few clicks is all it takes to design and customize the perfect gear carrying system.'
                    }],
                    name: 'Rack Accessories',
                    image: '/media\/catalog\/category\/rack_accessory_menu_2018.jpg',
                    category_id: '11',
                    url: '/rack-accessories.html',
                    description: 'Front Runner Dometic Rack Accessories are built to endure the world\u2019s roughest terrain while still offering easy on and off mounting as well as flexibility in arranging gear across the top, bottom or sides of the rack. Check out Front Runner Dometic\u2019s 50+ solutions for securing outdoor sports gear, camping supplies, water, off-road rescue equipment, roof top tents, and much more. Start customizing the perfect off-road tough rack system now!'
                },
                {
                    sub_category: [{
                        name: 'Tents & Awnings', image: '/media\/catalog\/category\/Block_4.jpg', category_id: '17', url: '/camping-gear\/tents-awnings.html', description: 'Front Runner Dometic shade and shelter products offer protection from the elements with a focus on quality, style and function. Browse to shop for Front Runner Dometic roof top tents, awnings, ground tents, and accessories.'
                    }, {
                        name: 'Camp Kitchen', image: '/media\/catalog\/category\/Block2.jpg', category_id: '19', url: '/camping-gear\/camp-kitchen.html', description: 'Front Runner Dometic has developed a line of tools to efficiently prep, cook, and eat outdoors. Our designers are tasked with a simple mission - to create products that inspire a mini revelation. It is Front Runner Dometic\u2019s goal to offer \u201chow did I ever camp without these\u201d products like our campfire cooking grate and kitchen utensil roll.'
                    }, {
                        name: 'Chairs & Tables', image: '/media\/catalog\/category\/Block_6.jpg', category_id: '20', url: '/camping-gear\/chairs-tables.html', description: 'Create a home away from home with gear that is comfortable, sturdy, functional, and travels compact. The Front Runner Dometic Expander Chairs fold down to 1\/17th their size. The Front Runner Dometic line of standard and pro stainless steel camp tables can be stored and locked away underneath all Front Runner Dometic racks. Our campsite basics are anything but.'
                    }, {
                        name: 'Storage & Bags', image: '/media\/catalog\/category\/Block_7.jpg', category_id: '21', url: '/camping-gear\/storage-bags.html', description: 'Get organized with Front Runner Dometic\u2019s carrying and storage solutions for campsites. Wolf Packs and Cub packs are ideal for moving gear in and out of vehicles and stacking for staging, prep, and additional work surfaces. Front Runner Dometic\u2019s storage systems nest, collapse, and \/ or fold for space saving convenience on the road and off.'
                    }, {
                        name: 'Tools & Other', image: '/media\/catalog\/category\/Block_8.jpg', category_id: '22', url: '/camping-gear\/other-camping-gear.html', description: 'Axes, shovels, first aid kits, saws, showers and other specialty products curated to compliment Front Runner Dometic\u2019s camping and rack accessories.'
                    }],
                    name: 'Camping',
                    image: '/media\/catalog\/category\/Block_3.jpg',
                    category_id: '18',
                    url: '/camping-gear.html',
                    description: 'Front Runner Dometic has a reputation for designing and manufacturing best in class vehicle organizing systems. Applying this mission of thoughtful engineering and quality craftsmanship to campsites has resulted in a line of innovative, space saving, and durable camping products that stow easily on Front Runner Dometic rack systems and\/or inside vehicles. Shop now for light and low profile roof top tents, instant 2 person ground tents, compact stainless steel camp tables, chairs, cooking accessories and more.'
                },
                {
                    sub_category: [{
                        name: 'Roof Load Bar Kits ', image: '/media\/catalog\/category\/06_2.jpg', category_id: '75', url: '/load-bars\/roof-load-bar-kits.html', description: 'Front Runner Dometic\u2019s Roof Load Bar Kits consist of a pair of load bar slats and a vehicle specific mounting system. All hardware components needed to install the load bar slats on a specific vehicle are included. Installation guides are available for download in the INSTALL tab of each product. \u201cSHOP BY VEHICLE\u201d and use our vehicle selection tool to find the rack and load bar kits available for your vehicle, or browse to shop for an appropriate rack or load bars.'
                    }, {
                        name: 'Pick-up Bed Load Bar Kits ', image: '/media\/catalog\/category\/06_6.jpg', category_id: '77', url: '/load-bars\/pick-up-bed-load-bar-kits.html', description: 'Front Runner Dometic\u2019s Pick-Up \/ Bakkie Bed Load Bar Kits consist of a pair of load bar slats and a truck specific bed \/ cargo bay mounting system. All hardware components needed to install the load bar slats on a specific truck bed are included. \u201cSHOP BY VEHICLE\u201d and use our vehicle selection tool to find the rack and load bar kits available for your pick-up\/bakkie bed, or browse to shop for an appropriate rack or load bar kit.'
                    }, {
                        name: 'Load Bar Components', image: '/media\/catalog\/category\/06_4.jpg', category_id: '76', url: '/load-bars\/load-bar-components.html', description: "Front Runner Dometic Roof Load Bar components include load bar slats, end caps, mounting systems and replacement parts.   If you'd like to customize your vehicle's Load Bars and don't see what you need here, please CONTACT US! "
                    }],
                    name: 'Load Bars',
                    image: '/media\/catalog\/category\/Block_38.jpg',
                    category_id: '74',
                    url: '/load-bars.html',
                    description: 'If you don\u2019t currently need a complete Front Runner Dometic Roof Rack but still want off-road rated, durable cargo space for a bicycle, roof top tent, kayak, and other gear carrying accessories, consider a Front Runner Dometic Load Bar Kit. A smaller footprint, lower profile, lighter load capacity, limited accessory range, and entry level pricing distinguish Front Runner Dometic Load Bars from Front Runner Dometic Roof Racks.'
                },
                {
                    sub_category: [{
                        name: 'Boxes & Bags', image: '/media\/catalog\/category\/Block_13.jpg', category_id: '30', url: '/storage-systems\/boxes-bags.html', description: 'Get organized with these Front Runner Dometic carrying and storage solutions. Wolf Packs and Cub Packs are ideal for moving gear in and out of vehicles to garages or campsites. Front Runner Dometic\u2019s boxes and bags nest, collapse, and\/or fold for space saving convenience on the road and off. Our boxes and bags are \u201cuniversal fit\u201d, which means they will work with all vehicle makes, models, and years.'
                    }, {
                        name: 'Drawer Systems', image: '/media\/catalog\/category\/Block_14.jpg', category_id: '27', url: '/storage-systems\/drawers.html', description: 'Front Runner Dometic\u2019s off-road tough drawer systems are locking and feature quality slides with 113 kg \/ 250 lb per drawer load carrying capacity. Keep tools, hunting equipment, photo gear, and more out of the sight, safe, and organized for easy access.  Sold either as vehicle specific systems complete with decking and wings for a customized fit or as \u201cuniversal\u201d fitted systems sold in an assortment of lengths, widths and heights.'
                    }, {
                        name: 'Sliders', image: '/media\/catalog\/category\/11_2.jpg', category_id: '28', url: '/storage-systems\/sliders.html', description: 'Front Runner Dometic\u2019s off-road tough sliders latch closed and feature a 113 kg \/ 250 lb load carrying capacity. Traditionally used to easily access refrigerators or tool boxes, Front Runner Dometic cargo slides can also be used to carry Wolf Packs, Cub Packs and other heavier gear. Front Runner Dometic Sliders can be secured to most flat surfaces. Browse to shop for an appropriately sized slider for your vehicle and gear.'
                    }, {
                        name: 'Safes', image: '/media\/catalog\/category\/Block_41.jpg', category_id: '29', url: '/storage-systems\/safes.html', description: 'Front Runner Dometic locking safes mount securely under vehicle consoles or under seats for out of sight, protected storage for your valuables.'
                    }, {
                        name: 'Lashing & Securing', image: '/media\/catalog\/category\/Block_15.jpg', category_id: '33', url: '/storage-systems\/lashing-and-securing.html', description: 'Utilize these cargo rails, straps, brackets, clips and tie down rings for a variety of \u201cuniversal\u201d storage applications.'
                    }, {
                        name: 'Gullwing', image: '/media\/catalog\/category\/Block_28.jpg', category_id: '89', url: '/storage-systems\/gullwing.html', description: 'Front Runner Dometic\u2019s Gullwing product line features glass or aluminum locking access panels designed to replace existing vehicle specific fixed windows.  The Front Runner Dometic Gullwing Storage Boxes mount inside the vehicle behind the gullwing access panels and feature optional shelves for secure and easy to access storage.  Designed and built for on-road sophistication with off-road strength.  \r\n'
                    }],
                    name: 'Storage',
                    image: '/media\/catalog\/category\/Block_12.jpg',
                    category_id: '26',
                    url: '/storage-systems.html',
                    description: 'Arguing with travelling companions about how to fit too much gear into too little space is a terrible way to start a road trip! Front Runner Dometic engineers have designed relationship saving organizing systems. Our boxes, drawers, sliders, safes, and gullwing panels provide easy access, security, and above all, innovative space saving alternatives to traditional vehicle based storage solutions.'
                },
                {
                    sub_category: [{
                        name: 'Tanks & Mounts', image: '/media\/catalog\/category\/Block_10.jpg', category_id: '110', url: '/water-solutions\/tanks-mounts.html', description: 'Front Runner Dometic manufactures a comprehensive range of heavy duty off-road tough poly water tanks.  Browse and shop for vehicle tanks with universal fitment like the Front Runner Dometic Footwell Water Tank or Front Runner Dometic 45l Rack Mount Water Tank or \u201cShop by Vehicle\u201d for available vehicle specific tanks. '
                    }, {
                        name: 'Bags', image: '/media\/catalog\/category\/Block_33.jpg', category_id: '111', url: '/water-solutions\/bags.html', description: 'The Front Runner Dometic water bags are durable, work well in tight spaces, and fold compact when empty. Browse and shop here for these convenient and flexible water storage solutions.   \r\n'
                    }, {
                        name: 'Shower', image: '/media\/catalog\/category\/Block_32.jpg', category_id: '112', url: '/water-solutions\/shower.html', description: 'Simple, sturdy solutions for rinsing off after a hike, bike ride, or overnight camping.  \r\n'
                    }, {
                        name: 'Hoses & Pumps', image: '/media\/catalog\/category\/Block_11.jpg', category_id: '113', url: '/water-solutions\/hose-pumps.html', description: 'Accessories to design custom water distribution for your vehicle and campsite. \r\n'
                    }],
                    name: 'Water',
                    image: '/media\/catalog\/category\/Block_9.jpg',
                    category_id: '25',
                    url: '/water-solutions.html',
                    description: 'Comprehensive water storage is an often overlooked necessity when travelling. Front Runner Dometic manufactures a range of heavy duty poly water tanks and flexible water bags in a choice of sizes and shapes for almost any vehicle related application. Designed to withstand 4x4 off-road conditions and made from food grade polyethylene, Front Runner Dometic\u2019s water storage systems are BPA free, do not taint the taste of water and can be easily cleaned.   '
                },
                {
                    sub_category: [{
                        name: 'Battery Management', image: '/media\/catalog\/category\/Block_24.jpg', category_id: '37', url: '/battery-solutions\/battery-management.html', description: 'Chargers, controllers, monitors, power packs, solenoids and other equipment to manage vehicle battery systems. \t\r\n'
                    }, {
                        name: 'Battery Accessories', image: '/media\/catalog\/category\/04_7.jpg', category_id: '38', url: '/battery-solutions\/battery-accessories.html', description: 'Fuses, relays, plugs, terminals, switches and other components for assembling and maintaining vehicle battery systems.  \r\n'
                    }],
                    name: 'Power & Lighting',
                    image: '/media\/catalog\/category\/Dual_Battery_Systems.jpg',
                    category_id: '35',
                    url: '/battery-solutions.html',
                    description: 'Don\u2019t underestimate the power of power!  Front Runner Dometic designs and manufactures off-road tough battery brackets and offers for sale world renown dual battery systems, power packs, and chargers.   \r\n'
                },
                {
                    sub_category: [{
                        name: 'Coolers', image: '/media\/catalog\/category\/Block_48.jpg', category_id: '115', url: '/refrigeration.html', description: 'Spread the Front Runner Dometic love with a selection of Front Runner Dometic t-shirts, hats and stickers. \r\n'
                    }],
                    name: 'Coolers',
                    image: '/media\/catalog\/category\/Block_48.jpg',
                    category_id: '115',
                    url: '/merch.html',
                    description: 'Spread the Front Runner Dometic love with a selection of Front Runner Dometic t-shirts, hats and stickers. \r\n'
                },
                {
                    sub_category: [{
                        name: 'Recovery', image: '/media\/catalog\/category\/Block_36.jpg', category_id: '39', url: '/recovery-equipment.html', description: null
                    }, {
                        name: 'Jack & Accessories', image: '/media\/catalog\/category\/Block_17.jpg', category_id: '41', url: '/recovery-equipment\/jack-accessories.html', description: null
                    }, {
                        name: 'Recovery Accessories', image: '/media\/catalog\/category\/14_7.jpg', category_id: '43', url: '/recovery-equipment\/recovery-accessories.html', description: null
                    }, {
                        name: 'Sand Ladders', image: '/media\/catalog\/category\/Block_18.jpg', category_id: '44', url: '/recovery-equipment\/sand-ladders.html', description: null
                    }, {
                        name: 'Tyre \/ Tire', image: '/media\/catalog\/category\/Block_20.jpg', category_id: '46', url: '/recovery-equipment\/tyre-tire.html', description: null
                    }, {
                        name: 'Refrigeration', image: '/media\/catalog\/category\/Block_23.jpg', category_id: '24', url: '/refrigeration.html', description: null
                    }, {
                        name: 'Ladders', image: '/media\/catalog\/category\/Block_25.jpg', category_id: '47', url: '/ladders.html', description: null
                    }, {
                        name: 'Protection & Trim', image: '/media\/catalog\/category\/15_1.jpg', category_id: '48', url: '/protection-equipment.html', description: null
                    }, {
                        name: 'Under Vehicle Protection', image: '/media\/catalog\/category\/15_6.jpg', category_id: '50', url: '/protection-equipment\/under-vehicle-protection.html', description: null
                    }, {
                        name: 'Body Protection', image: '/media\/catalog\/category\/15_4.jpg', category_id: '49', url: '/protection-equipment\/vehicle-protection.html', description: null
                    // }, {
                    //     name: 'Bumpers & Carriers', image: '/media\/catalog\/category\/03.jpg', category_id: '61', url: '/bumpers-carriers.html', description: null
                    // }, {
                    //     name: 'Front Bumpers & Bull Bars', image: '/media\/catalog\/category\/Block_26.jpg', category_id: '62', url: '/bumpers-carriers\/front-bumpers-bull-bars.html', description: null
                    // }, {
                    //     name: 'Rear Bumpers & Spare Wheel', image: '/media\/catalog\/category\/03_4.jpg', category_id: '63', url: '/bumpers-carriers\/rear-bumpers-spare-wheel.html', description: null
                    }],
                    name: 'More',
                    image: '',
                    category_id: '',
                    url: '/#',
                    description: ''
                }]
            }],
            shop_by_adventure: [{ label: 'Camp', url: '/adventure\/camp' }, { label: 'Expedition', url: '/adventure\/expedition' }, { label: 'Fish', url: '/adventure\/fish' }, { label: 'Surf', url: '/adventure\/surf' }, { label: 'Snow', url: '/adventure\/snow' }, { label: 'Paddle', url: '/adventure\/paddle' }, { label: 'Bike', url: '/adventure\/bike' }],
            explore: [{ label: 'Slimline II Roof Rack', url: '/slimline2' }, { label: 'Gearzette', url: '\/gearzette' }, { label: 'The Pride', url: 'http:\/\/frontrunnerpride.com' }, { label: 'About Us', url: '/about-us' }, { label: 'Customer Commitment', url: '/customer-commitment' }, { label: 'Careers', url: '/jobs' }],
            contact: [{ label: 'Contact', url: '/contact' }]
        }]
    },
    mobileHeaderTextBanner: '',
    isHeaderAndFooterVisible: true
};

/** @namespace Pwa/Store/HeaderAndFooter/Reducer/HeaderAndFooterReducer */
export const HeaderAndFooterReducer = (state = initialState, action) => {
    const {
        type, isHeaderAndFooterVisible, mobileMenu, desktopMenu, mobileHeaderTextBanner
    } = action;

    switch (type) {
    case UPDATE_MENU:

        if (!(isEmpty(mobileMenu))) {
            return {
                ...state, desktopMenu, mobileHeaderTextBanner, mobileMenu
            };
        }

        return { ...state, mobileHeaderTextBanner };
    case TOGGLE_HEADER_AND_FOOTER:
        return { ...state, isHeaderAndFooterVisible };
    default:
        return state;
    }
};

export default HeaderAndFooterReducer;
