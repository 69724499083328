/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/* eslint-disable max-len */
/* eslint-disable no-magic-numbers */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable fp/no-let */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/sort-comp */
import PropTypes from 'prop-types';
import { Component } from 'react';
import OwlCarousel from 'react-owl-carousel';

import ProductCard from 'Component/ProductCard';
import ProductListQuery from 'Query/ProductList.query';
import { showNotification } from 'Store/Notification/Notification.action';
import isMobile from 'Util/Mobile';
import { fetchQuery } from 'Util/Request';

import FRSlider from '../FRSlider/FRSlider';

export const options = {
    margin: 10,
    responsiveClass: true,
    autoplay: false,
    autoplayHoverPause: true,
    dots: false,
    nav: true,
    navText: [
        '<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" class="ChevronIcon ChevronIcon_direction_left"><path d="M5.8535 13.707L11.5605 7.99997L5.8535 2.29297L4.4395 3.70697L8.7325 7.99997L4.4395 12.293L5.8535 13.707Z"></path></svg>',
        '<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" class="ChevronIcon ChevronIcon_direction_right"><path d="M5.8535 13.707L11.5605 7.99997L5.8535 2.29297L4.4395 3.70697L8.7325 7.99997L4.4395 12.293L5.8535 13.707Z"></path></svg>'
    ],
    // loop: false,
    smartSpeed: 250,
    lazyLoad: true,
    responsive: {
        0: {
            items: 2,
            dots: true,
            nav: false,
            autoplay: false
        },
        811: {
            items: 3,
            dots: false,
            nav: true,
            autoplay: false
        }
    }
};

export const NUMBER_OF_DISPLAYED_PRODUCTS = 5;

/** @namespace Pwa/Component/CmsProductSlider/CmsProductSlider */
export class CmsProductSlider extends Component {
  static propTypes = {
      productsskuarray: PropTypes.any
  };

  __construct(props) {
      super.__construct(props);

      this.state = {
          products: []
      };
  }

  componentDidMount() {
      this._fetchProducts();
  }

  //   componentDidUpdate(prevProps) {
  //       if (prevProps !== this.props) {
  //           this._fetchProducts();
  //       }
  //   }

  _handleError = (error) => {
      const [{ message, debugMessage }] = error;
      showNotification('error', debugMessage || message);
  };

  _fetchProducts() {
      let { productsskuarray = [] } = this.props;
      if (productsskuarray.length === 0) {
          return;
      }
      productsskuarray = productsskuarray?.split(',');
      const options = { sku: { in: productsskuarray } };

      fetchQuery(ProductListQuery.getDealsPopupQuery(options, 200)).then(
          /** @namespace Pwa/Component/CmsProductSlider/fetchQuery/then */
          (data) => {
              const { products } = data;
              const productList = products.items.filter((product) => productsskuarray.includes(product.sku));
              productList.sort((a, b) => productsskuarray.indexOf(a.sku) - productsskuarray.indexOf(b.sku));
              this.setState({ products: productList });
          },
          this._handleError
      );
  }

  static defaultProps = {
      productsskuarray: ''
  };

  renderEmptyCard = () => (
        <div className="child">
            <li className="ProductCard">
                <div className="pro-image">
                    <div className="ProductCard-Link">
                        <figure className="ProductCard-Figure">
                            <div className="Image Image_ratio_custom Image_imageStatus_3 Image_isPlaceholder ProductCard-Picture " />
                        </figure>
                    </div>
                </div>
                <div className="product-item-details">
                    <div className="pro-left">
                        <div className="ProductCard-Content">
                            <div className="ProductCard-Name">
                                <h2>
                                    <div className="ProductCard-Link">
                                        <span className="TextPlaceholder TextPlaceholder_length_medium " />
                                    </div>
                                </h2>
                            </div>
                        </div>
                    </div>
                    <div className="pro-right">
                        <div className="pro_inner_top">
                            <div className="pro-inner-wrap">
                                <span className="TextPlaceholder TextPlaceholder_length_short " />
                                <div className="ProductCard-ConfigurableOptions" />
                            </div>
                            <div className="add_cart_wrap">
                                <div className="ProductCard-Link" />
                            </div>
                        </div>
                        <div className="pro_inner_bot">
                            <div className="ProductCard-SKU">
                                <span className="TextPlaceholder TextPlaceholder_length_short " />
                            </div>
                            <div className="product-wishlist">
                                <div className="ProductWishlistButton">
                                    <button title="Please sign in first!" className="ProductWishlistButton-Button ProductWishlistButton-Button_isDisabled Button Button_isHollow ">
                                        <span className="TextPlaceholder TextPlaceholder_length_short " />
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="ProductCard-AdditionalContent" />
                    </div>
                </div>
            </li>
        </div>
  );

  renderProductCard(product) {
      const { id } = product;
      const isRelated = true;
      const isSlider = true;

      //   if (!id) {
      //       return this.renderEmptyCard();
      //   }

      return (
        <div className="child" key={ `product-slider-${id}` }>
          <ProductCard
            block="product"
            elem="Card"
            product={ product }
            isRelated
            isSlider
            key={ id }
          />
        </div>
      );
  }

  renderItems() {
      const { products: items = [] } = this.state;
      const { use_slider } = this.props;
      const opt = options;

      if (items.length === 0) {
          if (!use_slider) {
              return (
                <div className="product-slider product-grid">
                    { Array.from(
                        { length: NUMBER_OF_DISPLAYED_PRODUCTS },
                        (_, i) => this.renderProductCard({}, i)
                    ) }
                </div>
              );
          }

          return (
            <div className="product-slider">
                <OwlCarousel
                  className="owl-theme"
                  autoWidth="true"
                  { ...opt }
                >
                    { Array.from(
                        { length: NUMBER_OF_DISPLAYED_PRODUCTS },
                        (_, i) => this.renderProductCard({}, i)
                    ) }
                </OwlCarousel>
            </div>
          );
      }

      //   const { items = [] } = products;
      if (!items) {
          return Array.from(
              { length: NUMBER_OF_DISPLAYED_PRODUCTS },
              (_, i) => this.renderProductCard({}, i)
          );
      }
      if (items.length < 1) {
          return null;
      }
      let itemCount = 2;

      if (!isMobile.any()) {
          itemCount = 5;
      }

      return (
        <FRSlider
          data={ items }
          isRelated={ false }
          count={ itemCount }
          use_slider={ use_slider }
        />
      );
  }

  render() {
      return (
        <div block="CmsProductSlider">
            { this.renderItems() }
        </div>
      );
  }
}
export default CmsProductSlider;
